<template>
    <div class="col-12 col-md-6 col-xl-3 mb-4">
        <article v-bind:id="ProdId" class="product" :class="ProdConfigured">
            <img v-bind:src="ProdImg"/>
            <h4>{{ ProdName }}</h4>
            <ul>
                <li>Opening Direction:<span>{{ ProdConfiguredDirection }}</span></li>
                <li>Sill or Threshold:<span>{{ ProdConfiguredTypeOfSill }}</span></li>
                <li>Handing:<span>{{ ProdConfiguredHanding }}</span></li>
                <li>Delivery Clearance:<span>{{ ProdConfiguredDeliveryClearance }}</span></li>
            </ul>
            <button :data-prodid="ProdId" v-if="ProdConfigured === null" type="button" class="aluminium-external-french-3000less-step-1-btn-choose btn btn-choose">Click to Configure</button>
            <button :data-prodid="ProdId" v-if="ProdConfigured === 'done'" type="button"class="aluminium-external-french-3000less-step-1-btn-choose btn btn-confirm">Configured (Click to reconfigure)</button>
        </article>
    </div>
</template>
<script>
export default {
    props: [
        'ProdName',
        'ProdId',
        'ProdImg',
        'ProdConfiguredDirection',
        'ProdConfiguredTypeOfSill',
        'ProdConfiguredDeliveryClearance',
        'ProdConfiguredHanding',
        'ProdConfigured'
    ]
}
</script>
