<template>
    <main id="direction-options" class="container option-wrapper">
        <section class="row">
            <h3 class="title col-12">Threshold/Sill</h3>
            <p  class="description col-12">Of the 4 threshold/sill combinations in the attached image, which do you need your set to include? Please note the low threshold will always be an anodised metallic colour, and not the same colour as the rest of the set.</p>
        </section>
        <section class="choices row">
            <div class="col-12 col-md-6 col-xl-2 mb-4">
                <article @click="saveData" class="option option-sill option-sill-rebated">
                    <i class="far fa-check-circle"></i>
                    <svg xmlns="http://www.w3.org/2000/svg" width="212" height="120.368" viewBox="0 0 212 120.368">
                        <g id="Figure" transform="translate(-156 -534.816)">
                            <g id="Group_26" data-name="Group 26" transform="translate(182.845 535.316)">
                                <rect id="Rectangle_24" data-name="Rectangle 24" width="2.447" height="16.519" transform="translate(4.102 20.441)" fill="#161615"/>
                                <rect id="Rectangle_25" data-name="Rectangle 25" width="34.549" height="7.198" transform="translate(69.961 82.988)" fill="#161615"/>
                                <rect id="Rectangle_26" data-name="Rectangle 26" width="34.549" height="7.198" transform="translate(69.961 67.801)" fill="#161615"/>
                                <path id="Path_67" data-name="Path 67" d="M67.169,202.716V243.6H89.913V299.74h7.63v-2.879H93.8v-6.91l38.867-.143v7.054h-4.03v2.879h7.773v-2.591s3.508-.91,5.339,2.085a.557.557,0,0,0,.766.206l1.729-1.057a.55.55,0,0,0,.217-.685c-.448-1.08-2.077-3.686-7.332-3.571v-9.07s1.287.3,2.983.77a.474.474,0,0,0,.6-.456l.015-7.791a.47.47,0,0,0-.616-.452c-.769.25-2.073.669-2.838.876V268.79a8.419,8.419,0,0,0,7.1-4.133.616.616,0,0,0-.1-.754l-1.291-1.258a.6.6,0,0,0-.872.028c-.785.884-2.642,2.654-4.836,2.519v-7.63s2.88,0,2.88-2.735V241.3a2.5,2.5,0,0,0-2.664-2.663V254.2h-12.74V238.56h-.036a2.556,2.556,0,0,0-2.555,2.555v13.19H93.872V239.424H76.381s.288-2.735-2.3-3.166v3.166H70.911V223.158H73.5v2.159l.675-.307a4.273,4.273,0,0,0,2.505-3.869l.01-2.158a4.271,4.271,0,0,0-2.466-3.892l-.76-.355v2.375H70.876v-11.12h2.483v2.645h0a3.617,3.617,0,0,0,3.617-3.617v-2.3Z" transform="translate(-67.169 -202.717)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <rect id="Rectangle_27" data-name="Rectangle 27" width="39.515" height="30.23" transform="translate(26.703 54.557)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <path id="Path_68" data-name="Path 68" d="M189.2,265.509" transform="translate(-80.445 -209.548)" fill="none"/>
                                <path id="Path_69" data-name="Path 69" d="M143.976,237.2a5.716,5.716,0,0,0-11.228,0v19.757h11.228Z" transform="translate(-74.303 -205.963)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <path id="Path_70" data-name="Path 70" d="M195.663,237.2a5.715,5.715,0,0,0-11.227,0v19.757h11.227Z" transform="translate(-79.926 -205.963)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <path id="Path_71" data-name="Path 71" d="M234.836,294.443l-46.515-.143v7.054h4.031v2.879h-7.774v-2.591s-3.508-.91-5.339,2.085a.556.556,0,0,1-.766.206l-1.73-1.057a.552.552,0,0,1-.217-.685c.449-1.08,2.077-3.686,7.333-3.571v-9.07s-1.288.3-2.984.77a.474.474,0,0,1-.6-.456l-.015-7.791a.47.47,0,0,1,.616-.452c.769.25,2.073.669,2.838.876v-9.213a8.42,8.42,0,0,1-7.1-4.133.618.618,0,0,1,.1-.754l1.291-1.258a.6.6,0,0,1,.872.028c.785.884,2.642,2.654,4.836,2.519v-7.63H182.68a2.258,2.258,0,0,1-2.258-2.258V246s-.162-2,2.645-2v14.684H195.7V244.006h16.789s2.105-.09,2.105,1.782-2,2-2,2h-1.944v9.987h24.185v-10.2h-2.7v-2.807h5.83v60.514h-7.126v-3.941h3.923Z" transform="translate(-79.061 -207.209)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <path id="Path_72" data-name="Path 72" d="M188.958,262.607v30.23h47.163V262.913h-5.6v-1.295H210.254a3.05,3.05,0,0,1-3.05-3.051v-9.474h-6.533v13.513Z" transform="translate(-80.418 -207.762)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                            </g>
                            <g id="Group_85" data-name="Group 85" transform="translate(157 535.762)">
                                <line id="Line_4" data-name="Line 4" y2="18.715" transform="translate(49.462 100.707)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_5" data-name="Line 5" y2="18.715" transform="translate(184.036 100.707)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_9" data-name="Line 9" y2="18.715" transform="translate(191.165 36.54) rotate(-90)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_10" data-name="Line 10" y2="18.715" transform="translate(191.165 97.142) rotate(-90)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_6" data-name="Line 6" x2="40.105" transform="translate(49.462 110.51)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_7" data-name="Line 7" x2="40.105" transform="translate(143.931 110.51)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_8" data-name="Line 8" y1="16.933" transform="translate(200.969 36.54)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_11" data-name="Line 11" y1="16.933" transform="translate(200.969 80.209)" fill="none" stroke="#707070" stroke-width="1"/>
                                <text id="_70mm" data-name="70mm" transform="translate(116 113.239)" fill="#332f2f" font-size="9" font-family="Roboto-Regular, Roboto"><tspan x="-11.353" y="0">70mm</tspan></text>
                                <text id="_31mm" data-name="31mm" transform="translate(199 68.239)" fill="#332f2f" font-size="9" font-family="Roboto-Regular, Roboto"><tspan x="-11.353" y="0">31mm</tspan></text>
                                <text id="_50mm" data-name="50mm" transform="translate(11 53.239)" fill="#332f2f" font-size="9" font-family="Roboto-Regular, Roboto"><tspan x="-11.353" y="0">50mm</tspan></text>
                                <line id="Line_12" data-name="Line 12" y2="18.715" transform="translate(3.119 0) rotate(-90)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_13" data-name="Line 13" y1="34.757" transform="translate(12.031 0)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_14" data-name="Line 14" y2="18.715" transform="translate(3.119 97.142) rotate(-90)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_15" data-name="Line 15" y1="31.192" transform="translate(12.031 65.949)" fill="none" stroke="#707070" stroke-width="1"/>
                            </g>
                        </g>
                    </svg>
                    <h4>Rebated</h4>
                </article>
            </div>
            <div class="col-12 col-md-6 col-xl-2 mb-4">
                <article @click="saveData" class="option option-sill option-sill-lowthreshold">
                    <svg xmlns="http://www.w3.org/2000/svg" width="260.965" height="121.922" viewBox="0 0 260.965 121.922">
                        <g id="Figure" transform="translate(-398 -533.262)">
                            <g id="Group_27" data-name="Group 27" transform="translate(65.049 325.213)">
                                <path id="Path_73" data-name="Path 73" d="M361.951,218.384V304.8h13.084v-5.492l-8.723.162V281.863h67.679v23.472h4.957v-2.908s3.936-1.02,5.992,2.34a.624.624,0,0,0,.859.231l1.941-1.186a.617.617,0,0,0,.243-.769c-.5-1.212-2.331-4.136-8.227-4.008V288.859s1.445.341,3.348.864a.532.532,0,0,0,.671-.512l.017-13.1a.528.528,0,0,0-.691-.508c-.862.282-2.326.752-3.184.983V266.246a9.446,9.446,0,0,0,7.966-4.638.69.69,0,0,0-.113-.845l-1.449-1.412a.674.674,0,0,0-.978.03c-.881.993-2.965,2.979-5.426,2.827V241.644h4.9V212.892h-4.2v23.1h-19.06v-23.1l-27.621,2.585a10.066,10.066,0,0,1,.935,4.939l3.335-.053.114,3.594-19.729.615v-2.957l2.585-.162a14.087,14.087,0,0,1,.646-5.007Z" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <rect id="Rectangle_28" data-name="Rectangle 28" width="75.321" height="9.237" transform="translate(439.756 289.586)" fill="#161615"/>
                                <rect id="Rectangle_29" data-name="Rectangle 29" width="75.321" height="10.388" transform="translate(439.756 265.872)" fill="#161615"/>
                                <path id="Path_74" data-name="Path 74" d="M375.035,221.938l-8.723.807v54.111h68V241.8H415.9V217.9l-15.021,1.776.16,6.784-5.334.238s4.607,5.577-2.1,11.231l-2.14-2.181s5.007-4.361-.767-8.641l-5.492.323s-4.725,4.28.121,8.318l-2,2.342s-6.622-3.23-2.423-10.5l-5.815.3Z" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <path id="Path_75" data-name="Path 75" d="M560.829,215.436a8.527,8.527,0,0,0-.687,4.4l-3.607-.3-.074,3.617,19.713,1.379v-2.957l-2.585-.161a14.087,14.087,0,0,0-.646-5.007l20.473,2.584v86.345h-9.363c-2.568,0-4.649-1.112-4.649-3.68v-1.378h9.813V281.863H520.428v21.659a1.812,1.812,0,0,1-1.812,1.813h-2.409v-2.908s-3.935-1.02-5.991,2.34a.624.624,0,0,1-.859.231l-1.941-1.186a.617.617,0,0,1-.243-.769c.5-1.212,2.33-4.136,8.227-4.008V288.859s-1.445.341-3.348.864a.531.531,0,0,1-.671-.512l-.018-13.1a.529.529,0,0,1,.692-.508c.862.282,2.326.752,3.183.983V266.246a9.447,9.447,0,0,1-7.966-4.638.691.691,0,0,1,.113-.845l1.449-1.412a.674.674,0,0,1,.978.03c.881.993,2.965,2.979,5.426,2.827V241.523h-6.582V212.892h5.452V236.8h19.625V212.892Z" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <path id="Path_76" data-name="Path 76" d="M579.727,221.9l-.06,5.954-18.132-.1c4.2,7.268-2.423,10.5-2.423,10.5l-2-2.342c4.845-4.038.121-8.319.121-8.319l-4.22-.318c-5.775,4.28-.767,8.641-.767,8.641l-2.141,2.181c-8.507-8.965,2.282-14.017,2.282-14.017l.314-4.91L538.821,217.9v23.5H520.428v36.465h68.789V222.745Z" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <path id="Path_77" data-name="Path 77" d="M386.456,212.108l-.4,1.733s-3.292.137-4,2.241a25.5,25.5,0,0,0-.888,5.371l-2.333.146-.252,2.973,19.729-.615-.114-3.594-3.335.053-.935-4.939A4.782,4.782,0,0,0,390,213.6l-.656-1.7a1.512,1.512,0,0,0-1.41-.967h0A1.513,1.513,0,0,0,386.456,212.108Z" fill="#161615"/>
                                <path id="Path_78" data-name="Path 78" d="M566.209,211.406l-1.089,2.226s-3.287-.219-4.216,1.8a26.615,26.615,0,0,0-1.488,4.456l-2.335-.107-.546,3.716,19.679,1.519.275-3.585-3.321-.308-.4-5.011a4.783,4.783,0,0,0-3.711-2.292l-.65-2.132a1.266,1.266,0,0,0-1.211-.9h0A1.1,1.1,0,0,0,566.209,211.406Z" fill="#161615"/>
                            </g>
                            <text id="_30mm" data-name="30mm" transform="translate(410 587)" fill="#332f2f" font-size="9" font-family="Roboto-Regular, Roboto"><tspan x="-11.353" y="0">30mm</tspan></text>
                            <line id="Line_16" data-name="Line 16" y2="18.715" transform="translate(402.119 533.762) rotate(-90)" fill="none" stroke="#707070" stroke-width="1"/>
                            <line id="Line_17" data-name="Line 17" y1="34.757" transform="translate(411.031 533.762)" fill="none" stroke="#707070" stroke-width="1"/>
                            <line id="Line_18" data-name="Line 18" y2="18.715" transform="translate(402.119 630.902) rotate(-90)" fill="none" stroke="#707070" stroke-width="1"/>
                            <line id="Line_19" data-name="Line 19" y1="31.192" transform="translate(411.031 599.711)" fill="none" stroke="#707070" stroke-width="1"/>
                            <line id="Line_20" data-name="Line 20" y2="18.715" transform="translate(427.462 636.469)" fill="none" stroke="#707070" stroke-width="1"/>
                            <line id="Line_21" data-name="Line 21" y2="18.715" transform="translate(658.036 636.469)" fill="none" stroke="#707070" stroke-width="1"/>
                            <line id="Line_22" data-name="Line 22" x2="95.038" transform="translate(427.462 646.271)" fill="none" stroke="#707070" stroke-width="1"/>
                            <line id="Line_23" data-name="Line 23" x2="92.536" transform="translate(565.5 646.271)" fill="none" stroke="#707070" stroke-width="1"/>
                            <text id="_70mm" data-name="70mm" transform="translate(543 649)" fill="#332f2f" font-size="9" font-family="Roboto-Regular, Roboto"><tspan x="-11.353" y="0">70mm</tspan></text>
                        </g>
                    </svg>
                    <h4>Low Threshold</h4>
                </article>
            </div>
            <div class="col-12 col-md-6 col-xl-4 mb-4">
                <article @click="saveData" class="option option-sill option-sill-rebateandsill">
                    <i class="far fa-check-circle"></i>
                    <svg xmlns="http://www.w3.org/2000/svg" width="378" height="195.31" viewBox="0 0 378 195.31">
                        <g id="Figure" transform="translate(-832 -478.874)">
                            <g id="Group_29" data-name="Group 29" transform="translate(174.406 276.658)">
                                <rect id="Rectangle_30" data-name="Rectangle 30" width="2.398" height="16.186" transform="translate(689.021 222.746)" fill="#161615"/>
                                <rect id="Rectangle_31" data-name="Rectangle 31" width="33.853" height="7.053" transform="translate(753.554 284.034)" fill="#161615"/>
                                <rect id="Rectangle_32" data-name="Rectangle 32" width="33.853" height="7.053" transform="translate(753.554 269.153)" fill="#161615"/>
                                <path id="Path_79" data-name="Path 79" d="M685,202.716v40.06h22.287v55.012h7.476v-2.821H711.1V288.2l38.085-.141v6.912h-3.95v2.821h7.617v-2.539s3.437-.892,5.233,2.043a.545.545,0,0,0,.75.2l1.7-1.036a.539.539,0,0,0,.212-.672c-.439-1.058-2.036-3.612-7.184-3.5V283.4s1.262.3,2.924.755a.464.464,0,0,0,.586-.447l.016-7.634a.461.461,0,0,0-.6-.443c-.753.245-2.031.656-2.781.858V267.46a8.249,8.249,0,0,0,6.956-4.049.6.6,0,0,0-.1-.739l-1.265-1.233a.589.589,0,0,0-.854.027c-.769.866-2.589,2.6-4.738,2.468v-7.476s2.822,0,2.822-2.68V240.519a2.446,2.446,0,0,0-2.609-2.609v15.252H741.423V237.839h-.036a2.5,2.5,0,0,0-2.5,2.5v12.924H711.167V238.685H694.028a2.793,2.793,0,0,0-2.257-3.1v3.1h-3.1V222.746h2.539v2.116l.662-.3a4.186,4.186,0,0,0,2.454-3.791l.01-2.114a4.186,4.186,0,0,0-2.415-3.814l-.745-.348v2.327h-2.539v-10.9h2.433v2.592h0a3.544,3.544,0,0,0,3.545-3.544v-2.257Z" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <rect id="Rectangle_33" data-name="Rectangle 33" width="38.72" height="29.622" transform="translate(711.167 256.176)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <path id="Path_80" data-name="Path 80" d="M807.033,265.509" transform="translate(-15.465 -7.958)" fill="none"/>
                                <path id="Path_81" data-name="Path 81" d="M761.582,237.107a5.6,5.6,0,0,0-11,0v19.36h11Z" transform="translate(-8.311 -3.782)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <path id="Path_82" data-name="Path 82" d="M813.27,237.107a5.6,5.6,0,0,0-11,0v19.36h11Z" transform="translate(-14.861 -3.782)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <path id="Path_83" data-name="Path 83" d="M851.493,293.428l-45.579-.141V300.2h3.95v2.821h-7.617v-2.539s-3.436-.892-5.232,2.043a.545.545,0,0,1-.75.2l-1.7-1.036a.539.539,0,0,1-.212-.672c.439-1.058,2.035-3.612,7.184-3.5v-8.887s-1.262.3-2.924.755a.464.464,0,0,1-.586-.447l-.016-7.634a.461.461,0,0,1,.6-.443c.753.245,2.031.656,2.78.858v-9.028a8.25,8.25,0,0,1-6.956-4.049.605.605,0,0,1,.1-.739l1.266-1.233a.588.588,0,0,1,.853.027c.769.866,2.589,2.6,4.738,2.468V261.69h-1.014a2.213,2.213,0,0,1-2.213-2.213V245.963s-.158-1.957,2.592-1.957v14.388h12.378V244.006h16.451s2.063-.088,2.063,1.746-1.957,1.957-1.957,1.957h-1.9v9.786h23.7v-10h-2.645v-2.751h5.713v59.3h-6.983v-3.862h3.844Z" transform="translate(-13.853 -5.233)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <path id="Path_84" data-name="Path 84" d="M806.79,262.335v29.621H853V262.635H847.52v-1.269H827.658a2.988,2.988,0,0,1-2.988-2.989v-9.283h-6.4v13.241Z" transform="translate(-15.434 -5.877)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <g id="Group_28" data-name="Group 28" transform="translate(701.391 283.837)">
                                    <path id="Path_85" data-name="Path 85" d="M708.543,295.609H703.77v69.3a1.385,1.385,0,0,0,1.291,1.464h18.3l1.481-2.8,5.761,4.663v-8.768H725V350.7h4.444l-3.127-5.6,2.962-4.476h-4.773V329.994h8.066v-7.648l-7.9,3.358h-2.8v-15.88H708.708Z" transform="translate(-703.77 -295.609)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                    <path id="Path_86" data-name="Path 86" d="M707.728,317.4v47.1a1.386,1.386,0,0,0,1.292,1.464h11.218V330.89H717.6V317.4Z" transform="translate(-704.272 -298.37)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                    <path id="Path_87" data-name="Path 87" d="M798.157,369.531H778.57l-1.481-2.936-5.761,4.663V362.49h5.6v-8.767H772.48l3.127-5.6-2.962-4.476h4.773V333.018h-8.065v-7.648l7.9,3.358h20.9V313.2H902.518s102.049,20.519,102.543,20.332,12.509,2.052,13,14.178c.4,9.917.147,30.067.04,37.076a2.373,2.373,0,0,1-2.222,2.47h-.7a2.384,2.384,0,0,1-2.222-2.519V380.35h-11.193v4.384a2.384,2.384,0,0,1-2.222,2.519h0a2.384,2.384,0,0,1-2.222-2.519v-15.2H878.158s-1.313,2.262-3.453,0Z" transform="translate(-712.081 -297.838)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                    <path id="Path_88" data-name="Path 88" d="M883.809,365.953V347.961a5.675,5.675,0,0,0-4.4-5.79l-70.653-11.94V316.614h26.335V322.4h3.621v-5.783h68.471L1008.9,337.506s10.205,3.731,10.205,9.141v29.286H1006.6v-9.98Z" transform="translate(-717.075 -298.271)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                    <path id="Path_89" data-name="Path 89" d="M876.237,368.014H782.744v-20.24h2.005a1.533,1.533,0,0,0,1.085-2.4l-3.09-4.879v-6.156h16.789l70.9,13.364c4.924.814,5.8,1.521,5.8,5.773Z" transform="translate(-713.778 -300.518)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                    <path id="Path_90" data-name="Path 90" d="M727.566,334.657l8.065-.466.494-2.891a33.906,33.906,0,0,1,27.651,0l.322,3.685h8.065V345.57l-5.343.046s-14.977-25.218-33.987-.782l-5.268-.011Z" transform="translate(-706.786 -299.759)" fill="#161615"/>
                                    <path id="Path_91" data-name="Path 91" d="M772.117,368.775l-6.1.466.008,2.891s-17.275,6.622-32.171,0l-.322-3.686h-6.015V358.422H732.7s15.637,24.623,34.648.186h4.773Z" transform="translate(-706.78 -303.569)" fill="#161615"/>
                                </g>
                            </g>
                            <g id="Group_87" data-name="Group 87" transform="translate(11 29)">
                                <text id="_44mm" data-name="44mm" transform="translate(833 496)" fill="#332f2f" font-size="9" font-family="Roboto-Regular, Roboto"><tspan x="-11.353" y="0">44mm</tspan></text>
                                <text id="_31mm" data-name="31mm" transform="translate(833 569)" fill="#332f2f" font-size="9" font-family="Roboto-Regular, Roboto"><tspan x="-11.353" y="0">31mm</tspan></text>
                                <line id="Line_24" data-name="Line 24" y2="18.715" transform="translate(824.119 450.762) rotate(-90)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_29" data-name="Line 29" y2="18.715" transform="translate(824.119 531.762) rotate(-90)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_25" data-name="Line 25" y1="27.738" transform="translate(833.031 450.762)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_28" data-name="Line 28" y1="21.738" transform="translate(833.031 531.762)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_31" data-name="Line 31" y2="18.715" transform="translate(824.119 601.902) rotate(-90)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_27" data-name="Line 27" y1="25.403" transform="translate(833.031 506.5)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_30" data-name="Line 30" y1="22.403" transform="translate(833.031 579.5)" fill="none" stroke="#707070" stroke-width="1"/>
                                <text id="_31mm-2" data-name="31mm" transform="translate(1187 523)" fill="#332f2f" font-size="9" font-family="Roboto-Regular, Roboto"><tspan x="-11.353" y="0">31mm</tspan></text>
                                <text id="_32mm" data-name="32mm" transform="translate(1187 588)" fill="#332f2f" font-size="9" font-family="Roboto-Regular, Roboto"><tspan x="-11.353" y="0">32mm</tspan></text>
                                <line id="Line_32" data-name="Line 32" x2="18.335" transform="translate(1178.5 487.762)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_33" data-name="Line 33" y1="24" transform="translate(1187.032 487.5)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_34" data-name="Line 34" y1="47" transform="translate(1187.032 528.5)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_35" data-name="Line 35" x2="18.335" transform="translate(1178.5 546.902)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_36" data-name="Line 36" y2="18.715" transform="translate(1178.119 620.902) rotate(-90)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_37" data-name="Line 37" y1="27.403" transform="translate(1187.032 593.5)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_38" data-name="Line 38" y2="18.715" transform="translate(865.462 626.469)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_39" data-name="Line 39" y2="18.715" transform="translate(1171.036 626.469)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_40" data-name="Line 40" x2="128.038" transform="translate(865.462 636.271)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_41" data-name="Line 41" x2="129.536" transform="translate(1041.5 636.271)" fill="none" stroke="#707070" stroke-width="1"/>
                                <text id="_150mm" data-name="150mm" transform="translate(1017 639)" fill="#332f2f" font-size="9" font-family="Roboto-Regular, Roboto"><tspan x="-13.88" y="0">150mm</tspan></text>
                            </g>
                        </g>
                    </svg>
                    <h4>Rebate and Sill</h4>
                </article>
            </div>
            <div class="col-12 col-md-6 col-xl-4 mb-4">
                <article @click="saveData" class="option option-sill option-sill-lowthresholdandsill">
                    <i class="far fa-check-circle"></i>
                    <svg xmlns="http://www.w3.org/2000/svg" width="368" height="156.922" viewBox="0 0 368 156.922">
                        <g id="Group_91" data-name="Group 91" transform="translate(-1316 -512.262)">
                            <g id="Group_30" data-name="Group 30" transform="translate(158.321 300.107)">
                                <path id="Path_92" data-name="Path 92" d="M1198.16,221.312v58.731h8.892V276.31l-5.928.109V264.454h46v15.952h3.368V278.43a3.8,3.8,0,0,1,4.072,1.59.425.425,0,0,0,.584.157l1.319-.806a.42.42,0,0,0,.165-.523c-.342-.824-1.584-2.811-5.591-2.724v-6.916s.982.231,2.275.587a.36.36,0,0,0,.456-.347l.012-8.905a.36.36,0,0,0-.47-.345c-.586.192-1.581.511-2.163.668V253.84a6.422,6.422,0,0,0,5.414-3.152.471.471,0,0,0-.077-.575l-.985-.96a.458.458,0,0,0-.664.021c-.6.675-2.015,2.024-3.688,1.921V237.12h3.328V217.579h-2.854v15.7h-12.954v-15.7l-18.772,1.757a6.839,6.839,0,0,1,.635,3.357l2.267-.036.077,2.442-13.408.419v-2.01l1.756-.109a9.573,9.573,0,0,1,.439-3.4Z" transform="translate(-2.717 -0.674)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <rect id="Rectangle_34" data-name="Rectangle 34" width="51.19" height="6.278" transform="translate(1248.321 269.028)" fill="#161615"/>
                                <rect id="Rectangle_35" data-name="Rectangle 35" width="51.19" height="7.06" transform="translate(1248.321 252.912)" fill="#161615"/>
                                <path id="Path_93" data-name="Path 93" d="M1208.449,225.331l-5.928.548v36.775h46.216V238.833h-12.515V222.586l-10.209,1.208.109,4.611-3.626.161s3.132,3.791-1.424,7.633l-1.454-1.482s3.4-2.964-.521-5.873l-3.733.22s-3.211,2.91.082,5.654l-1.359,1.592s-4.5-2.2-1.646-7.135l-3.953.2Z" transform="translate(-4.114 -2.278)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <path id="Path_94" data-name="Path 94" d="M1379.831,219.308a5.8,5.8,0,0,0-.467,2.992l-2.451-.206-.05,2.458,13.4.938v-2.01l-1.757-.109a9.581,9.581,0,0,0-.439-3.4l13.915,1.757v58.683h-6.364c-1.745,0-3.16-.756-3.16-2.5v-.937h6.669V264.454h-46.751v14.72a1.232,1.232,0,0,1-1.232,1.232h-1.637V278.43a3.8,3.8,0,0,0-4.072,1.59.425.425,0,0,1-.584.157l-1.319-.806a.42.42,0,0,1-.165-.523c.342-.824,1.583-2.811,5.591-2.724v-6.916s-.982.231-2.275.587a.361.361,0,0,1-.456-.348l-.012-8.905a.36.36,0,0,1,.47-.345c.587.192,1.581.511,2.164.668V253.84a6.423,6.423,0,0,1-5.415-3.152.47.47,0,0,1,.078-.575l.985-.96a.459.459,0,0,1,.665.021c.6.675,2.014,2.024,3.688,1.921V237.037h-4.474V217.579h3.705v16.247h13.338V217.579Z" transform="translate(-49.226 -0.674)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <path id="Path_95" data-name="Path 95" d="M1396.937,225.3l-.041,4.047-12.322-.067c2.854,4.94-1.647,7.136-1.647,7.136l-1.359-1.592c3.293-2.744.082-5.653.082-5.653l-2.868-.217c-3.924,2.91-.521,5.873-.521,5.873l-1.454,1.482c-5.782-6.093,1.55-9.526,1.55-9.526l.213-3.337-9.435-.862v15.973h-12.5v24.782h46.751V225.879Z" transform="translate(-53.488 -2.278)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <path id="Path_96" data-name="Path 96" d="M1220.144,216.419l-.27,1.178s-2.237.093-2.717,1.523a17.321,17.321,0,0,0-.6,3.65l-1.586.1-.171,2.021,13.408-.419-.078-2.442-2.267.036-.635-3.357a3.25,3.25,0,0,0-2.676-1.276l-.446-1.154a1.027,1.027,0,0,0-.958-.657h0A1.028,1.028,0,0,0,1220.144,216.419Z" transform="translate(-8.047 -0.047)" fill="#161615"/>
                                <path id="Path_97" data-name="Path 97" d="M1399.318,215.895l-.741,1.513s-2.234-.149-2.865,1.221a18.045,18.045,0,0,0-1.011,3.028l-1.588-.073-.371,2.525,13.375,1.032.186-2.436-2.257-.209-.269-3.406a3.249,3.249,0,0,0-2.522-1.558l-.442-1.449a.86.86,0,0,0-.823-.61h0A.749.749,0,0,0,1399.318,215.895Z" transform="translate(-65.056)" fill="#161615"/>
                                <path id="Path_98" data-name="Path 98" d="M1194.455,291.557h-4.776v61.171a1.292,1.292,0,0,0,1.292,1.292h18.3l1.482-2.47,5.763,4.117v-7.74h-5.6v-7.739h4.445l-3.128-4.94,2.964-3.952h-4.775v-9.386h8.068v-6.751l-7.9,2.964h-2.8V304.106h-13.173Z" transform="translate(0 -24.375)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <path id="Path_99" data-name="Path 99" d="M1194.768,316.271v41.576a1.291,1.291,0,0,0,1.291,1.291h11.223V328.182h-2.634V316.271Z" transform="translate(-1.63 -32.292)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <path id="Path_100" data-name="Path 100" d="M1302.8,361.235h-19.595l-1.482-2.592-5.763,4.117v-7.739h5.6v-7.74h-4.446l3.129-4.94-2.965-3.951h4.776V329h-8.069v-6.751l7.9,2.965H1302.8v-13.71h104.406s102.093,18.113,102.587,17.948,12.514,1.811,13.008,12.515c.4,8.754.148,26.542.04,32.729a2.219,2.219,0,0,1-2.222,2.18h-.7a2.223,2.223,0,0,1-2.223-2.223v-3.87h-11.2v3.87a2.224,2.224,0,0,1-2.223,2.223h0a2.223,2.223,0,0,1-2.223-2.223v-13.42H1382.836s-1.314,2-3.454,0Z" transform="translate(-27.009 -30.766)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <path id="Path_101" data-name="Path 101" d="M1399.721,358.936V343.053a5.167,5.167,0,0,0-4.4-5.11l-70.683-10.54V315.382h26.347v5.1h3.623v-5.1h68.5l101.764,18.443s10.209,3.293,10.209,8.069v25.852h-12.514v-8.81Z" transform="translate(-43.235 -32.008)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <path id="Path_102" data-name="Path 102" d="M1384.731,365.214H1291.2V347.348h2.006a1.336,1.336,0,0,0,1.085-2.116l-3.092-4.306v-5.434h16.8l70.933,11.8c4.927.718,5.8,1.343,5.8,5.1Z" transform="translate(-32.524 -38.45)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                                <path id="Path_103" data-name="Path 103" d="M1220.268,334.263l8.069-.411.494-2.553a38.025,38.025,0,0,1,27.664,0l.322,3.254h8.069V343.9l-5.344.041s-14.983-22.261-34-.69l-5.271-.01Z" transform="translate(-9.8 -36.275)" fill="#161615"/>
                                <path id="Path_104" data-name="Path 104" d="M1264.825,371.942l-6.1.412.007,2.552s-17.282,5.846-32.185,0l-.322-3.253h-6.017V362.8h5.179s15.643,21.736,34.662.165h4.776Z" transform="translate(-9.781 -47.2)" fill="#161615"/>
                            </g>
                            <g id="Group_88" data-name="Group 88" transform="translate(495 29)">
                                <text id="_24mm" data-name="24mm" transform="translate(833 518)" fill="#332f2f" font-size="9" font-family="Roboto-Regular, Roboto"><tspan x="-11.353" y="0">24mm</tspan></text>
                                <text id="_31mm" data-name="31mm" transform="translate(833 574)" fill="#332f2f" font-size="9" font-family="Roboto-Regular, Roboto"><tspan x="-11.353" y="0">31mm</tspan></text>
                                <line id="Line_24" data-name="Line 24" y2="18.715" transform="translate(824.119 487.762) rotate(-90)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_29" data-name="Line 29" y2="18.715" transform="translate(824.119 538.762) rotate(-90)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_25" data-name="Line 25" y1="20" transform="translate(833.031 487.5)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_28" data-name="Line 28" y1="40" transform="translate(833.031 522.5)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_31" data-name="Line 31" y2="18.715" transform="translate(824.119 601.902) rotate(-90)" fill="none" stroke="#707070" stroke-width="1"/>
                                <line id="Line_30" data-name="Line 30" y1="22.403" transform="translate(833.031 579.5)" fill="none" stroke="#707070" stroke-width="1"/>
                                <g id="Group_89" data-name="Group 89" transform="translate(-11)">
                                    <text id="_30mm" data-name="30mm" transform="translate(1188 519)" fill="#332f2f" font-size="9" font-family="Roboto-Regular, Roboto"><tspan x="-11.353" y="0">30mm</tspan></text>
                                    <text id="_32mm" data-name="32mm" transform="translate(1188 584)" fill="#332f2f" font-size="9" font-family="Roboto-Regular, Roboto"><tspan x="-11.353" y="0">32mm</tspan></text>
                                    <line id="Line_32" data-name="Line 32" x2="18.335" transform="translate(1179.5 483.762)" fill="none" stroke="#707070" stroke-width="1"/>
                                    <line id="Line_33" data-name="Line 33" y1="24" transform="translate(1188.032 483.5)" fill="none" stroke="#707070" stroke-width="1"/>
                                    <line id="Line_34" data-name="Line 34" y1="47" transform="translate(1188.032 524.5)" fill="none" stroke="#707070" stroke-width="1"/>
                                    <line id="Line_35" data-name="Line 35" x2="18.335" transform="translate(1179.5 542.902)" fill="none" stroke="#707070" stroke-width="1"/>
                                    <line id="Line_36" data-name="Line 36" y2="18.715" transform="translate(1179.119 616.902) rotate(-90)" fill="none" stroke="#707070" stroke-width="1"/>
                                    <line id="Line_37" data-name="Line 37" y1="27.403" transform="translate(1188.032 589.5)" fill="none" stroke="#707070" stroke-width="1"/>
                                </g>
                                <g id="Group_90" data-name="Group 90" transform="translate(0 1)">
                                    <line id="Line_38" data-name="Line 38" y2="18.715" transform="translate(853.462 620.469)" fill="none" stroke="#707070" stroke-width="1"/>
                                    <line id="Line_39" data-name="Line 39" y2="18.715" transform="translate(1159.036 620.469)" fill="none" stroke="#707070" stroke-width="1"/>
                                    <line id="Line_40" data-name="Line 40" x2="128.038" transform="translate(853.462 630.271)" fill="none" stroke="#707070" stroke-width="1"/>
                                    <line id="Line_41" data-name="Line 41" x2="129.536" transform="translate(1029.5 630.271)" fill="none" stroke="#707070" stroke-width="1"/>
                                    <text id="_150mm" data-name="150mm" transform="translate(1005 633)" fill="#332f2f" font-size="9" font-family="Roboto-Regular, Roboto"><tspan x="-13.88" y="0">150mm</tspan></text>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <h4>Low Threshold and Sill</h4>
                </article>
            </div>
        </section>
        <nav class="container sm-fixed-bottom text-center">
            <section class="footer-left col-6 col-lg-3 float-left">
                <button @click="goBack" type="button" class="btn btn-back btn-lg w-100"><i class="fa fa-angle-left"></i> Go Back</button>
            </section>
            <section class="footer-right col-6 col-lg-3 float-right">
                <button @click="next" type="button" href="#" class="btn btn-next btn-lg w-100 btn-disable">Next Step <i
                    class="fa fa-angle-right"></i></button>
            </section>
        </nav>
    </main>
</template>
<script>
export default {
    created() {
        document.querySelectorAll('nav.stages ul li').forEach((el) => {
            if(el.classList.contains('three')) {
                el.classList.add('active');
            }
        });
    },
    methods: {
        next: function (e) {

            if (!e.target.classList.contains('btn-disable')) {
                switch(sessionStorage.ProdConfiguredDirection){
                    case('Inwards'):
                        document.querySelector('body').querySelector('#aluminium-external-french-3000less-step-3-inwards').click();
                        break;
                    case('Outwards'):
                        document.querySelector('body').querySelector('#aluminium-external-french-3000less-step-3-outwards').click();
                        break;
                    default:
                        break;

                }

                document.querySelectorAll('nav.stages ul li').forEach((el) => {
                    if(el.classList.contains('three')) {
                        el.classList.remove('active');
                        el.classList.add('done');
                    }
                });
            }
        },
        goBack: function () {
            window.history.back();
        },
        saveData: function (e) {
            if (e.target.closest('article').classList.contains('option-sill-rebated')) {
                sessionStorage.ProdConfiguredTypeOfSill = 'Rebated';
            }

            if (e.target.closest('article').classList.contains('option-sill-lowthreshold')) {
                sessionStorage.ProdConfiguredTypeOfSill = 'Low Threshold';
            }

            if (e.target.closest('article').classList.contains('option-sill-rebateandsill')) {
                sessionStorage.ProdConfiguredTypeOfSill = 'Rebate and Sill';
            }

            if (e.target.closest('article').classList.contains('option-sill-lowthresholdandsill')) {
                sessionStorage.ProdConfiguredTypeOfSill = 'Low Threshold and Sill';
            }

            document.querySelectorAll(".option").forEach((el) => {
                el.classList.remove('chosen');
            });
            e.target.closest('article').classList.add('chosen');
            document.querySelector('body').querySelector('.btn-next').classList.remove('btn-disable');
        }
    }
}
</script>
