<template>
    <main id="direction-options" class="container option-wrapper">
        <section class="row">
            <h3 class="title col-12">Opening Direction</h3>
            <p  class="description col-12">When viewed from the garden looking back at the house, do you want the doors to open from left to right, or right to left?</p>
        </section>
        <section class="choices row">
            <div class="col-12 col-md-6 col-xl-6 mb-4">
                <article @click="saveData" class="option option-rtl">
                    <i class="far fa-check-circle"></i>
                    <h4>Right to Left</h4>
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 616 360">
                        <path id="Rectangle_8" data-name="Rectangle 8" d="M0,299.13H616V355a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V299.13Z"
                              style="fill:#d5db42"/>
                        <g id="Illustration">
                            <rect id="Rectangle_5" data-name="Rectangle 5" x="72.02" y="51.64" width="471.16" height="242.88"
                                  style="fill:#e8e8e8"/>
                            <rect id="Rectangle_6" data-name="Rectangle 6" x="72.02" y="56.83" width="471.16" height="11.25"
                                  style="fill:#d2d0d1"/>
                            <rect id="Rectangle_7" data-name="Rectangle 7" x="155.65" y="88.12" width="303.9" height="193.64"
                                  style="fill:#fff;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                            <path id="arrow"
                                  d="M474.12,206.82l-20.75-20.69a1.69,1.69,0,0,1,0-2.38h0l20.75-20.7a1.7,1.7,0,0,1,2.38,0h0l2.74,2.74a1.67,1.67,0,0,1,0,2.35l-.05.05-13.58,13.1h34.79A1.68,1.68,0,0,1,502.1,183v3.92a1.69,1.69,0,0,1-1.68,1.68H465.63l13.58,13.1a1.68,1.68,0,0,1,0,2.38h0l-2.74,2.74a1.67,1.67,0,0,1-2.34.07l0,0Z"
                                  style="fill:#f4990a"/>
                            <path id="Path_2" data-name="Path 2"
                                  d="M532.16,6.78H83A2.57,2.57,0,0,0,80.7,8.54L61.58,52.78c-.72,1.92.52,10.54,2.35,10.54H551.27c1.83,0,3.07-8.62,2.35-10.54L534.5,8.54A2.57,2.57,0,0,0,532.16,6.78Z"
                                  style="fill:#6a5647"/>
                            <path id="Path_3" data-name="Path 3"
                                  d="M532.16.29H83A2.57,2.57,0,0,0,80.7,2.05L61.58,52.79c-.72,1.91.52,4,2.35,4H551.27c1.83,0,3.07-2.13,2.35-4L534.5,2.05A2.57,2.57,0,0,0,532.16.29Z"
                                  style="fill:#947668"/>
                            <path id="Path_4" data-name="Path 4"
                                  d="M465.17,286.66H150a1.08,1.08,0,0,1-1-1.13,1,1,0,0,1,.16-.54h0a4.1,4.1,0,0,1,3.49-2H462.82a3.86,3.86,0,0,1,3.3,1.9h0a1.17,1.17,0,0,1-.95,1.78Z"
                                  style="fill:#464545"/>
                            <path id="Path_5" data-name="Path 5" d="M578.19,327.39H37.81l33.75-32.88H544.44Z" style="fill:#bababa"/>
                            <g id="Group_9" data-name="Group 9">
                                <g id="Group_7" data-name="Group 7">
                                    <g id="Group_4" data-name="Group 4">
                                        <path id="Path_6" data-name="Path 6"
                                              d="M158.07,90.32V278.77l87.74,15.47a1.33,1.33,0,0,0,1.53-1.07,2,2,0,0,0,0-.23V107.17a1.32,1.32,0,0,0-1.09-1.3ZM238.7,285.49l-72-12.7V99.34l72,12.7Z"
                                              style="fill:#5b5b5b"/>
                                        <path id="Path_7" data-name="Path 7"
                                              d="M245.81,294.24,159.16,279a1.32,1.32,0,0,1-1.09-1.3V91.89a1.32,1.32,0,0,1,1.32-1.32l.23,0,86.65,15.28a1.32,1.32,0,0,1,1.09,1.3V292.94a1.33,1.33,0,0,1-1.33,1.32Z"
                                              style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                        <path id="Path_8" data-name="Path 8" d="M238.7,285.49l-72-12.7V99.34l72,12.7Z"
                                              style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                        <path id="Path_9" data-name="Path 9" d="M172.34,164.19v14l54-63.22-10-1.77Z" style="fill:#fff"/>
                                        <path id="Path_10" data-name="Path 10" d="M172.34,145.05v14l38.4-46.83-10.05-1.77Z"
                                              style="fill:#fff"/>
                                    </g>
                                    <g id="Group_5" data-name="Group 5">
                                        <path id="Path_11" data-name="Path 11"
                                              d="M249.49,107.17V292.94a1.32,1.32,0,0,0,1.32,1.32l.23,0L337.69,279a1.3,1.3,0,0,0,1.08-1.3V91.89a1.31,1.31,0,0,0-1.31-1.32l-.23,0-86.65,15.28A1.32,1.32,0,0,0,249.49,107.17Zm80.63,165.62-72,12.7V112l72-12.7Z"
                                              style="fill:#5b5b5b"/>
                                        <path id="Path_12" data-name="Path 12"
                                              d="M337.69,279,251,294.24a1.33,1.33,0,0,1-1.53-1.07.93.93,0,0,1,0-.23V107.17a1.32,1.32,0,0,1,1.09-1.3l86.65-15.28a1.31,1.31,0,0,1,1.52,1.07.9.9,0,0,1,0,.23V277.66A1.31,1.31,0,0,1,337.69,279Z"
                                              style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                        <path id="Path_13" data-name="Path 13" d="M330.12,272.79l-72,12.7V112l72-12.7Z"
                                              style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                        <path id="Path_14" data-name="Path 14" d="M263.75,174.91v14l54-82.27-10,1.77Z" style="fill:#fff"/>
                                        <path id="Path_15" data-name="Path 15" d="M263.75,155.77v14l38.4-60.38-10,1.77Z" style="fill:#fff"/>
                                    </g>
                                    <g id="Group_6" data-name="Group 6">
                                        <path id="Path_16" data-name="Path 16"
                                              d="M340.91,91.89V277.66A1.32,1.32,0,0,0,342,279l88.2,15.55V106.06L342.46,90.59a1.33,1.33,0,0,0-1.53,1.07A1.79,1.79,0,0,0,340.91,91.89Zm80.63,193.6-72-12.69V99.34l72,12.7Z"
                                              style="fill:#5b5b5b"/>
                                        <path id="Path_17" data-name="Path 17"
                                              d="M428.65,294.24,342,279a1.32,1.32,0,0,1-1.09-1.3V91.89a1.32,1.32,0,0,1,1.32-1.32l.23,0,86.65,15.28a1.3,1.3,0,0,1,1.08,1.3V292.94a1.32,1.32,0,0,1-1.31,1.32Z"
                                              style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                        <path id="Path_18" data-name="Path 18" d="M421.54,285.49l-72-12.7V99.34l72,12.7Z"
                                              style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                        <path id="Path_19" data-name="Path 19" d="M355.17,164.19v14l54-63.22-10-1.77Z" style="fill:#fff"/>
                                        <path id="Path_20" data-name="Path 20" d="M355.17,145.05v14l38.4-46.83-10-1.77Z" style="fill:#fff"/>
                                    </g>
                                </g>
                                <g id="Group_8" data-name="Group 8">
                                    <path id="Path_21" data-name="Path 21"
                                          d="M425.13,203.42h0a1.72,1.72,0,0,1-1.37-1.61V190.86a1.13,1.13,0,0,1,1.09-1.16,1.33,1.33,0,0,1,.28,0h0a1.7,1.7,0,0,1,1.37,1.61v10.95a1.11,1.11,0,0,1-1.09,1.15A.88.88,0,0,1,425.13,203.42Z"
                                          style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                                    <path id="Path_22" data-name="Path 22"
                                          d="M418.17,192.61h0a1.12,1.12,0,0,1,1.08-1.15.93.93,0,0,1,.29,0l4.79.85a1.7,1.7,0,0,1,1.37,1.61h0a1.11,1.11,0,0,1-1.08,1.15,1,1,0,0,1-.29,0l-4.79-.85A1.72,1.72,0,0,1,418.17,192.61Z"
                                          style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                                </g>
                            </g>
                            <rect id="Rectangle_9" data-name="Rectangle 9" x="37.81" y="327.39" width="540.39" height="7.28"
                                  style="fill:#757474"/>
                        </g>
                    </svg>
                </article>
            </div>
            <div class="col-12 col-md-6 col-xl-6 mb-4">
                <article @click="saveData" class="option option-ltr">
                    <i class="far fa-check-circle hidden"></i>
                    <h4>Left to Right</h4>
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 616 360">
                        <path id="Rectangle_8" data-name="Rectangle 8" d="M616,299.13V355a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V299.13H616Z"
                              style="fill:#d5db42"/>
                        <g id="Illustration">
                            <rect id="Rectangle_5" data-name="Rectangle 5" x="72.82" y="51.64" width="471.16" height="242.88"
                                  style="fill:#e8e8e8"/>
                            <rect id="Rectangle_6" data-name="Rectangle 6" x="72.82" y="56.83" width="471.16" height="11.25"
                                  style="fill:#d2d0d1"/>
                            <rect id="Rectangle_7" data-name="Rectangle 7" x="156.45" y="88.12" width="303.9" height="193.64"
                                  style="fill:#fff;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                            <path id="arrow"
                                  d="M141.88,206.82l0,0a1.67,1.67,0,0,1-2.34-.07l-2.74-2.74h0a1.68,1.68,0,0,1,0-2.38l13.58-13.1H115.58a1.69,1.69,0,0,1-1.68-1.68V183a1.68,1.68,0,0,1,1.68-1.67h34.79l-13.58-13.1,0-.05a1.67,1.67,0,0,1,0-2.35l2.74-2.74h0a1.7,1.7,0,0,1,2.38,0l20.75,20.7h0a1.69,1.69,0,0,1,0,2.38l-20.75,20.69Z"
                                  style="fill:#f4990a"/>
                            <path id="Path_2" data-name="Path 2"
                                  d="M81.5,8.54,62.38,52.78c-.72,1.92.52,10.54,2.35,10.54H552.07c1.83,0,3.07-8.62,2.35-10.54L535.3,8.54A2.57,2.57,0,0,0,533,6.78H83.84A2.57,2.57,0,0,0,81.5,8.54Z"
                                  style="fill:#6a5647"/>
                            <path id="Path_3" data-name="Path 3"
                                  d="M81.5,2.05,62.38,52.79c-.72,1.91.52,4,2.35,4H552.07c1.83,0,3.07-2.13,2.35-4L535.3,2.05A2.57,2.57,0,0,0,533,.29H83.84A2.57,2.57,0,0,0,81.5,2.05Z"
                                  style="fill:#947668"/>
                            <path id="Path_4" data-name="Path 4"
                                  d="M150.25,286.48a1.16,1.16,0,0,1-.37-1.6h0a3.86,3.86,0,0,1,3.3-1.9H463.42a4.1,4.1,0,0,1,3.49,2h0a1,1,0,0,1,.16.54,1.08,1.08,0,0,1-1,1.13H150.83A1.17,1.17,0,0,1,150.25,286.48Z"
                                  style="fill:#464545"/>
                            <path id="Path_5" data-name="Path 5" d="M71.56,294.51H544.44l33.75,32.88H37.81Z" style="fill:#bababa"/>
                            <g id="Group_9" data-name="Group 9">
                                <g id="Group_7" data-name="Group 7">
                                    <g id="Group_4" data-name="Group 4">
                                        <path id="Path_6" data-name="Path 6"
                                              d="M369.73,105.87a1.32,1.32,0,0,0-1.09,1.3V292.94a2,2,0,0,0,0,.23,1.33,1.33,0,0,0,1.53,1.07l87.74-15.47V90.32ZM377.3,112l72-12.7V272.79l-72,12.7Z"
                                              style="fill:#5b5b5b"/>
                                        <path id="Path_7" data-name="Path 7"
                                              d="M370,294.26a1.33,1.33,0,0,1-1.33-1.32V107.17a1.32,1.32,0,0,1,1.09-1.3l86.65-15.28.23,0a1.32,1.32,0,0,1,1.32,1.32V277.66a1.32,1.32,0,0,1-1.09,1.3l-86.65,15.28Z"
                                              style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                        <path id="Path_8" data-name="Path 8" d="M377.3,112l72-12.7V272.79l-72,12.7Z"
                                              style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                        <path id="Path_9" data-name="Path 9" d="M399.7,113.22l-10,1.77,54,63.22v-14Z" style="fill:#fff"/>
                                        <path id="Path_10" data-name="Path 10" d="M415.31,110.47l-10.05,1.77,38.4,46.83v-14Z"
                                              style="fill:#fff"/>
                                    </g>
                                    <g id="Group_5" data-name="Group 5">
                                        <path id="Path_11" data-name="Path 11"
                                              d="M365.42,105.87,278.77,90.59l-.23,0a1.31,1.31,0,0,0-1.31,1.32V277.66a1.3,1.3,0,0,0,1.08,1.3L365,294.24l.23,0a1.32,1.32,0,0,0,1.32-1.32V107.17A1.32,1.32,0,0,0,365.42,105.87Zm-79.54-6.53,72,12.7V285.49l-72-12.7Z"
                                              style="fill:#5b5b5b"/>
                                        <path id="Path_12" data-name="Path 12"
                                              d="M277.23,277.66V91.89a.9.9,0,0,1,0-.23,1.31,1.31,0,0,1,1.52-1.07l86.65,15.28a1.32,1.32,0,0,1,1.09,1.3V292.94a.93.93,0,0,1,0,.23,1.33,1.33,0,0,1-1.53,1.07L278.31,279A1.31,1.31,0,0,1,277.23,277.66Z"
                                              style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                        <path id="Path_13" data-name="Path 13" d="M285.88,99.34l72,12.7V285.49l-72-12.7Z"
                                              style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                        <path id="Path_14" data-name="Path 14" d="M308.28,108.43l-10-1.77,54,82.27v-14Z" style="fill:#fff"/>
                                        <path id="Path_15" data-name="Path 15" d="M323.89,111.18l-10-1.77,38.4,60.38v-14Z"
                                              style="fill:#fff"/>
                                    </g>
                                    <g id="Group_6" data-name="Group 6">
                                        <path id="Path_16" data-name="Path 16"
                                              d="M275.07,91.66a1.33,1.33,0,0,0-1.53-1.07L185.8,106.06V294.51L274,279a1.32,1.32,0,0,0,1.09-1.3V91.89A1.79,1.79,0,0,0,275.07,91.66ZM194.46,112l72-12.7V272.8l-72,12.69Z"
                                              style="fill:#5b5b5b"/>
                                        <path id="Path_17" data-name="Path 17"
                                              d="M187.12,294.26a1.32,1.32,0,0,1-1.31-1.32V107.17a1.3,1.3,0,0,1,1.08-1.3l86.65-15.28.23,0a1.32,1.32,0,0,1,1.32,1.32V277.66A1.32,1.32,0,0,1,274,279l-86.65,15.28Z"
                                              style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                        <path id="Path_18" data-name="Path 18" d="M194.46,112l72-12.7V272.79l-72,12.7Z"
                                              style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                        <path id="Path_19" data-name="Path 19" d="M216.86,113.22l-10,1.77,54,63.22v-14Z" style="fill:#fff"/>
                                        <path id="Path_20" data-name="Path 20" d="M232.47,110.47l-10,1.77,38.4,46.83v-14Z"
                                              style="fill:#fff"/>
                                    </g>
                                </g>
                                <g id="Group_8" data-name="Group 8">
                                    <path id="Path_21" data-name="Path 21"
                                          d="M190.59,203.44a1.11,1.11,0,0,1-1.09-1.15V191.34a1.7,1.7,0,0,1,1.37-1.61h0a1.33,1.33,0,0,1,.28,0,1.13,1.13,0,0,1,1.09,1.16v10.95a1.72,1.72,0,0,1-1.37,1.61h0A.88.88,0,0,1,190.59,203.44Z"
                                          style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                                    <path id="Path_22" data-name="Path 22"
                                          d="M196.46,194.22l-4.79.85a1,1,0,0,1-.29,0,1.11,1.11,0,0,1-1.08-1.15h0a1.7,1.7,0,0,1,1.37-1.61l4.79-.85a.93.93,0,0,1,.29,0,1.12,1.12,0,0,1,1.08,1.15h0A1.72,1.72,0,0,1,196.46,194.22Z"
                                          style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                                </g>
                            </g>
                            <rect id="Rectangle_9" data-name="Rectangle 9" x="37.81" y="327.39" width="540.39" height="7.28"
                                  style="fill:#757474"/>
                        </g>
                    </svg>
                </article>
            </div>
        </section>
        <nav class="container sm-fixed-bottom text-center">
            <section class="footer-left col-6 col-lg-3 float-left">
                <button @click="goBack" type="button" class="btn btn-back btn-lg w-100"><i class="fa fa-angle-left"></i> Go Back</button>
            </section>
            <section class="footer-right col-6 col-lg-3 float-right">
                <button @click="next" type="button" href="#" class="btn btn-next btn-lg w-100 btn-disable">Next Step <i
                    class="fa fa-angle-right"></i></button>
            </section>
        </nav>
    </main>
</template>
<script>
export default {
    created() {
        document.querySelectorAll('nav.stages ul li').forEach((el) => {
            if(el.classList.contains('one')) {
                el.classList.add('active');
            }
            el.classList.remove('hidden');

        });
    },
    methods: {
        next: function (e) {
            if (!e.target.classList.contains('btn-disable')) {
                switch(sessionStorage.ProdConfiguredDirection){
                    case('Right to Left'):
                        document.querySelector('body').querySelector('#aluminium-external-bifold-3000less-step-2-rtl').click();
                        break;
                    case('Left to Right'):
                        document.querySelector('body').querySelector('#aluminium-external-bifold-3000less-step-2-ltr').click();
                        break;
                    default:
                        break;

                }

                document.querySelectorAll('nav.stages ul li').forEach((el) => {
                    if(el.classList.contains('one')) {
                        el.classList.remove('active');
                        el.classList.add('done');
                    }
                });
            }
        },
        goBack: function () {
            for(let [key, val] of Object.entries(sessionStorage)){
                if(key !== 'token'){
                    sessionStorage.removeItem(key);
                }
            }
            window.history.back();
        },
        saveData: function (e) {
            if (e.target.closest('article').classList.contains('option-rtl')) {
                sessionStorage.ProdConfiguredDirection = 'Right to Left';
            }

            if (e.target.closest('article').classList.contains('option-ltr')) {
                sessionStorage.ProdConfiguredDirection = 'Left to Right';
            }

            document.querySelectorAll(".option").forEach((el) => {
                el.classList.remove('chosen');
            });
            e.target.closest('article').classList.add('chosen');
            document.querySelector('body').querySelector('.btn-next').classList.remove('btn-disable');
        }
    }
}
</script>
