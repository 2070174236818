<template>
    <div class="container">
        <router-view></router-view>
        <router-link :id="'dashboard'" to="/"></router-link>
        <router-link :id="'verify'" to="/verify"></router-link>
        <router-link :id="'thankyou'" to="/thankyou"></router-link>

        <router-link :id="'aluminium-external-bifold-3000less-step-1'" to="/aluminium-external-bifold-3000less/step-1"></router-link>
        <router-link :id="'aluminium-external-bifold-3000less-step-2-rtl'" to="/aluminium-external-bifold-3000less/step-2/rtl"></router-link>
        <router-link :id="'aluminium-external-bifold-3000less-step-2-ltr'" to="/aluminium-external-bifold-3000less/step-2/ltr"></router-link>
        <router-link :id="'aluminium-external-bifold-3000less-step-3'" to="/aluminium-external-bifold-3000less/step-3"></router-link>
        <router-link :id="'aluminium-external-bifold-3000less-step-4'" to="/aluminium-external-bifold-3000less/step-4"></router-link>

        <router-link :id="'aluminium-external-bifold-3000plus-step-1'" to="/aluminium-external-bifold-3000plus/step-1"></router-link>
        <router-link :id="'aluminium-external-bifold-3000plus-step-2-rtl'" to="/aluminium-external-bifold-3000plus/step-2/rtl"></router-link>
        <router-link :id="'aluminium-external-bifold-3000plus-step-2-ltr'" to="/aluminium-external-bifold-3000plus/step-2/ltr"></router-link>
        <router-link :id="'aluminium-external-bifold-3000plus-step-3'" to="/aluminium-external-bifold-3000plus/step-3"></router-link>

        <router-link :id="'aluminium-external-sliding-step-1'" to="/aluminium-external-sliding/step-1"></router-link>
        <router-link :id="'aluminium-external-sliding-step-2'" to="/aluminium-external-sliding/step-2"></router-link>

        <router-link :id="'aluminium-external-french-3000less-step-1'" to="/aluminium-external-french-3000less/step-1"></router-link>
        <router-link :id="'aluminium-external-french-3000less-step-2'" to="/aluminium-external-french-3000less/step-2"></router-link>
        <router-link :id="'aluminium-external-french-3000less-step-3-inwards'" to="/aluminium-external-french-3000less/step-3/inwards"></router-link>
        <router-link :id="'aluminium-external-french-3000less-step-3-outwards'" to="/aluminium-external-french-3000less/step-3/outwards"></router-link>
        <router-link :id="'aluminium-external-french-3000less-step-4'" to="/aluminium-external-french-3000less/step-4"></router-link>

        <router-link :id="'aluminium-external-french-3000plus-step-1'" to="/aluminium-external-french-3000plus/step-1"></router-link>
        <router-link :id="'aluminium-external-french-3000plus-step-2'" to="/aluminium-external-french-3000plus/step-2"></router-link>
        <router-link :id="'aluminium-external-french-3000plus-step-3-inwards'" to="/aluminium-external-french-3000plus/step-3/inwards"></router-link>
        <router-link :id="'aluminium-external-french-3000plus-step-3-outwards'" to="/aluminium-external-french-3000plus/step-3/outwards"></router-link>
    </div>
</template>
<script>
export default {

}
</script>
