<template>
    <div class="col-12 col-md-6 col-xl-3 mb-4">
        <article :id="ProdId" class="product" :class="ProdConfigured">
            <img :src="ProdImg"/>
            <h4>{{ ProdName }}</h4>
            <ul>
                <li>Direction:<span>{{ProdConfiguredDirection}}</span></li>
                <li>Inwards or Outwards Opening:<span>{{ProdConfiguredInwardsOrOutwards}}</span></li>
                <li>Sill or Threshold:<span>{{ProdConfiguredTypeOfSill}}</span></li>
            </ul>
            <button :data-prodid="ProdId" v-if="ProdConfigured === null" type="button" class="aluminium-external-bifold-3000plus-step-1-btn-choose btn btn-choose">Click to Configure</button>
            <button :data-prodid="ProdId" v-if="ProdConfigured === 'done'" type="button" class="aluminium-external-bifold-3000plus-step-1-btn-choose btn btn-confirm">Configured (Click to reconfigure)</button>
        </article>
    </div>
</template>
<script>
    export default {
        props: [
            'ProdName',
            'ProdId',
            'ProdImg',
            'ProdConfiguredDirection',
            'ProdConfiguredInwardsOrOutwards',
            'ProdConfiguredTypeOfSill',
            'ProdConfigured'
        ]
    }
</script>
