<template>
    <main id="direction-options" class="container option-wrapper">
        <section class="row">
            <h3 class="title col-12">Handing</h3>
            <p  class="description col-12">When viewed from the outside, do you want the handled lead door on the right or left?</p>
        </section>
        <section class="choices row">
            <div class="col-12 col-md-6 col-xl-6 mb-4">
                <article @click="saveData" class="option option-handing-right">
                    <i class="far fa-check-circle"></i>
                    <h4>Right</h4>
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         viewBox="0 0 615.72 359.71">
                        <defs>
                            <clipPath id="clip-path" transform="translate(-0.28 -0.29)">
                                <rect x="156.44" y="86.65" width="303.9" height="195.11" style="fill:none"/>
                            </clipPath>
                        </defs>
                        <path id="Rectangle_8" data-name="Rectangle 8" d="M.28,299.13H616V355a5,5,0,0,1-5,5H5.28a5,5,0,0,1-5-5Z"
                              transform="translate(-0.28 -0.29)" style="fill:#d5db42"/>
                        <g id="Illustration">
                            <rect id="Rectangle_5" data-name="Rectangle 5" x="72.54" y="51.35" width="471.16" height="242.88"
                                  style="fill:#e8e8e8"/>
                            <rect id="Rectangle_6" data-name="Rectangle 6" x="72.54" y="56.54" width="471.16" height="11.25"
                                  style="fill:#d2d0d1"/>
                            <rect id="Rectangle_7-2" data-name="Rectangle 7-2" x="156.17" y="87.83" width="303.9" height="193.64"
                                  style="fill:#fff;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                            <path id="Path_2" data-name="Path 2"
                                  d="M81.5,8.54,62.38,52.78c-.72,1.92.52,10.54,2.35,10.54H552.07c1.83,0,3.07-8.62,2.35-10.54L535.3,8.54A2.57,2.57,0,0,0,533,6.78H83.84A2.57,2.57,0,0,0,81.5,8.54Z"
                                  transform="translate(-0.28 -0.29)" style="fill:#6a5647"/>
                            <path id="Path_3" data-name="Path 3"
                                  d="M81.5,2.05,62.38,52.79c-.72,1.91.52,4,2.35,4H552.07c1.83,0,3.07-2.13,2.35-4L535.3,2.05A2.57,2.57,0,0,0,533,.29H83.84A2.57,2.57,0,0,0,81.5,2.05Z"
                                  transform="translate(-0.28 -0.29)" style="fill:#947668"/>
                            <path id="Path_4" data-name="Path 4"
                                  d="M150.25,286.48a1.15,1.15,0,0,1-.38-1.58h0a3.86,3.86,0,0,1,3.3-1.9H463.42a4.1,4.1,0,0,1,3.49,2h0a1,1,0,0,1,.16.54,1.08,1.08,0,0,1-1,1.13H150.83A1.08,1.08,0,0,1,150.25,286.48Z"
                                  transform="translate(-0.28 -0.29)" style="fill:#464545"/>
                            <path id="Path_5" data-name="Path 5" d="M71.56,294.51H544.44l33.75,32.88H37.81Z"
                                  transform="translate(-0.28 -0.29)" style="fill:#bababa"/>
                            <rect id="Rectangle_9" data-name="Rectangle 9" x="37.53" y="327.1" width="540.39" height="7.28"
                                  style="fill:#757474"/>
                            <g style="clip-path:url(#clip-path)">
                                <g id="Left_Open" data-name="Left Open">
                                    <g id="Group_7" data-name="Group 7">
                                        <g id="Group_6" data-name="Group 6">
                                            <path id="Path_16" data-name="Path 16"
                                                  d="M329,77.81V265a1.27,1.27,0,0,0,.8,1.31L383.41,282V92.09L330.16,76.5c-.52-.12-1,.36-1.12,1.08A1.85,1.85,0,0,0,329,77.81Zm48,195.07L335.35,260.1V85.28l41.72,12.8Z"
                                                  transform="translate(-0.28 -0.29)" style="fill:#5b5b5b"/>
                                            <path id="Path_17" data-name="Path 17"
                                                  d="M382.28,281.74l-52.45-15.4A1.29,1.29,0,0,1,329,265V77.83h0c0-.73.43-1.33,1-1.33h.17l52.45,15.4a1.27,1.27,0,0,1,.79,1.31V280.39c0,.73-.42,1.32-1,1.33Z"
                                                  transform="translate(-0.28 -0.29)"
                                                  style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                            <path id="Path_18" data-name="Path 18" d="M377.07,272.88l-41.72-12.79V85.28l41.72,12.8Z"
                                                  transform="translate(-0.28 -0.29)"
                                                  style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.8500000238418579;isolation:isolate"/>
                                            <path id="Path_19" data-name="Path 19" d="M338.72,137.83v10.49l30.14-48.38-5.44-1.32Z"
                                                  transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                            <path id="Path_20" data-name="Path 20" d="M338.71,123.49V134l21.65-36.1-5.45-1.32Z"
                                                  transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                        </g>
                                    </g>
                                    <g id="Group_8" data-name="Group 8">
                                        <path id="Path_21" data-name="Path 21"
                                              d="M331.07,179.17h0a1.69,1.69,0,0,1-1-1.62v-11a1,1,0,0,1,.8-1.17h.2a1.68,1.68,0,0,1,1,1.63v11c0,.62-.35,1.13-.8,1.14A.64.64,0,0,1,331.07,179.17Z"
                                              transform="translate(-0.28 -0.29)" style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                                        <path id="Path_22" data-name="Path 22"
                                              d="M326,168.25h0c0-.62.34-1.14.79-1.16a.38.38,0,0,1,.21,0l3.51.85a1.68,1.68,0,0,1,1,1.63h0c0,.61-.34,1.13-.79,1.14a.38.38,0,0,1-.21,0l-3.51-.85A1.65,1.65,0,0,1,326,168.25Z"
                                              transform="translate(-0.28 -0.29)" style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                                    </g>
                                </g>
                                <g id="Left_Open-2" data-name="Left Open-2">
                                    <g id="Group_7-2" data-name="Group 7-2">
                                        <g id="Group_6-2" data-name="Group 6-2">
                                            <path id="Path_16-2" data-name="Path 16-2"
                                                  d="M289.82,77.44c-.1-.73-.6-1.21-1.12-1.08L235.45,91.94V281.82L289,266.2a1.28,1.28,0,0,0,.8-1.31V77.67A1.79,1.79,0,0,0,289.82,77.44Zm-48,20.49,41.72-12.8V260l-41.72,12.79Z"
                                                  transform="translate(-0.28 -0.29)" style="fill:#5b5b5b"/>
                                            <path id="Path_17-2" data-name="Path 17-2"
                                                  d="M236.42,281.57c-.53,0-1-.6-1-1.33V93.06a1.27,1.27,0,0,1,.79-1.31L288.7,76.36h.17a1.21,1.21,0,0,1,1,1.33h0V264.85a1.29,1.29,0,0,1-.8,1.35l-52.45,15.39Z"
                                                  transform="translate(-0.28 -0.29)"
                                                  style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                            <path id="Path_18-2" data-name="Path 18-2" d="M241.79,97.93l41.72-12.8V259.94l-41.72,12.8Z"
                                                  transform="translate(-0.28 -0.29)"
                                                  style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.8500000238418579;isolation:isolate"/>
                                            <path id="Path_19-2" data-name="Path 19-2" d="M255.44,98.47,250,99.8l30.14,48.37V137.68Z"
                                                  transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                            <path id="Path_20-2" data-name="Path 20-2" d="M264,96.41l-5.45,1.33,21.65,36.09V123.34Z"
                                                  transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <rect id="Rectangle_7-3" data-name="Rectangle 7-3" x="156.17" y="87.83" width="303.9" height="193.64"
                              style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                        <g id="French_doors" data-name="French doors">
                            <g id="Group_55" data-name="Group 55">
                                <path id="Path_170" data-name="Path 170" d="M160,90.92V281.71h74.47V90.92ZM226.42,274.8H168V97.88h58.43Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#5b5b5b"/>
                                <rect id="Rectangle_112" data-name="Rectangle 112" x="159.67" y="90.63" width="74.49" height="190.85"
                                      style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                <rect id="Rectangle_113" data-name="Rectangle 113" x="167.71" y="97.59" width="58.42" height="176.92"
                                      style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.8500000238418579;isolation:isolate"/>
                                <path id="Path_171" data-name="Path 171" d="M173.2,140.71v9.09l34.14-47.14H201Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                <path id="Path_172" data-name="Path 172" d="M173.2,128.31v9.09l24.89-34.74h-6.5Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                            </g>
                            <g id="Group_55-2" data-name="Group 55-2">
                                <path id="Path_170-2" data-name="Path 170-2"
                                      d="M383.42,90.92V281.71h74.47V90.92ZM449.88,274.8H391.46V97.88h58.42Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#5b5b5b"/>
                                <rect id="Rectangle_112-2" data-name="Rectangle 112-2" x="383.14" y="90.63" width="74.49" height="190.85"
                                      style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                <rect id="Rectangle_113-2" data-name="Rectangle 113-2" x="391.18" y="97.59" width="58.42" height="176.92"
                                      style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.8500000238418579;isolation:isolate"/>
                                <path id="Path_171-2" data-name="Path 171-2" d="M396.67,140.71v9.09l34.13-47.14h-6.33Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                <path id="Path_172-2" data-name="Path 172-2" d="M396.67,128.31v9.09l24.88-34.74h-6.49Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                            </g>
                        </g>
                    </svg>
                </article>
            </div>
            <div class="col-12 col-md-6 col-xl-6 mb-4">
                <article @click="saveData" class="option option-handing-left">
                    <i class="far fa-check-circle hidden"></i>
                    <h4>Left</h4>
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         viewBox="0 0 615.72 359.71">
                        <defs>
                            <clipPath id="clip-path" transform="translate(-0.28 -0.29)">
                                <rect x="156.44" y="86.65" width="303.9" height="195.11" style="fill:none"/>
                            </clipPath>
                        </defs>
                        <path id="Rectangle_8" data-name="Rectangle 8" d="M.28,299.13H616V355a5,5,0,0,1-5,5H5.28a5,5,0,0,1-5-5Z"
                              transform="translate(-0.28 -0.29)" style="fill:#d5db42"/>
                        <g id="Illustration">
                            <rect id="Rectangle_5" data-name="Rectangle 5" x="72.54" y="51.35" width="471.16" height="242.88"
                                  style="fill:#e8e8e8"/>
                            <rect id="Rectangle_6" data-name="Rectangle 6" x="72.54" y="56.54" width="471.16" height="11.25"
                                  style="fill:#d2d0d1"/>
                            <rect id="Rectangle_7-2" data-name="Rectangle 7-2" x="156.17" y="87.83" width="303.9" height="193.64"
                                  style="fill:#fff;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                            <path id="Path_2" data-name="Path 2"
                                  d="M81.5,8.54,62.38,52.78c-.72,1.92.52,10.54,2.35,10.54H552.07c1.83,0,3.07-8.62,2.35-10.54L535.3,8.54A2.57,2.57,0,0,0,533,6.78H83.84A2.57,2.57,0,0,0,81.5,8.54Z"
                                  transform="translate(-0.28 -0.29)" style="fill:#6a5647"/>
                            <path id="Path_3" data-name="Path 3"
                                  d="M81.5,2.05,62.38,52.79c-.72,1.91.52,4,2.35,4H552.07c1.83,0,3.07-2.13,2.35-4L535.3,2.05A2.57,2.57,0,0,0,533,.29H83.84A2.57,2.57,0,0,0,81.5,2.05Z"
                                  transform="translate(-0.28 -0.29)" style="fill:#947668"/>
                            <path id="Path_4" data-name="Path 4"
                                  d="M150.25,286.48a1.15,1.15,0,0,1-.38-1.58h0a3.86,3.86,0,0,1,3.3-1.9H463.42a4.1,4.1,0,0,1,3.49,2h0a1,1,0,0,1,.16.54,1.08,1.08,0,0,1-1,1.13H150.83A1.08,1.08,0,0,1,150.25,286.48Z"
                                  transform="translate(-0.28 -0.29)" style="fill:#464545"/>
                            <path id="Path_5" data-name="Path 5" d="M71.56,294.51H544.44l33.75,32.88H37.81Z"
                                  transform="translate(-0.28 -0.29)" style="fill:#bababa"/>
                            <rect id="Rectangle_9" data-name="Rectangle 9" x="37.53" y="327.1" width="540.39" height="7.28"
                                  style="fill:#757474"/>
                            <g style="clip-path:url(#clip-path)">
                                <g id="Left_Open" data-name="Left Open">
                                    <g id="Group_7" data-name="Group 7">
                                        <g id="Group_6" data-name="Group 6">
                                            <path id="Path_16" data-name="Path 16"
                                                  d="M329,77.81V265a1.27,1.27,0,0,0,.8,1.31L383.41,282V92.09L330.16,76.5c-.52-.12-1,.36-1.12,1.08A1.85,1.85,0,0,0,329,77.81Zm48,195.07L335.35,260.1V85.28l41.72,12.8Z"
                                                  transform="translate(-0.28 -0.29)" style="fill:#5b5b5b"/>
                                            <path id="Path_17" data-name="Path 17"
                                                  d="M382.28,281.74l-52.45-15.4A1.29,1.29,0,0,1,329,265V77.83h0c0-.73.43-1.33,1-1.33h.17l52.45,15.4a1.27,1.27,0,0,1,.79,1.31V280.39c0,.73-.42,1.32-1,1.33Z"
                                                  transform="translate(-0.28 -0.29)"
                                                  style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                            <path id="Path_18" data-name="Path 18" d="M377.07,272.88l-41.72-12.79V85.28l41.72,12.8Z"
                                                  transform="translate(-0.28 -0.29)"
                                                  style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.8500000238418579;isolation:isolate"/>
                                            <path id="Path_19" data-name="Path 19" d="M338.72,137.83v10.49l30.14-48.38-5.44-1.32Z"
                                                  transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                            <path id="Path_20" data-name="Path 20" d="M338.71,123.49V134l21.65-36.1-5.45-1.32Z"
                                                  transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                        </g>
                                    </g>
                                </g>
                                <g id="Left_Open-2" data-name="Left Open-2">
                                    <g id="Group_7-2" data-name="Group 7-2">
                                        <g id="Group_6-2" data-name="Group 6-2">
                                            <path id="Path_16-2" data-name="Path 16-2"
                                                  d="M289.82,77.44c-.1-.73-.6-1.21-1.12-1.08L235.45,91.94V281.82L289,266.2a1.28,1.28,0,0,0,.8-1.31V77.67A1.79,1.79,0,0,0,289.82,77.44Zm-48,20.49,41.72-12.8V260l-41.72,12.79Z"
                                                  transform="translate(-0.28 -0.29)" style="fill:#5b5b5b"/>
                                            <path id="Path_17-2" data-name="Path 17-2"
                                                  d="M236.42,281.57c-.53,0-1-.6-1-1.33V93.06a1.27,1.27,0,0,1,.79-1.31L288.7,76.36h.17a1.21,1.21,0,0,1,1,1.33h0V264.85a1.29,1.29,0,0,1-.8,1.35l-52.45,15.39Z"
                                                  transform="translate(-0.28 -0.29)"
                                                  style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                            <path id="Path_18-2" data-name="Path 18-2" d="M241.79,97.93l41.72-12.8V259.94l-41.72,12.8Z"
                                                  transform="translate(-0.28 -0.29)"
                                                  style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.8500000238418579;isolation:isolate"/>
                                            <path id="Path_19-2" data-name="Path 19-2" d="M255.44,98.47,250,99.8l30.14,48.37V137.68Z"
                                                  transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                            <path id="Path_20-2" data-name="Path 20-2" d="M264,96.41l-5.45,1.33,21.65,36.09V123.34Z"
                                                  transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g id="Group_8" data-name="Group 8">
                            <path id="Path_21" data-name="Path 21"
                                  d="M287,179.16c-.45,0-.8-.52-.8-1.14V167a1.68,1.68,0,0,1,1-1.63h.2a1,1,0,0,1,.8,1.17v11a1.66,1.66,0,0,1-1,1.62H287Z"
                                  transform="translate(-0.28 -0.29)" style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                            <path id="Path_22" data-name="Path 22"
                                  d="M291.26,169.87l-3.51.85a.37.37,0,0,1-.21,0c-.45,0-.8-.53-.79-1.14h0a1.68,1.68,0,0,1,1-1.63l3.51-.85a.38.38,0,0,1,.21,0c.45,0,.81.54.8,1.16h0A1.67,1.67,0,0,1,291.26,169.87Z"
                                  transform="translate(-0.28 -0.29)" style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                        </g>
                        <rect id="Rectangle_7-3" data-name="Rectangle 7-3" x="156.17" y="87.83" width="303.9" height="193.64"
                              style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                        <g id="French_doors" data-name="French doors">
                            <g id="Group_55" data-name="Group 55">
                                <path id="Path_170" data-name="Path 170" d="M160,90.92V281.71h74.47V90.92ZM226.42,274.8H168V97.88h58.43Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#5b5b5b"/>
                                <rect id="Rectangle_112" data-name="Rectangle 112" x="159.67" y="90.63" width="74.49" height="190.85"
                                      style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                <rect id="Rectangle_113" data-name="Rectangle 113" x="167.71" y="97.59" width="58.42" height="176.92"
                                      style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.8500000238418579;isolation:isolate"/>
                                <path id="Path_171" data-name="Path 171" d="M173.2,140.71v9.09l34.14-47.14H201Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                <path id="Path_172" data-name="Path 172" d="M173.2,128.31v9.09l24.89-34.74h-6.5Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                            </g>
                            <g id="Group_55-2" data-name="Group 55-2">
                                <path id="Path_170-2" data-name="Path 170-2"
                                      d="M383.42,90.92V281.71h74.47V90.92ZM449.88,274.8H391.46V97.88h58.42Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#5b5b5b"/>
                                <rect id="Rectangle_112-2" data-name="Rectangle 112-2" x="383.14" y="90.63" width="74.49" height="190.85"
                                      style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                <rect id="Rectangle_113-2" data-name="Rectangle 113-2" x="391.18" y="97.59" width="58.42" height="176.92"
                                      style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.8500000238418579;isolation:isolate"/>
                                <path id="Path_171-2" data-name="Path 171-2" d="M396.67,140.71v9.09l34.13-47.14h-6.33Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                <path id="Path_172-2" data-name="Path 172-2" d="M396.67,128.31v9.09l24.88-34.74h-6.49Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                            </g>
                        </g>
                    </svg>
                </article>
            </div>
        </section>
        <nav class="container sm-fixed-bottom text-center">
            <section class="footer-left col-6 col-lg-3 float-left">
                <button @click="goBack" type="button" class="btn btn-back btn-lg w-100"><i class="fa fa-angle-left"></i> Go Back</button>
            </section>
            <section class="footer-right col-6 col-lg-3 float-right">
                <button @click="next" type="button" href="#" class="btn btn-next btn-lg w-100 btn-disable">Next Step <i
                    class="fa fa-angle-right"></i></button>
            </section>
        </nav>
    </main>
</template>
<script>
export default {
    created() {
        document.querySelectorAll('nav.stages ul li').forEach((el) => {
            if(el.classList.contains('three')) {
                el.classList.add('active');
            }
        });
    },
    methods: {
        next: function (e) {
            if (!e.target.classList.contains('btn-disable')) {
                document.querySelector('body').querySelector('#aluminium-external-french-3000less-step-4').click();

                document.querySelectorAll('nav.stages ul li').forEach((el) => {
                    if(el.classList.contains('three')) {
                        el.classList.remove('active');
                        el.classList.add('done');
                    }
                });
            }
        },
        goBack: function () {
            window.history.back();
        },
        saveData: function (e) {
            if (e.target.closest('article').classList.contains('option-handing-right')) {
                sessionStorage.ProdConfiguredHanding = 'Right';
            }

            if (e.target.closest('article').classList.contains('option-handing-left')) {
                sessionStorage.ProdConfiguredHanding = 'Left';
            }

            document.querySelectorAll(".option").forEach((el) => {
                el.classList.remove('chosen');
            });
            e.target.closest('article').classList.add('chosen');
            document.querySelector('body').querySelector('.btn-next').classList.remove('btn-disable');
        }
    }
}
</script>
