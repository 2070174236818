<template>
    <main id="direction-options" class="container option-wrapper">
        <section class="row">
            <h3 class="title col-12">Handling</h3>
            <p  class="description col-12">When viewed from the outside, do you want the handled lead door on the right or left?</p>
        </section>
        <section class="choices row">
            <div class="col-12 col-md-6 col-xl-6 mb-4">
                <article @click="saveData" class="option option-handling-right">
                    <i class="far fa-check-circle"></i>
                    <h4>Right</h4>
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 615.72 359.71">
                        <path id="Rectangle_8" data-name="Rectangle 8" d="M.28,299.13H616V355a5,5,0,0,1-5,5H5.28a5,5,0,0,1-5-5Z"
                              transform="translate(-0.28 -0.29)" style="fill:#d5db42"/>
                        <g id="Illustration">
                            <rect id="Rectangle_5" data-name="Rectangle 5" x="72.54" y="51.35" width="471.16" height="242.88"
                                  style="fill:#e8e8e8"/>
                            <rect id="Rectangle_6" data-name="Rectangle 6" x="72.54" y="56.54" width="471.16" height="11.25"
                                  style="fill:#d2d0d1"/>
                            <rect id="Rectangle_7" data-name="Rectangle 7" x="156.17" y="87.83" width="303.9" height="193.64"
                                  style="fill:#fff;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                            <path id="Path_2" data-name="Path 2"
                                  d="M81.5,8.54,62.38,52.78c-.72,1.92.52,10.54,2.35,10.54H552.07c1.83,0,3.07-8.62,2.35-10.54L535.3,8.54A2.57,2.57,0,0,0,533,6.78H83.84A2.57,2.57,0,0,0,81.5,8.54Z"
                                  transform="translate(-0.28 -0.29)" style="fill:#6a5647"/>
                            <path id="Path_3" data-name="Path 3"
                                  d="M81.5,2.05,62.38,52.79c-.72,1.91.52,4,2.35,4H552.07c1.83,0,3.07-2.13,2.35-4L535.3,2.05A2.57,2.57,0,0,0,533,.29H83.84A2.57,2.57,0,0,0,81.5,2.05Z"
                                  transform="translate(-0.28 -0.29)" style="fill:#947668"/>
                            <path id="Path_4" data-name="Path 4"
                                  d="M150.25,286.48a1.15,1.15,0,0,1-.38-1.58h0a3.86,3.86,0,0,1,3.3-1.9H463.42a4.1,4.1,0,0,1,3.49,2h0a1,1,0,0,1,.16.54,1.08,1.08,0,0,1-1,1.13H150.83A1.08,1.08,0,0,1,150.25,286.48Z"
                                  transform="translate(-0.28 -0.29)" style="fill:#464545"/>
                            <path id="Path_5" data-name="Path 5" d="M71.56,294.51H544.44l33.75,32.88H37.81Z"
                                  transform="translate(-0.28 -0.29)" style="fill:#bababa"/>
                            <rect id="Rectangle_9" data-name="Rectangle 9" x="37.53" y="327.1" width="540.39" height="7.28"
                                  style="fill:#757474"/>
                        </g>
                        <g id="French_doors" data-name="French doors">
                            <g id="Group_55" data-name="Group 55">
                                <path id="Path_170" data-name="Path 170" d="M159.42,90V280.8h74.47V90Zm66.47,183.88H167.46V97h58.43Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#5b5b5b"/>
                                <rect id="Rectangle_112" data-name="Rectangle 112" x="159.14" y="89.74" width="74.49" height="190.85"
                                      style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                <rect id="Rectangle_113" data-name="Rectangle 113" x="167.18" y="96.7" width="58.42" height="176.92"
                                      style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.8500000238418579;isolation:isolate"/>
                                <path id="Path_171" data-name="Path 171" d="M172.67,139.82v9.1l34.14-47.15h-6.33Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                <path id="Path_172" data-name="Path 172" d="M172.67,127.42v9.09l24.89-34.74h-6.49Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                            </g>
                            <g id="Group_55-2" data-name="Group 55-2">
                                <path id="Path_170-2" data-name="Path 170-2" d="M382.89,90V280.8h74.47V90Zm66.47,183.88H390.93V97h58.43Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#5b5b5b"/>
                                <rect id="Rectangle_112-2" data-name="Rectangle 112-2" x="382.61" y="89.74" width="74.49" height="190.85"
                                      style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                <rect id="Rectangle_113-2" data-name="Rectangle 113-2" x="390.65" y="96.7" width="58.42" height="176.92"
                                      style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.8500000238418579;isolation:isolate"/>
                                <path id="Path_171-2" data-name="Path 171-2" d="M396.14,139.82v9.1l34.14-47.15h-6.34Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                <path id="Path_172-2" data-name="Path 172-2" d="M396.14,127.42v9.09L421,101.77h-6.5Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                            </g>
                            <g id="Left_Open" data-name="Left Open">
                                <g id="Group_7" data-name="Group 7">
                                    <g id="Group_6" data-name="Group 6">
                                        <path id="Path_16" data-name="Path 16"
                                              d="M233.89,91V279.6a1.28,1.28,0,0,0,.8,1.32l53.59,15.75V105.39L235,89.68c-.53-.12-1,.36-1.12,1.09A1.77,1.77,0,0,0,233.89,91Zm48,196.51-41.72-12.88V98.52l41.72,12.9Z"
                                              transform="translate(-0.28 -0.29)" style="fill:#5b5b5b"/>
                                        <path id="Path_17" data-name="Path 17"
                                              d="M287.14,296.43l-52.45-15.51a1.3,1.3,0,0,1-.8-1.36V91h0c0-.74.44-1.34,1-1.34H235l52.45,15.51a1.28,1.28,0,0,1,.79,1.32V295.07c0,.74-.43,1.34-1,1.34Z"
                                              transform="translate(-0.28 -0.29)"
                                              style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                        <path id="Path_18" data-name="Path 18" d="M281.93,287.51l-41.72-12.89V98.52l41.72,12.9Z"
                                              transform="translate(-0.28 -0.29)"
                                              style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.8500000238418579;isolation:isolate"/>
                                        <path id="Path_19" data-name="Path 19" d="M243.58,151.46V162l30.15-48.73L268.28,112Z"
                                              transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                        <path id="Path_20" data-name="Path 20" d="M243.58,137v10.57l21.65-36.36-5.45-1.33Z"
                                              transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                    </g>
                                </g>
                            </g>
                            <g id="Left_Open-2" data-name="Left Open-2">
                                <g id="Group_7-2" data-name="Group 7-2">
                                    <g id="Group_6-2" data-name="Group 6-2">
                                        <path id="Path_16-2" data-name="Path 16-2"
                                              d="M381.84,91.51c-.1-.73-.6-1.21-1.12-1.09l-53.25,15.7V297.4l53.58-15.74a1.29,1.29,0,0,0,.8-1.32V91.74A1.77,1.77,0,0,0,381.84,91.51Zm-48,20.64,41.72-12.89v176.1l-41.72,12.89Z"
                                              transform="translate(-0.28 -0.29)" style="fill:#5b5b5b"/>
                                        <path id="Path_17-2" data-name="Path 17-2"
                                              d="M328.44,297.15c-.53,0-1-.61-1-1.34V107.25a1.28,1.28,0,0,1,.79-1.32l52.45-15.51h.17c.53,0,1,.6,1,1.34h0V280.3a1.3,1.3,0,0,1-.8,1.36L328.6,297.17Z"
                                              transform="translate(-0.28 -0.29)"
                                              style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                        <path id="Path_18-2" data-name="Path 18-2" d="M333.81,112.15l41.72-12.89V275.35l-41.72,12.9Z"
                                              transform="translate(-0.28 -0.29)"
                                              style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.8500000238418579;isolation:isolate"/>
                                        <path id="Path_19-2" data-name="Path 19-2" d="M347.46,112.7,342,114l30.14,48.73V152.19Z"
                                              transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                        <path id="Path_20-2" data-name="Path 20-2" d="M356,110.62,350.52,112l21.65,36.35v-10.6Z"
                                              transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                    </g>
                                </g>
                            </g>
                            <g id="Group_8" data-name="Group 8">
                                <path id="Path_21" data-name="Path 21"
                                      d="M330.56,204.23c-.45,0-.81-.53-.8-1.15V192a1.66,1.66,0,0,1,1-1.64h.2c.46,0,.81.55.8,1.18V202.6a1.7,1.7,0,0,1-1,1.64h0A.45.45,0,0,1,330.56,204.23Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                                <path id="Path_22" data-name="Path 22"
                                      d="M334.86,194.87l-3.51.86a.37.37,0,0,1-.21,0c-.45,0-.8-.53-.79-1.15h0a1.66,1.66,0,0,1,1-1.64l3.51-.86a.38.38,0,0,1,.21,0c.45,0,.81.54.79,1.17h0A1.66,1.66,0,0,1,334.86,194.87Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                            </g>
                        </g>
                    </svg>
                </article>
            </div>
            <div class="col-12 col-md-6 col-xl-6 mb-4">
                <article @click="saveData" class="option option-handling-left">
                    <i class="far fa-check-circle hidden"></i>
                    <h4>Left</h4>
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 615.72 359.71">
                        <path id="Rectangle_8" data-name="Rectangle 8" d="M.28,299.13H616V355a5,5,0,0,1-5,5H5.28a5,5,0,0,1-5-5Z"
                              transform="translate(-0.28 -0.29)" style="fill:#d5db42"/>
                        <g id="Illustration">
                            <rect id="Rectangle_5" data-name="Rectangle 5" x="72.54" y="51.35" width="471.16" height="242.88"
                                  style="fill:#e8e8e8"/>
                            <rect id="Rectangle_6" data-name="Rectangle 6" x="72.54" y="56.54" width="471.16" height="11.25"
                                  style="fill:#d2d0d1"/>
                            <rect id="Rectangle_7" data-name="Rectangle 7" x="156.17" y="87.83" width="303.9" height="193.64"
                                  style="fill:#fff;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                            <path id="Path_2" data-name="Path 2"
                                  d="M81.5,8.54,62.38,52.78c-.72,1.92.52,10.54,2.35,10.54H552.07c1.83,0,3.07-8.62,2.35-10.54L535.3,8.54A2.57,2.57,0,0,0,533,6.78H83.84A2.57,2.57,0,0,0,81.5,8.54Z"
                                  transform="translate(-0.28 -0.29)" style="fill:#6a5647"/>
                            <path id="Path_3" data-name="Path 3"
                                  d="M81.5,2.05,62.38,52.79c-.72,1.91.52,4,2.35,4H552.07c1.83,0,3.07-2.13,2.35-4L535.3,2.05A2.57,2.57,0,0,0,533,.29H83.84A2.57,2.57,0,0,0,81.5,2.05Z"
                                  transform="translate(-0.28 -0.29)" style="fill:#947668"/>
                            <path id="Path_4" data-name="Path 4"
                                  d="M150.25,286.48a1.15,1.15,0,0,1-.38-1.58h0a3.86,3.86,0,0,1,3.3-1.9H463.42a4.1,4.1,0,0,1,3.49,2h0a1,1,0,0,1,.16.54,1.08,1.08,0,0,1-1,1.13H150.83A1.08,1.08,0,0,1,150.25,286.48Z"
                                  transform="translate(-0.28 -0.29)" style="fill:#464545"/>
                            <path id="Path_5" data-name="Path 5" d="M71.56,294.51H544.44l33.75,32.88H37.81Z"
                                  transform="translate(-0.28 -0.29)" style="fill:#bababa"/>
                            <rect id="Rectangle_9" data-name="Rectangle 9" x="37.53" y="327.1" width="540.39" height="7.28"
                                  style="fill:#757474"/>
                        </g>
                        <g id="French_doors" data-name="French doors">
                            <g id="Group_55" data-name="Group 55">
                                <path id="Path_170" data-name="Path 170" d="M160,90.92V281.71h74.47V90.92ZM226.42,274.8H168V97.88h58.43Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#5b5b5b"/>
                                <rect id="Rectangle_112" data-name="Rectangle 112" x="159.67" y="90.63" width="74.49" height="190.85"
                                      style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                <rect id="Rectangle_113" data-name="Rectangle 113" x="167.71" y="97.59" width="58.42" height="176.92"
                                      style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.8500000238418579;isolation:isolate"/>
                                <path id="Path_171" data-name="Path 171" d="M173.2,140.71v9.09l34.14-47.14H201Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                <path id="Path_172" data-name="Path 172" d="M173.2,128.31v9.09l24.89-34.74h-6.5Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                            </g>
                            <g id="Group_55-2" data-name="Group 55-2">
                                <path id="Path_170-2" data-name="Path 170-2"
                                      d="M383.42,90.92V281.71h74.47V90.92ZM449.88,274.8H391.46V97.88h58.42Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#5b5b5b"/>
                                <rect id="Rectangle_112-2" data-name="Rectangle 112-2" x="383.14" y="90.63" width="74.49" height="190.85"
                                      style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                <rect id="Rectangle_113-2" data-name="Rectangle 113-2" x="391.18" y="97.59" width="58.42" height="176.92"
                                      style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.8500000238418579;isolation:isolate"/>
                                <path id="Path_171-2" data-name="Path 171-2" d="M396.67,140.71v9.09l34.13-47.14h-6.33Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                <path id="Path_172-2" data-name="Path 172-2" d="M396.67,128.31v9.09l24.88-34.74h-6.49Z"
                                      transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                            </g>
                            <g id="Left_Open" data-name="Left Open">
                                <g id="Group_7" data-name="Group 7">
                                    <g id="Group_6" data-name="Group 6">
                                        <path id="Path_16" data-name="Path 16"
                                              d="M233.39,92.25v188.6a1.28,1.28,0,0,0,.8,1.32l53.59,15.75V106.64L234.53,90.93c-.53-.12-1,.36-1.12,1.09C233.4,92.1,233.4,92.18,233.39,92.25Zm48,196.51-41.71-12.88V99.77l41.71,12.9Z"
                                              transform="translate(-0.28 -0.29)" style="fill:#5b5b5b"/>
                                        <path id="Path_17" data-name="Path 17"
                                              d="M286.64,297.68l-52.45-15.51a1.3,1.3,0,0,1-.8-1.36V92.27h0c0-.74.44-1.34,1-1.34h.17L287,106.44a1.28,1.28,0,0,1,.79,1.32V296.32c0,.74-.43,1.34-1,1.34Z"
                                              transform="translate(-0.28 -0.29)"
                                              style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                        <path id="Path_18" data-name="Path 18" d="M281.43,288.76l-41.71-12.89V99.77l41.71,12.9Z"
                                              transform="translate(-0.28 -0.29)"
                                              style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.8500000238418579;isolation:isolate"/>
                                        <path id="Path_19" data-name="Path 19" d="M243.09,152.71v10.57l30.14-48.73-5.45-1.34Z"
                                              transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                        <path id="Path_20" data-name="Path 20" d="M243.08,138.26v10.57l21.65-36.36-5.45-1.33Z"
                                              transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                    </g>
                                </g>
                                <g id="Group_8" data-name="Group 8">
                                    <path id="Path_21" data-name="Path 21"
                                          d="M284.06,205.49h0a1.7,1.7,0,0,1-1-1.64V192.74c0-.63.35-1.16.8-1.18h.2a1.69,1.69,0,0,1,1,1.64v11.13c0,.62-.35,1.14-.8,1.15A.45.45,0,0,1,284.06,205.49Z"
                                          transform="translate(-0.28 -0.29)" style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                                    <path id="Path_22" data-name="Path 22"
                                          d="M279,194.49h0c0-.63.34-1.15.79-1.17a.37.37,0,0,1,.21,0l3.51.86a1.66,1.66,0,0,1,1,1.64h0c0,.62-.34,1.14-.79,1.15a.38.38,0,0,1-.21,0l-3.51-.86A1.66,1.66,0,0,1,279,194.49Z"
                                          transform="translate(-0.28 -0.29)" style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                                </g>
                            </g>
                            <g id="Left_Open-2" data-name="Left Open-2">
                                <g id="Group_7-2" data-name="Group 7-2">
                                    <g id="Group_6-2" data-name="Group 6-2">
                                        <path id="Path_16-2" data-name="Path 16-2"
                                              d="M381.34,92.76c-.1-.73-.59-1.21-1.12-1.09L327,107.37V298.65l53.59-15.74a1.3,1.3,0,0,0,.8-1.32V93A.79.79,0,0,1,381.34,92.76Zm-48,20.64L375,100.51v176.1L333.32,289.5Z"
                                              transform="translate(-0.28 -0.29)" style="fill:#5b5b5b"/>
                                        <path id="Path_17-2" data-name="Path 17-2"
                                              d="M327.94,298.4c-.53,0-1-.61-1-1.34V108.5a1.28,1.28,0,0,1,.79-1.32l52.45-15.51h.17c.53,0,1,.6,1,1.34h0V281.55a1.32,1.32,0,0,1-.8,1.36L328.1,298.42Z"
                                              transform="translate(-0.28 -0.29)"
                                              style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                        <path id="Path_18-2" data-name="Path 18-2" d="M333.32,113.4,375,100.51V276.6l-41.71,12.9Z"
                                              transform="translate(-0.28 -0.29)"
                                              style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.8500000238418579;isolation:isolate"/>
                                        <path id="Path_19-2" data-name="Path 19-2" d="M347,114l-5.45,1.33L371.66,164V153.44Z"
                                              transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                        <path id="Path_20-2" data-name="Path 20-2" d="M355.47,111.87,350,113.21l21.65,36.35V139Z"
                                              transform="translate(-0.28 -0.29)" style="fill:#fff"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </article>
            </div>
        </section>
        <nav class="container sm-fixed-bottom text-center">
            <section class="footer-left col-6 col-lg-3 float-left">
                <button @click="goBack" type="button" class="btn btn-back btn-lg w-100"><i class="fa fa-angle-left"></i> Go Back</button>
            </section>
            <section class="footer-right col-6 col-lg-3 float-right">
                <button @click="next" type="button" href="#" class="btn btn-next btn-lg w-100 btn-disable">Next Step <i
                    class="fa fa-angle-right"></i></button>
            </section>
        </nav>
    </main>
</template>
<script>
export default {
    created() {
        document.querySelectorAll('nav.stages ul li').forEach((el) => {
            if(el.classList.contains('three')) {
                el.classList.add('active');
            }
        });
    },
    methods: {
        next: function (e) {
            if (!e.target.classList.contains('btn-disable')) {
                document.querySelector('body').querySelector('#dashboard').click();

                document.querySelectorAll('nav.stages ul li').forEach((el) => {
                    if(el.classList.contains('four')) {
                        el.classList.remove('active');
                        el.classList.add('done');
                    }
                });
            }
        },
        goBack: function () {
            window.history.back();
        },
        saveData: function (e) {
            if (e.target.closest('article').classList.contains('option-handling-right')) {
                sessionStorage.ProdConfiguredHandling = 'Right';
            }

            if (e.target.closest('article').classList.contains('option-handling-left')) {
                sessionStorage.ProdConfiguredHandling = 'Left';
            }

            document.querySelectorAll(".option").forEach((el) => {
                el.classList.remove('chosen');
            });
            e.target.closest('article').classList.add('chosen');
            document.querySelector('body').querySelector('.btn-next').classList.remove('btn-disable');
        }
    }
}
</script>
