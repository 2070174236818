<template>
    <main id="direction-options" class="container option-wrapper">
        <section class="row">
            <h3 class="title col-12">Did you want the set with or without the sill?</h3>
            <p class="description col-12">If opting for no sill, please ensure you have adequate drainage outside of the
                property.</p>
        </section>
        <section class="choices row">
            <div class="col-12 col-md-6 col-xl-6 mb-4">
                <article @click="saveData" class="option option-with-sill">
                    <i class="far fa-check-circle"></i>
                    <h4>With Sill</h4>
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         viewBox="0 0 616 360">
                        <defs>
                            <clipPath id="clip-path">
                                <circle cx="138.53" cy="266.71" r="70.06" style="fill:none"/>
                            </clipPath>
                            <clipPath id="clip-path-2">
                                <circle cx="138.24" cy="266.71" r="70.06" style="fill:none"/>
                            </clipPath>
                        </defs>
                        <path id="Rectangle_8" data-name="Rectangle 8" d="M.28,299.13H616V355a5,5,0,0,1-5,5H5.28a5,5,0,0,1-5-5V299.13Z"
                              style="fill:#d5db42"/>
                        <g id="Group_70" data-name="Group 70">
                            <rect id="Rectangle_117" data-name="Rectangle 117" x="72.46" y="49.93" width="471.66" height="236.19"
                                  style="fill:#e8e8e8"/>
                            <rect id="Rectangle_118" data-name="Rectangle 118" x="70.9" y="54.98" width="471.66" height="10.94"
                                  style="fill:#d2d0d1"/>
                            <rect id="Rectangle_119" data-name="Rectangle 119" x="152.23" y="85.41" width="309" height="188.31"
                                  style="fill:#fff;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                            <g id="Group_57" data-name="Group 57">
                                <path id="Path_178" data-name="Path 178" d="M307.51,87.93V271.2h153V87.93Zm144.6,176H315.92V95.23H452.11Z"
                                      style="fill:#5b5b5b"/>
                                <rect id="Rectangle_120" data-name="Rectangle 120" x="307.51" y="87.93" width="153.02" height="183.26"
                                      style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                <rect id="Rectangle_121" data-name="Rectangle 121" x="315.92" y="95.23" width="136.19" height="168.68"
                                      style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                <path id="Path_179" data-name="Path 179" d="M321.38,157.33V171l52.52-70.74h-9.77Z" style="fill:#fff"/>
                                <path id="Path_180" data-name="Path 180" d="M321.38,138.72v13.63l37.35-52.13H349Z" style="fill:#fff"/>
                            </g>
                            <g id="Group_58" data-name="Group 58">
                                <path id="Path_181" data-name="Path 181" d="M155.27,87.93V271.2h153V87.93Zm144.6,176H163.68V95.23H299.87Z"
                                      style="fill:#5b5b5b"/>
                                <rect id="Rectangle_122" data-name="Rectangle 122" x="155.27" y="87.93" width="153.02" height="183.26"
                                      style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                <rect id="Rectangle_123" data-name="Rectangle 123" x="163.68" y="95.23" width="136.19" height="168.68"
                                      style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                <path id="Path_182" data-name="Path 182" d="M169.14,157.33V171l52.52-70.74h-9.77Z" style="fill:#fff"/>
                                <path id="Path_183" data-name="Path 183" d="M169.14,138.72v13.63l37.35-52.13h-9.77Z" style="fill:#fff"/>
                            </g>
                            <rect id="Rectangle_124" data-name="Rectangle 124" x="158.72" y="158.67" width="2.66" height="41.79" rx="1.18"
                                  style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                            <path id="Path_184" data-name="Path 184"
                                  d="M531.25,6.31h-449A2.55,2.55,0,0,0,79.87,8l-19.11,43c-.72,1.86.51,10.24,2.34,10.24H550.36c1.83,0,3.07-8.38,2.35-10.24L533.59,8A2.55,2.55,0,0,0,531.25,6.31Z"
                                  style="fill:#6a5647"/>
                            <path id="Path_185" data-name="Path 185"
                                  d="M531.25,0h-449a2.55,2.55,0,0,0-2.34,1.71L60.76,51.05a2.8,2.8,0,0,0,1.43,3.7,2.65,2.65,0,0,0,.91.23H550.36a2.81,2.81,0,0,0,2.58-3,3.2,3.2,0,0,0-.23-.91L533.59,1.71A2.55,2.55,0,0,0,531.25,0Z"
                                  style="fill:#947668"/>
                            <path id="Path_186" data-name="Path 186"
                                  d="M466.65,279.46H146.75a1.06,1.06,0,0,1-1.06-1.06,1,1,0,0,1,.17-.56h0a4.17,4.17,0,0,1,3.53-2H464.26a4,4,0,0,1,3.35,1.85h0a1.13,1.13,0,0,1-.36,1.56A1.16,1.16,0,0,1,466.65,279.46Z"
                                  style="fill:#464545"/>
                            <path id="Path_187" data-name="Path 187" d="M577.78,318.1H38.8l33.66-32H544.12Z" style="fill:#bababa"/>
                            <rect id="Rectangle_126" data-name="Rectangle 126" x="38.8" y="318.1" width="538.98" height="7.08"
                                  style="fill:#757474"/>
                            <g id="Group_69" data-name="Group 69">
                                <g id="Ellipse_4" data-name="Ellipse 4">
                                    <circle cx="138.53" cy="266.71" r="70.06" style="fill:#fff"/>
                                    <path
                                        d="M138.53,339.75a73,73,0,1,1,73-73A73.13,73.13,0,0,1,138.53,339.75Zm0-140.12a67.08,67.08,0,1,0,67.07,67.08A67.15,67.15,0,0,0,138.53,199.63Z"
                                        style="fill:#f4990a"/>
                                </g>
                                <g id="Group_68" data-name="Group 68">
                                    <g style="clip-path:url(#clip-path)">
                                        <g id="Group_67" data-name="Group 67">
                                            <g id="Group_59" data-name="Group 59">
                                                <g id="Rectangle_127" data-name="Rectangle 127">
                                                    <rect x="134.09" y="-288.22" width="464.51" height="553.28" style="fill:#fff"/>
                                                </g>
                                                <g id="Path_188" data-name="Path 188">
                                                    <path d="M605.19,271.65H127.49V-294.82h477.7ZM140.68,258.46H592V-281.63H140.68Z"
                                                          style="fill:#4d4c4c"/>
                                                </g>
                                            </g>
                                            <g id="Group_65" data-name="Group 65">
                                                <g id="Group_60" data-name="Group 60">
                                                    <g id="Path_189" data-name="Path 189">
                                                        <path d="M143-280.81V257.65H592.61V-280.81Zm424.87,517H167.74v-495.6H567.89Z"
                                                              style="fill:#5b5b5b"/>
                                                    </g>
                                                </g>
                                                <g id="Group_61" data-name="Group 61">
                                                    <g id="Path_190" data-name="Path 190">
                                                        <path d="M599.21,264.24H136.42V-287.41H599.21Zm-449.6-13.19H586V-274.22H149.61Z"
                                                              style="fill:#4d4c4c"/>
                                                    </g>
                                                </g>
                                                <g id="Group_64" data-name="Group 64">
                                                    <g id="Group_62" data-name="Group 62" style="opacity:0.5">
                                                        <g id="Rectangle_128" data-name="Rectangle 128">
                                                            <rect x="167.74" y="-259.38" width="400.15" height="495.6"
                                                                  style="fill:#cee4f7"/>
                                                        </g>
                                                    </g>
                                                    <g id="Group_63" data-name="Group 63">
                                                        <g id="Path_191" data-name="Path 191">
                                                            <path d="M571.18,239.51H164.44V-262.68H571.18ZM171,232.92H564.59v-489H171Z"
                                                                  style="fill:#2e2e2d"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="Group_66" data-name="Group 66">
                                                <g id="Path_192" data-name="Path 192">
                                                    <path
                                                        d="M1057.91,281.93H118a3.1,3.1,0,0,1-2.63-4.76h0a12.24,12.24,0,0,1,10.38-5.75h925.15a11.62,11.62,0,0,1,9.83,5.44h0a3.32,3.32,0,0,1-2.81,5.07Z"
                                                        style="fill:#464545"/>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="Ellipse_6" data-name="Ellipse 6">
                                    <path id="arrow"
                                          d="M138.53,339.75a73,73,0,1,1,73-73A73.13,73.13,0,0,1,138.53,339.75Zm0-140.12a67.08,67.08,0,1,0,67.07,67.08A67.15,67.15,0,0,0,138.53,199.63Z"
                                          style="fill:#f4990a"/>
                                </g>
                            </g>
                        </g>
                        <g id="Group_70-2" data-name="Group 70">
                            <rect id="Rectangle_117-2" data-name="Rectangle 117" x="72.17" y="49.93" width="471.66" height="236.19"
                                  style="fill:#e8e8e8"/>
                            <rect id="Rectangle_118-2" data-name="Rectangle 118" x="70.61" y="54.98" width="471.66" height="10.94"
                                  style="fill:#d2d0d1"/>
                            <rect id="Rectangle_119-2" data-name="Rectangle 119" x="151.94" y="85.41" width="309" height="188.31"
                                  style="fill:#fff;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                            <g id="Group_57-2" data-name="Group 57">
                                <path id="Path_178-2" data-name="Path 178"
                                      d="M307.22,87.93V271.2h153V87.93Zm144.61,176H315.64V95.23H451.83Z" style="fill:#5b5b5b"/>
                                <rect id="Rectangle_120-2" data-name="Rectangle 120" x="307.22" y="87.93" width="153.02" height="183.26"
                                      style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                <rect id="Rectangle_121-2" data-name="Rectangle 121" x="315.64" y="95.23" width="136.19" height="168.68"
                                      style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                <path id="Path_179-2" data-name="Path 179" d="M321.09,157.33V171l52.52-70.74h-9.76Z" style="fill:#fff"/>
                                <path id="Path_180-2" data-name="Path 180" d="M321.09,138.72v13.63l37.35-52.13h-9.77Z" style="fill:#fff"/>
                            </g>
                            <g id="Group_58-2" data-name="Group 58">
                                <path id="Path_181-2" data-name="Path 181" d="M155,87.93V271.2H308V87.93Zm144.6,176H163.4V95.23H299.58Z"
                                      style="fill:#5b5b5b"/>
                                <rect id="Rectangle_122-2" data-name="Rectangle 122" x="154.98" y="87.93" width="153.02" height="183.26"
                                      style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                <rect id="Rectangle_123-2" data-name="Rectangle 123" x="163.4" y="95.23" width="136.19" height="168.68"
                                      style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                <path id="Path_182-2" data-name="Path 182" d="M168.85,157.33V171l52.52-70.74h-9.76Z" style="fill:#fff"/>
                                <path id="Path_183-2" data-name="Path 183" d="M168.85,138.72v13.63l37.35-52.13h-9.77Z" style="fill:#fff"/>
                            </g>
                            <rect id="Rectangle_124-2" data-name="Rectangle 124" x="158.43" y="158.67" width="2.66" height="41.79" rx="1.18"
                                  style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                            <path id="Path_184-2" data-name="Path 184"
                                  d="M531,6.31h-449A2.57,2.57,0,0,0,79.58,8l-19.11,43c-.72,1.86.51,10.24,2.34,10.24H550.07c1.83,0,3.07-8.38,2.35-10.24L533.3,8A2.55,2.55,0,0,0,531,6.31Z"
                                  style="fill:#6a5647"/>
                            <path id="Path_185-2" data-name="Path 185"
                                  d="M531,0h-449a2.57,2.57,0,0,0-2.35,1.71L60.47,51.05a2.8,2.8,0,0,0,1.43,3.7,2.65,2.65,0,0,0,.91.23H550.07a2.81,2.81,0,0,0,2.58-3,3.2,3.2,0,0,0-.23-.91L533.3,1.71A2.55,2.55,0,0,0,531,0Z"
                                  style="fill:#947668"/>
                            <path id="Path_186-2" data-name="Path 186"
                                  d="M466.36,279.46H146.46a1.05,1.05,0,0,1-1.05-1.06,1,1,0,0,1,.16-.56h0a4.17,4.17,0,0,1,3.53-2H464a4,4,0,0,1,3.35,1.85h0a1.13,1.13,0,0,1-1,1.73Z"
                                  style="fill:#464545"/>
                            <path id="Path_187-2" data-name="Path 187" d="M577.49,318.1h-539l33.66-32H543.83Z" style="fill:#bababa"/>
                            <rect id="Rectangle_126-2" data-name="Rectangle 126" x="38.51" y="318.1" width="538.98" height="7.08"
                                  style="fill:#757474"/>
                            <g id="Group_69-2" data-name="Group 69">
                                <g id="Ellipse_4-2" data-name="Ellipse 4">
                                    <circle cx="138.24" cy="266.71" r="70.06" style="fill:#fff"/>
                                    <path
                                        d="M138.24,339.75a73,73,0,1,1,73-73A73.12,73.12,0,0,1,138.24,339.75Zm0-140.12a67.08,67.08,0,1,0,67.07,67.08A67.14,67.14,0,0,0,138.24,199.63Z"
                                        style="fill:#f4990a"/>
                                </g>
                                <g id="Group_68-2" data-name="Group 68">
                                    <g style="clip-path:url(#clip-path-2)">
                                        <g id="Group_67-2" data-name="Group 67">
                                            <g id="Group_59-2" data-name="Group 59">
                                                <g id="Rectangle_127-2" data-name="Rectangle 127">
                                                    <rect x="133.8" y="-288.22" width="464.51" height="553.28" style="fill:#fff"/>
                                                </g>
                                                <g id="Path_188-2" data-name="Path 188">
                                                    <path d="M604.91,271.65H127.21V-294.82h477.7ZM140.39,258.46H591.72V-281.63H140.39Z"
                                                          style="fill:#4d4c4c"/>
                                                </g>
                                            </g>
                                            <g id="Group_65-2" data-name="Group 65">
                                                <g id="Group_60-2" data-name="Group 60">
                                                    <g id="Path_189-2" data-name="Path 189">
                                                        <path d="M142.73-280.81V257.65H592.32V-280.81Zm424.87,517H167.45v-495.6H567.6Z"
                                                              style="fill:#5b5b5b"/>
                                                    </g>
                                                </g>
                                                <g id="Group_61-2" data-name="Group 61">
                                                    <g id="Path_190-2" data-name="Path 190">
                                                        <path d="M598.92,264.24H136.13V-287.41H598.92Zm-449.6-13.19H585.73V-274.22H149.32Z"
                                                              style="fill:#4d4c4c"/>
                                                    </g>
                                                </g>
                                                <g id="Group_64-2" data-name="Group 64">
                                                    <g id="Group_62-2" data-name="Group 62" style="opacity:0.5">
                                                        <g id="Rectangle_128-2" data-name="Rectangle 128">
                                                            <rect x="167.45" y="-259.38" width="400.15" height="495.6"
                                                                  style="fill:#cee4f7"/>
                                                        </g>
                                                    </g>
                                                    <g id="Group_63-2" data-name="Group 63">
                                                        <g id="Path_191-2" data-name="Path 191">
                                                            <path d="M570.89,239.51H164.16V-262.68H570.89Zm-400.14-6.59H564.3v-489H170.75Z"
                                                                  style="fill:#2e2e2d"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="Group_66-2" data-name="Group 66">
                                                <g id="Path_192-2" data-name="Path 192">
                                                    <path
                                                        d="M1057.63,281.93H117.7a3.1,3.1,0,0,1-2.63-4.76h0a12.26,12.26,0,0,1,10.39-5.75H1050.6a11.62,11.62,0,0,1,9.83,5.44h0a3.31,3.31,0,0,1-2.8,5.07Z"
                                                        style="fill:#464545"/>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="Ellipse_6-2" data-name="Ellipse 6">
                                    <path id="arrow-2" data-name="arrow"
                                          d="M138.24,339.75a73,73,0,1,1,73-73A73.12,73.12,0,0,1,138.24,339.75Zm0-140.12a67.08,67.08,0,1,0,67.07,67.08A67.14,67.14,0,0,0,138.24,199.63Z"
                                          style="fill:#f4990a"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </article>
            </div>
            <div class="col-12 col-md-6 col-xl-6 mb-4">
                <article @click="saveData" class="option option-without-sill">
                    <i class="far fa-check-circle hidden"></i>
                    <h4>Without Sill</h4>
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         viewBox="0 0 616 360">
                        <defs>
                            <clipPath id="clip-path">
                                <circle cx="-497.47" cy="266.71" r="70.06" style="fill:none"/>
                            </clipPath>
                            <clipPath id="clip-path-2">
                                <circle cx="-497.76" cy="266.71" r="70.06" style="fill:none"/>
                            </clipPath>
                            <clipPath id="clip-path-3">
                                <circle cx="138.24" cy="266.71" r="70.06" style="fill:none"/>
                            </clipPath>
                        </defs>
                        <path id="Rectangle_8" data-name="Rectangle 8" d="M.28,299.13H616V355a5,5,0,0,1-5,5H5.28a5,5,0,0,1-5-5V299.13Z"
                              style="fill:#d5db42"/>
                        <g id="Group_70" data-name="Group 70">
                            <g id="Group_69" data-name="Group 69">
                                <g id="Group_68" data-name="Group 68">
                                    <g style="clip-path:url(#clip-path)">
                                        <g id="Group_67" data-name="Group 67">
                                            <g id="Group_66" data-name="Group 66">
                                                <g id="Path_192" data-name="Path 192">
                                                    <path
                                                        d="M421.91,281.93H-518a3.11,3.11,0,0,1-3.11-3.11,3.11,3.11,0,0,1,.48-1.65h0a12.24,12.24,0,0,1,10.38-5.75H414.89a11.62,11.62,0,0,1,9.83,5.44h0a3.32,3.32,0,0,1-2.81,5.07Z"
                                                        style="fill:#464545"/>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g id="Group_70-2" data-name="Group 70">
                            <g id="Group_69-2" data-name="Group 69">
                                <g id="Group_68-2" data-name="Group 68">
                                    <g style="clip-path:url(#clip-path-2)">
                                        <g id="Group_67-2" data-name="Group 67">
                                            <g id="Group_66-2" data-name="Group 66">
                                                <g id="Path_192-2" data-name="Path 192">
                                                    <path
                                                        d="M421.63,281.93H-518.3a3.11,3.11,0,0,1-3.11-3.11,3.11,3.11,0,0,1,.48-1.65h0a12.26,12.26,0,0,1,10.39-5.75H414.6a11.62,11.62,0,0,1,9.83,5.44h0a3.31,3.31,0,0,1-2.8,5.07Z"
                                                        style="fill:#464545"/>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g id="Group_70-3" data-name="Group 70">
                            <rect id="Rectangle_117" data-name="Rectangle 117" x="72.17" y="49.93" width="471.66" height="236.19"
                                  style="fill:#e8e8e8"/>
                            <rect id="Rectangle_118" data-name="Rectangle 118" x="70.61" y="54.98" width="471.66" height="10.94"
                                  style="fill:#d2d0d1"/>
                            <rect id="Rectangle_119" data-name="Rectangle 119" x="151.94" y="85.41" width="309" height="188.31"
                                  style="fill:#fff;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                            <g id="Group_57" data-name="Group 57">
                                <path id="Path_178" data-name="Path 178" d="M307.22,87.93V271.2h153V87.93Zm144.61,176H315.64V95.23H451.83Z"
                                      style="fill:#5b5b5b"/>
                                <rect id="Rectangle_120" data-name="Rectangle 120" x="307.22" y="87.93" width="153.02" height="183.26"
                                      style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                <rect id="Rectangle_121" data-name="Rectangle 121" x="315.64" y="95.23" width="136.19" height="168.68"
                                      style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                <path id="Path_179" data-name="Path 179" d="M321.09,157.33V171l52.52-70.74h-9.76Z" style="fill:#fff"/>
                                <path id="Path_180" data-name="Path 180" d="M321.09,138.72v13.63l37.35-52.13h-9.77Z" style="fill:#fff"/>
                            </g>
                            <g id="Group_58" data-name="Group 58">
                                <path id="Path_181" data-name="Path 181" d="M155,87.93V271.2H308V87.93Zm144.6,176H163.4V95.23H299.58Z"
                                      style="fill:#5b5b5b"/>
                                <rect id="Rectangle_122" data-name="Rectangle 122" x="154.98" y="87.93" width="153.02" height="183.26"
                                      style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                <rect id="Rectangle_123" data-name="Rectangle 123" x="163.4" y="95.23" width="136.19" height="168.68"
                                      style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                <path id="Path_182" data-name="Path 182" d="M168.85,157.33V171l52.52-70.74h-9.76Z" style="fill:#fff"/>
                                <path id="Path_183" data-name="Path 183" d="M168.85,138.72v13.63l37.35-52.13h-9.77Z" style="fill:#fff"/>
                            </g>
                            <rect id="Rectangle_124" data-name="Rectangle 124" x="158.43" y="158.67" width="2.66" height="41.79" rx="1.18"
                                  style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                            <path id="Path_184" data-name="Path 184"
                                  d="M531,6.31h-449A2.57,2.57,0,0,0,79.58,8l-19.11,43c-.72,1.86.51,10.24,2.34,10.24H550.07c1.83,0,3.07-8.38,2.35-10.24L533.3,8A2.55,2.55,0,0,0,531,6.31Z"
                                  style="fill:#6a5647"/>
                            <path id="Path_185" data-name="Path 185"
                                  d="M531,0h-449a2.57,2.57,0,0,0-2.35,1.71L60.47,51.05a2.8,2.8,0,0,0,1.43,3.7,2.65,2.65,0,0,0,.91.23H550.07a2.81,2.81,0,0,0,2.58-3,3.2,3.2,0,0,0-.23-.91L533.3,1.71A2.55,2.55,0,0,0,531,0Z"
                                  style="fill:#947668"/>
                            <path id="Path_187" data-name="Path 187" d="M577.49,318.1h-539l33.66-32H543.83Z" style="fill:#bababa"/>
                            <rect id="Rectangle_126" data-name="Rectangle 126" x="38.51" y="318.1" width="538.98" height="7.08"
                                  style="fill:#757474"/>
                            <g id="Group_69-3" data-name="Group 69">
                                <g id="Ellipse_4" data-name="Ellipse 4">
                                    <circle cx="138.24" cy="266.71" r="70.06" style="fill:#fff"/>
                                    <path
                                        d="M138.24,339.75a73,73,0,1,1,73-73A73.12,73.12,0,0,1,138.24,339.75Zm0-140.12a67.08,67.08,0,1,0,67.07,67.08A67.14,67.14,0,0,0,138.24,199.63Z"
                                        style="fill:#f4990a"/>
                                </g>
                                <g id="Group_68-3" data-name="Group 68">
                                    <g style="clip-path:url(#clip-path-3)">
                                        <g id="Group_67-3" data-name="Group 67">
                                            <g id="Group_59" data-name="Group 59">
                                                <g id="Rectangle_127" data-name="Rectangle 127">
                                                    <rect x="133.8" y="-288.22" width="464.51" height="553.28" style="fill:#fff"/>
                                                </g>
                                                <g id="Path_188" data-name="Path 188">
                                                    <path d="M604.91,271.65H127.21V-294.82h477.7ZM140.39,258.46H591.72V-281.63H140.39Z"
                                                          style="fill:#4d4c4c"/>
                                                </g>
                                            </g>
                                            <g id="Group_65" data-name="Group 65">
                                                <g id="Group_60" data-name="Group 60">
                                                    <g id="Path_189" data-name="Path 189">
                                                        <path d="M142.73-280.81V257.65H592.32V-280.81Zm424.87,517H167.45v-495.6H567.6Z"
                                                              style="fill:#5b5b5b"/>
                                                    </g>
                                                </g>
                                                <g id="Group_61" data-name="Group 61">
                                                    <g id="Path_190" data-name="Path 190">
                                                        <path d="M598.92,264.24H136.13V-287.41H598.92Zm-449.6-13.19H585.73V-274.22H149.32Z"
                                                              style="fill:#4d4c4c"/>
                                                    </g>
                                                </g>
                                                <g id="Group_64" data-name="Group 64">
                                                    <g id="Group_62" data-name="Group 62" style="opacity:0.5">
                                                        <g id="Rectangle_128" data-name="Rectangle 128">
                                                            <rect x="167.45" y="-259.38" width="400.15" height="495.6"
                                                                  style="fill:#cee4f7"/>
                                                        </g>
                                                    </g>
                                                    <g id="Group_63" data-name="Group 63">
                                                        <g id="Path_191" data-name="Path 191">
                                                            <path d="M570.89,239.51H164.16V-262.68H570.89Zm-400.14-6.59H564.3v-489H170.75Z"
                                                                  style="fill:#2e2e2d"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="Ellipse_6" data-name="Ellipse 6">
                                    <path id="arrow"
                                          d="M138.24,339.75a73,73,0,1,1,73-73A73.12,73.12,0,0,1,138.24,339.75Zm0-140.12a67.08,67.08,0,1,0,67.07,67.08A67.14,67.14,0,0,0,138.24,199.63Z"
                                          style="fill:#f4990a"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </article>
            </div>
        </section>
        <nav class="container sm-fixed-bottom text-center">
            <section class="footer-left col-6 col-lg-3 float-left">
                <button @click="goBack" type="button" href="#" class="btn btn-back btn-lg w-100"><i
                    class="fa fa-angle-left"></i> Go Back
                </button>
            </section>
            <section class="footer-right col-6 col-lg-3 float-right">
                <button @click="next" type="button" href="#" class="btn btn-confirm btn-lg w-100 btn-disable">Confirm
                    Choices <i class="far fa-check-circle"></i></button>
            </section>
        </nav>
    </main>
</template>
<script>

export default {
    created() {
        document.querySelectorAll('nav.stages ul li').forEach((el) => {
            if(el.classList.contains('two')) {
                el.classList.add('active');
            }
        });
    },
    methods: {
        next: function (e) {
            if (!e.target.classList.contains('btn-disable')) {
                document.querySelector('body').querySelector('#dashboard').click();
            }
        },
        goBack: function () {
            window.history.back();
        },
        saveData: function (e) {
            if (e.target.closest('article').classList.contains('option-with-sill')) {
                sessionStorage.ProdConfiguredWithOrWithoutSill = 'With Sill';
            }

            if (e.target.closest('article').classList.contains('option-without-sill')) {
                sessionStorage.ProdConfiguredWithOrWithoutSill = 'Without Sill';
            }

            document.querySelectorAll(".option").forEach((el) => {
                el.classList.remove('chosen');
            });
            e.target.closest('article').classList.add('chosen');
            document.querySelector('body').querySelector('.btn-confirm').classList.remove('btn-disable');
        },
    }
}
</script>
