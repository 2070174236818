<template>
    <main id="final-screen" class="container option-wrapper">
        <div class="col-12">
            <section class="row d-flex justify-content-center text-center">
                <i class="far fa-check-circle col-12"></i>
                <h4 class="title col-11">Thank you for submitting your Door configuration options.</h4>
                <p class="col-11">Work on your doors will begin shortly. If you have any further questions about delivery or timeframes - please contact our team, on:</p>
                <h5 class="col-11"><a href="tel:020 3744 0704">020 3744 0704</a></h5>
            </section>
        </div>
    </main>

</template>
<script>
    sessionStorage.clear();
    export default {

    }
</script>
