<template>
    <main id="direction-options" class="container option-wrapper">
        <section class="row">
            <h3 class="title col-12">Opening Direction</h3>
            <p  class="description col-12">Do you want the doors to open outwards into the garden or inwards into the house?</p>
        </section>
        <section class="choices row">
            <div class="col-12 col-md-6 col-xl-6 mb-4">
                <article @click="saveData" class="option option-in">
                    <i class="far fa-check-circle"></i>
                    <h4>Inwards</h4>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         viewBox="0 0 616 360">
                        <defs>
                            <clipPath id="clip-path">
                                <rect x="160.13" y="88.19" width="296.62" height="195.43" style="fill:none"/>
                            </clipPath>
                            <clipPath id="clip-path-2">
                                <circle cx="-497.47" cy="646.71" r="70.06" style="fill:none"/>
                            </clipPath>
                            <clipPath id="clip-path-3">
                                <circle cx="-497.76" cy="646.71" r="70.06" style="fill:none"/>
                            </clipPath>
                            <clipPath id="clip-path-4">
                                <circle cx="138.24" cy="646.71" r="70.06" style="fill:none"/>
                            </clipPath>
                        </defs>
                        <path id="Rectangle_8" data-name="Rectangle 8" d="M.28,299.13H616V355a5,5,0,0,1-5,5H5.28a5,5,0,0,1-5-5V299.13Z"
                              style="fill:#d5db42"/>
                        <g id="Illustration">
                            <rect id="Rectangle_17" data-name="Rectangle 17" x="75.73" y="51.78" width="465.35" height="245.13"
                                  style="fill:#e8e8e8"/>
                            <rect id="Rectangle_18" data-name="Rectangle 18" x="75.73" y="57.02" width="465.35" height="11.35"
                                  style="fill:#d2d0d1"/>
                            <path id="Path_45" data-name="Path 45"
                                  d="M84.08,8.28,65.18,52.94c-.71,1.93.51,10.63,2.32,10.63H549.3c1.81,0,3-8.7,2.32-10.63L532.72,8.28a2.53,2.53,0,0,0-2.32-1.77H86.4A2.53,2.53,0,0,0,84.08,8.28Z"
                                  style="fill:#6a5647"/>
                            <path id="Path_46" data-name="Path 46"
                                  d="M84.09,1.73,65.18,52.94c-.71,1.93.51,4.08,2.32,4.08H549.3c1.81,0,3-2.15,2.32-4.08L532.72,1.73A2.53,2.53,0,0,0,530.4,0H86.4A2.52,2.52,0,0,0,84.09,1.73Z"
                                  style="fill:#947668"/>
                            <path id="Path_47" data-name="Path 47"
                                  d="M153.88,287.69a1.18,1.18,0,0,1-.38-1.62h0a3.74,3.74,0,0,1,3.22-1.92H459.9a4,4,0,0,1,3.4,2h0a1.1,1.1,0,0,1-.86,1.69h-308A1.28,1.28,0,0,1,153.88,287.69Z"
                                  style="fill:#464545"/>
                            <g id="Group_24" data-name="Group 24">
                                <g style="clip-path:url(#clip-path)">
                                    <g id="Group_23" data-name="Group 23">
                                        <rect id="Rectangle_19" data-name="Rectangle 19" x="160.13" y="88.19" width="296.62" height="195.43"
                                              style="fill:#fff;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                        <g id="Group_22" data-name="Group 22">
                                            <g id="Group_20" data-name="Group 20">
                                                <g id="Group_17" data-name="Group 17">
                                                    <path id="Path_48" data-name="Path 48"
                                                          d="M373,75.21a.8.8,0,0,0-.31,0,.83.83,0,0,0-.71.93V263.61a2.07,2.07,0,0,0,1.45,1.88l81.9,16.62V91.91Zm7.06,9,67.19,13.63V272.9l-67.19-13.64Z"
                                                          style="fill:#5b5b5b"/>
                                                    <path id="Path_49" data-name="Path 49"
                                                          d="M372,263.61V76.12a.8.8,0,0,1,0-.31.82.82,0,0,1,1-.6l80.88,16.41a2,2,0,0,1,1.45,1.88V281a.8.8,0,0,1,0,.31.83.83,0,0,1-1,.61l-80.89-16.42A2,2,0,0,1,372,263.61Z"
                                                          style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                                    <path id="Path_50" data-name="Path 50" d="M380.06,84.21l67.19,13.63V272.9l-67.19-13.63Z"
                                                          style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                                    <path id="Path_51" data-name="Path 51" d="M401,93.63l-9.37-1.9L442,175.38V161.23Z"
                                                          style="fill:#fff"/>
                                                    <path id="Path_52" data-name="Path 52" d="M415.54,96.59l-9.38-1.9L442,156.06V141.91Z"
                                                          style="fill:#fff"/>
                                                </g>
                                                <g id="Group_18" data-name="Group 18">
                                                    <path id="Path_53" data-name="Path 53"
                                                          d="M370,75.81a.82.82,0,0,0-1-.6L288.08,91.62a2,2,0,0,0-1.44,1.88V281a.65.65,0,0,0,0,.31.82.82,0,0,0,1,.6l80.88-16.41a2,2,0,0,0,1.45-1.88V76.12A.8.8,0,0,0,370,75.81Zm-75.25,22,67.19-13.63V259.27L294.72,272.9Z"
                                                          style="fill:#5b5b5b"/>
                                                    <path id="Path_54" data-name="Path 54"
                                                          d="M287.34,281.92a.82.82,0,0,1-.7-.93V93.5a2,2,0,0,1,1.44-1.88L369,75.21a.8.8,0,0,1,.31,0,.83.83,0,0,1,.71.93V263.61a2.06,2.06,0,0,1-1.45,1.88l-80.89,16.42A.81.81,0,0,1,287.34,281.92Z"
                                                          style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                                    <path id="Path_55" data-name="Path 55"
                                                          d="M294.72,97.84l67.19-13.63V259.27L294.72,272.9Z"
                                                          style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                                    <path id="Path_56" data-name="Path 56" d="M315.63,98.78l-9.37,1.9,50.41,63.19V149.72Z"
                                                          style="fill:#fff"/>
                                                    <path id="Path_57" data-name="Path 57" d="M330.2,95.83l-9.38,1.9,35.85,46.83V130.41Z"
                                                          style="fill:#fff"/>
                                                </g>
                                                <g id="Group_19" data-name="Group 19">
                                                    <path id="Path_58" data-name="Path 58"
                                                          d="M283.2,91.62,201.3,75V265.2l82.33,16.7a.81.81,0,0,0,.31,0,.83.83,0,0,0,.71-.93V93.5A2.06,2.06,0,0,0,283.2,91.62Zm-73.82-7.41,67.19,13.63V272.9l-67.19-13.63Z"
                                                          style="fill:#5b5b5b"/>
                                                    <path id="Path_59" data-name="Path 59"
                                                          d="M201.3,263.61V76.12a.8.8,0,0,1,0-.31.82.82,0,0,1,1-.6l80.9,16.41a2,2,0,0,1,1.44,1.88V281a.8.8,0,0,1,0,.31.82.82,0,0,1-1,.61l-80.89-16.42A2,2,0,0,1,201.3,263.61Z"
                                                          style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                                    <path id="Path_60" data-name="Path 60" d="M209.38,84.21l67.19,13.63V272.9l-67.19-13.63Z"
                                                          style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                                    <path id="Path_61" data-name="Path 61" d="M230.29,93.63l-9.38-1.9,50.42,83.65V161.23Z"
                                                          style="fill:#fff"/>
                                                    <path id="Path_62" data-name="Path 62" d="M244.86,96.59l-9.38-1.9,35.85,61.37V141.91Z"
                                                          style="fill:#fff"/>
                                                </g>
                                            </g>
                                            <g id="Group_21" data-name="Group 21">
                                                <path id="Path_63" data-name="Path 63"
                                                      d="M204.75,187V175.94a.81.81,0,0,1,0-.31,1,1,0,0,1,1.25-.81h0a1.72,1.72,0,0,1,1.28,1.64v11a1.15,1.15,0,0,1,0,.32,1.06,1.06,0,0,1-1.26.81h0A1.71,1.71,0,0,1,204.75,187Z"
                                                      style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                                                <path id="Path_64" data-name="Path 64"
                                                      d="M205.51,178.54a1.06,1.06,0,0,1,1.26-.81l4.47.91a1.72,1.72,0,0,1,1.28,1.64h0a1.08,1.08,0,0,1,0,.31,1.05,1.05,0,0,1-1.26.81l-4.47-.9a1.72,1.72,0,0,1-1.28-1.64h0A1.15,1.15,0,0,1,205.51,178.54Z"
                                                      style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <rect id="Rectangle_21" data-name="Rectangle 21" x="160.13" y="87.19" width="296.54" height="195.43"
                                  style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                            <path id="arrow"
                                  d="M139.76,181.54l0,0a1.68,1.68,0,0,1-.06,2.37L137,186.7h0a1.68,1.68,0,0,1-2.4,0L121.33,173v35.12a1.69,1.69,0,0,1-1.69,1.69h-4a1.69,1.69,0,0,1-1.69-1.69V173l-13.23,13.7,0,0a1.67,1.67,0,0,1-2.37,0l-2.77-2.77a1.7,1.7,0,0,1,0-2.4l20.89-20.94h0a1.69,1.69,0,0,1,2.4,0l20.89,20.94Z"
                                  style="fill:#f4990a"/>
                            <path id="Path_66" data-name="Path 66" d="M73.81,294.24H542.19l33.42,33.19H40.39Z" style="fill:#bababa"/>
                            <rect id="Rectangle_23" data-name="Rectangle 23" x="40.39" y="327.43" width="535.22" height="7.35"
                                  style="fill:#757474"/>
                        </g>
                        <g id="Group_70-3" data-name="Group 70">
                            <g id="Group_69-3" data-name="Group 69">
                                <g id="Group_68-3" data-name="Group 68">
                                    <g style="clip-path:url(#clip-path-4)">
                                        <g id="Group_67-3" data-name="Group 67">
                                            <g id="Group_59" data-name="Group 59">
                                                <g id="Rectangle_127" data-name="Rectangle 127">
                                                    <rect x="133.8" y="91.78" width="464.51" height="553.28" style="fill:#fff"/>
                                                </g>
                                                <g id="Path_188" data-name="Path 188">
                                                    <path d="M604.91,651.65H127.21V85.18h477.7ZM140.39,638.46H591.72V98.37H140.39Z"
                                                          style="fill:#4d4c4c"/>
                                                </g>
                                            </g>
                                            <g id="Group_65" data-name="Group 65">
                                                <g id="Group_60" data-name="Group 60">
                                                    <g id="Path_189" data-name="Path 189">
                                                        <path d="M142.73,99.19V637.65H592.32V99.19Zm424.87,517H167.45V120.62H567.6Z"
                                                              style="fill:#5b5b5b"/>
                                                    </g>
                                                </g>
                                                <g id="Group_61" data-name="Group 61">
                                                    <g id="Path_190" data-name="Path 190">
                                                        <path d="M598.92,644.24H136.13V92.59H598.92Zm-449.6-13.19H585.73V105.78H149.32Z"
                                                              style="fill:#4d4c4c"/>
                                                    </g>
                                                </g>
                                                <g id="Group_64" data-name="Group 64">
                                                    <g id="Group_62" data-name="Group 62" style="opacity:0.5">
                                                        <g id="Rectangle_128" data-name="Rectangle 128">
                                                            <rect x="167.45" y="120.62" width="400.15" height="495.6" style="fill:#cee4f7"/>
                                                        </g>
                                                    </g>
                                                    <g id="Group_63" data-name="Group 63">
                                                        <g id="Path_191" data-name="Path 191">
                                                            <path d="M570.89,619.51H164.16V117.32H570.89Zm-400.14-6.59H564.3v-489H170.75Z"
                                                                  style="fill:#2e2e2d"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </article>
            </div>
            <div class="col-12 col-md-6 col-xl-6 mb-4">
                <article @click="saveData" class="option option-out">
                    <i class="far fa-check-circle hidden"></i>
                    <h4>Outwards</h4>
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         viewBox="0 0 616 360">
                        <defs>
                            <clipPath id="clip-path">
                                <circle cx="138.53" cy="646.71" r="70.06" style="fill:none"/>
                            </clipPath>
                            <clipPath id="clip-path-2">
                                <circle cx="138.24" cy="646.71" r="70.06" style="fill:none"/>
                            </clipPath>
                        </defs>
                        <path id="Rectangle_8" data-name="Rectangle 8" d="M.28,299.13H616V355a5,5,0,0,1-5,5H5.28a5,5,0,0,1-5-5V299.13Z"
                              style="fill:#d5db42"/>
                        <g id="Group_70" data-name="Group 70">
                            <g id="Group_69" data-name="Group 69">
                                <g id="Group_68" data-name="Group 68">
                                    <g style="clip-path:url(#clip-path)">
                                        <g id="Group_67" data-name="Group 67">
                                            <g id="Group_59" data-name="Group 59">
                                                <g id="Rectangle_127" data-name="Rectangle 127">
                                                    <rect x="134.09" y="91.78" width="464.51" height="553.28" style="fill:#fff"/>
                                                </g>
                                                <g id="Path_188" data-name="Path 188">
                                                    <path d="M605.19,651.65H127.49V85.18h477.7ZM140.68,638.46H592V98.37H140.68Z"
                                                          style="fill:#4d4c4c"/>
                                                </g>
                                            </g>
                                            <g id="Group_65" data-name="Group 65">
                                                <g id="Group_60" data-name="Group 60">
                                                    <g id="Path_189" data-name="Path 189">
                                                        <path d="M143,99.19V637.65H592.61V99.19Zm424.87,517H167.74V120.62H567.89Z"
                                                              style="fill:#5b5b5b"/>
                                                    </g>
                                                </g>
                                                <g id="Group_61" data-name="Group 61">
                                                    <g id="Path_190" data-name="Path 190">
                                                        <path d="M599.21,644.24H136.42V92.59H599.21Zm-449.6-13.19H586V105.78H149.61Z"
                                                              style="fill:#4d4c4c"/>
                                                    </g>
                                                </g>
                                                <g id="Group_64" data-name="Group 64">
                                                    <g id="Group_62" data-name="Group 62" style="opacity:0.5">
                                                        <g id="Rectangle_128" data-name="Rectangle 128">
                                                            <rect x="167.74" y="120.62" width="400.15" height="495.6" style="fill:#cee4f7"/>
                                                        </g>
                                                    </g>
                                                    <g id="Group_63" data-name="Group 63">
                                                        <g id="Path_191" data-name="Path 191">
                                                            <path d="M571.18,619.51H164.44V117.32H571.18ZM171,612.92H564.59v-489H171Z"
                                                                  style="fill:#2e2e2d"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g id="Group_70-2" data-name="Group 70">
                            <g id="Group_69-2" data-name="Group 69">
                                <g id="Group_68-2" data-name="Group 68">
                                    <g style="clip-path:url(#clip-path-2)">
                                        <g id="Group_67-2" data-name="Group 67">
                                            <g id="Group_59-2" data-name="Group 59">
                                                <g id="Rectangle_127-2" data-name="Rectangle 127">
                                                    <rect x="133.8" y="91.78" width="464.51" height="553.28" style="fill:#fff"/>
                                                </g>
                                                <g id="Path_188-2" data-name="Path 188">
                                                    <path d="M604.91,651.65H127.21V85.18h477.7ZM140.39,638.46H591.72V98.37H140.39Z"
                                                          style="fill:#4d4c4c"/>
                                                </g>
                                            </g>
                                            <g id="Group_65-2" data-name="Group 65">
                                                <g id="Group_60-2" data-name="Group 60">
                                                    <g id="Path_189-2" data-name="Path 189">
                                                        <path d="M142.73,99.19V637.65H592.32V99.19Zm424.87,517H167.45V120.62H567.6Z"
                                                              style="fill:#5b5b5b"/>
                                                    </g>
                                                </g>
                                                <g id="Group_61-2" data-name="Group 61">
                                                    <g id="Path_190-2" data-name="Path 190">
                                                        <path d="M598.92,644.24H136.13V92.59H598.92Zm-449.6-13.19H585.73V105.78H149.32Z"
                                                              style="fill:#4d4c4c"/>
                                                    </g>
                                                </g>
                                                <g id="Group_64-2" data-name="Group 64">
                                                    <g id="Group_62-2" data-name="Group 62" style="opacity:0.5">
                                                        <g id="Rectangle_128-2" data-name="Rectangle 128">
                                                            <rect x="167.45" y="120.62" width="400.15" height="495.6" style="fill:#cee4f7"/>
                                                        </g>
                                                    </g>
                                                    <g id="Group_63-2" data-name="Group 63">
                                                        <g id="Path_191-2" data-name="Path 191">
                                                            <path d="M570.89,619.51H164.16V117.32H570.89Zm-400.14-6.59H564.3v-489H170.75Z"
                                                                  style="fill:#2e2e2d"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g id="Illustration">
                            <rect id="Rectangle_5" data-name="Rectangle 5" x="72.82" y="51.35" width="471.16" height="242.88"
                                  style="fill:#e8e8e8"/>
                            <rect id="Rectangle_6" data-name="Rectangle 6" x="72.82" y="56.54" width="471.16" height="11.25"
                                  style="fill:#d2d0d1"/>
                            <rect id="Rectangle_7" data-name="Rectangle 7" x="156.45" y="87.83" width="303.9" height="193.64"
                                  style="fill:#fff;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                            <path id="arrow"
                                  d="M91.63,188l20.69,20.75a1.67,1.67,0,0,0,2.37,0h0L135.39,188a1.67,1.67,0,0,0,0-2.37h0l-2.74-2.74a1.65,1.65,0,0,0-2.34,0l-.05,0-13.1,13.58V161.71a1.69,1.69,0,0,0-1.68-1.68h-3.91a1.69,1.69,0,0,0-1.68,1.68V196.5l-13.1-13.58a1.69,1.69,0,0,0-2.38,0h0l-2.74,2.74a1.65,1.65,0,0,0-.07,2.34l0,0Z"
                                  style="fill:#f4990a"/>
                            <path id="Path_2" data-name="Path 2"
                                  d="M83.84,6.49H533a2.57,2.57,0,0,1,2.34,1.76l19.12,44.24c.72,1.92-.52,10.54-2.35,10.54H64.73c-1.83,0-3.07-8.62-2.35-10.54L81.5,8.25A2.57,2.57,0,0,1,83.84,6.49Z"
                                  style="fill:#6a5647"/>
                            <path id="Path_3" data-name="Path 3"
                                  d="M83.84,0H533a2.57,2.57,0,0,1,2.34,1.76l19.12,50.73c.72,1.92-.52,4-2.35,4H64.73c-1.83,0-3.07-2.13-2.35-4L81.5,1.76A2.57,2.57,0,0,1,83.84,0Z"
                                  style="fill:#947668"/>
                            <path id="Path_4" data-name="Path 4"
                                  d="M150.83,286.37H466a1.08,1.08,0,0,0,1-1.13,1,1,0,0,0-.16-.54h0a4.1,4.1,0,0,0-3.49-2H153.18a3.86,3.86,0,0,0-3.3,1.9h0a1.17,1.17,0,0,0,1,1.78Z"
                                  style="fill:#464545"/>
                            <path id="Path_5" data-name="Path 5" d="M37.81,327.1H578.19l-33.75-32.88H71.56Z" style="fill:#bababa"/>
                            <g id="Group_9" data-name="Group 9">
                                <g id="Group_7" data-name="Group 7">
                                    <g id="Group_4" data-name="Group 4">
                                        <path id="Path_6" data-name="Path 6"
                                              d="M369.73,105.58a1.32,1.32,0,0,0-1.09,1.3V292.65a2,2,0,0,0,0,.23,1.33,1.33,0,0,0,1.53,1.07l87.74-15.47V90Zm7.57,6.16,72-12.69V272.5l-72,12.7Z"
                                              style="fill:#5b5b5b"/>
                                        <path id="Path_7" data-name="Path 7"
                                              d="M370.19,294l86.65-15.28a1.32,1.32,0,0,0,1.09-1.3V91.6a1.32,1.32,0,0,0-1.32-1.32l-.23,0-86.65,15.28a1.32,1.32,0,0,0-1.09,1.3V292.65A1.33,1.33,0,0,0,370,294Z"
                                              style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                        <path id="Path_8" data-name="Path 8" d="M377.3,285.19l72-12.69V99.05l-72,12.69Z"
                                              style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                        <path id="Path_9" data-name="Path 9" d="M443.66,163.9v14l-54-63.22,10-1.77Z" style="fill:#fff"/>
                                        <path id="Path_10" data-name="Path 10" d="M443.66,144.76v14L405.26,112l10.05-1.77Z"
                                              style="fill:#fff"/>
                                    </g>
                                    <g id="Group_5" data-name="Group 5">
                                        <path id="Path_11" data-name="Path 11"
                                              d="M365.42,105.58,278.77,90.3l-.23,0a1.31,1.31,0,0,0-1.31,1.32V277.37a1.3,1.3,0,0,0,1.08,1.3L365,294l.23,0a1.32,1.32,0,0,0,1.32-1.32V106.88A1.32,1.32,0,0,0,365.42,105.58Zm-79.54-6.53,72,12.7V285.2l-72-12.7Z"
                                              style="fill:#5b5b5b"/>
                                        <path id="Path_12" data-name="Path 12"
                                              d="M278.31,278.67,365,294a1.33,1.33,0,0,0,1.53-1.07.93.93,0,0,0,0-.23V106.88a1.32,1.32,0,0,0-1.09-1.3L278.77,90.3a1.31,1.31,0,0,0-1.52,1.07.9.9,0,0,0,0,.23V277.37A1.31,1.31,0,0,0,278.31,278.67Z"
                                              style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                        <path id="Path_13" data-name="Path 13" d="M285.88,272.5l72,12.69V111.74l-72-12.69Z"
                                              style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                        <path id="Path_14" data-name="Path 14" d="M352.25,174.62v14l-54-82.26,10,1.77Z" style="fill:#fff"/>
                                        <path id="Path_15" data-name="Path 15" d="M352.25,155.48v14l-38.4-60.38,10,1.77Z"
                                              style="fill:#fff"/>
                                    </g>
                                    <g id="Group_6" data-name="Group 6">
                                        <path id="Path_16" data-name="Path 16"
                                              d="M275.07,91.37a1.32,1.32,0,0,0-1.53-1.07L185.8,105.77V294.22L274,278.67a1.32,1.32,0,0,0,1.09-1.3V91.6A1.79,1.79,0,0,0,275.07,91.37Zm-80.61,20.38,72-12.7V272.51l-72,12.69Z"
                                              style="fill:#5b5b5b"/>
                                        <path id="Path_17" data-name="Path 17"
                                              d="M187.35,294,274,278.67a1.32,1.32,0,0,0,1.09-1.3V91.6a1.32,1.32,0,0,0-1.32-1.32l-.23,0-86.65,15.28a1.3,1.3,0,0,0-1.08,1.3V292.65a1.32,1.32,0,0,0,1.31,1.32Z"
                                              style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                        <path id="Path_18" data-name="Path 18" d="M194.46,285.19l72-12.69V99.05l-72,12.69Z"
                                              style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                        <path id="Path_19" data-name="Path 19" d="M260.83,163.9v14l-54-63.22,10-1.77Z" style="fill:#fff"/>
                                        <path id="Path_20" data-name="Path 20" d="M260.83,144.76v14L222.43,112l10-1.77Z" style="fill:#fff"/>
                                    </g>
                                </g>
                                <g id="Group_8" data-name="Group 8">
                                    <path id="Path_21" data-name="Path 21"
                                          d="M190.87,203.13h0a1.72,1.72,0,0,0,1.37-1.61v-11a1.13,1.13,0,0,0-1.09-1.16,1.33,1.33,0,0,0-.28,0h0a1.7,1.7,0,0,0-1.37,1.61V202a1.11,1.11,0,0,0,1.09,1.15A.88.88,0,0,0,190.87,203.13Z"
                                          style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                                    <path id="Path_22" data-name="Path 22"
                                          d="M197.83,192.32h0a1.12,1.12,0,0,0-1.08-1.15.93.93,0,0,0-.29,0l-4.79.85a1.7,1.7,0,0,0-1.37,1.61h0a1.11,1.11,0,0,0,1.08,1.15,1,1,0,0,0,.29,0l4.79-.85A1.72,1.72,0,0,0,197.83,192.32Z"
                                          style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                                </g>
                            </g>
                            <rect id="Rectangle_9" data-name="Rectangle 9" x="37.81" y="327.1" width="540.39" height="7.28"
                                  style="fill:#757474"/>
                        </g>
                    </svg>
                </article>
            </div>
        </section>
        <nav class="container sm-fixed-bottom text-center">
            <section class="footer-left col-6 col-lg-3 float-left">
                <button @click="goBack" type="button" class="btn btn-back btn-lg w-100"><i class="fa fa-angle-left"></i> Go Back</button>
            </section>
            <section class="footer-right col-6 col-lg-3 float-right">
                <button @click="next" type="button" href="#" class="btn btn-next btn-lg w-100 btn-disable">Next Step <i
                    class="fa fa-angle-right"></i></button>
            </section>
        </nav>
    </main>
</template>
<script>
export default {
    created() {
        document.querySelectorAll('nav.stages ul li').forEach((el) => {
            if(el.classList.contains('two')) {
                el.classList.add('active');
            }
        });
    },
    methods: {
        next: function (e) {
            if (!e.target.classList.contains('btn-disable')) {
                document.querySelector('body').querySelector('#aluminium-external-bifold-3000less-step-3').click();

                document.querySelectorAll('nav.stages ul li').forEach((el) => {
                    if(el.classList.contains('two')) {
                        el.classList.remove('active');
                        el.classList.add('done');
                    }
                });
            }
        },
        goBack: function () {
            window.history.back();
        },
        saveData: function (e) {
            if (e.target.closest('article').classList.contains('option-in')) {
                sessionStorage.ProdConfiguredInwardsOrOutwards = 'Inwards';
            }

            if (e.target.closest('article').classList.contains('option-out')) {
                sessionStorage.ProdConfiguredInwardsOrOutwards = 'Outwards';
            }

            document.querySelectorAll(".option").forEach((el) => {
                el.classList.remove('chosen');
            });
            e.target.closest('article').classList.add('chosen');
            document.querySelector('body').querySelector('.btn-next').classList.remove('btn-disable');
        }
    }
}
</script>
