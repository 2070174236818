<template>
    <main id="direction-options" class="container option-wrapper">
        <section class="row">
            <h3 class="title col-12">If you have sufficient rear or side access, we can deliver the set pre-assembled. Do you have sufficient access?</h3>
            <p  class="description col-12">A normal side or rear gate is sufficient at a minimum, without vertical restrictions. Space will be required to manoeuvre</p>
        </section>
        <section class="choices row">
            <div class="col-12 col-md-6 col-xl-6 mb-4">
                <article @click="saveData" class="option option-access">
                    <i class="far fa-check-circle"></i>
                    <h4>I have sufficient access</h4>
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         viewBox="0 0 616 360">
                        <defs>
                            <clipPath id="clip-path">
                                <path d="M-1.39,0h618V346.3a5,5,0,0,1-5,5H3.61a5,5,0,0,1-5-5V0Z" style="fill:none"/>
                            </clipPath>
                        </defs>
                        <path id="Rectangle_8" data-name="Rectangle 8" d="M.28,299.13H616V355a5,5,0,0,1-5,5H5.28a5,5,0,0,1-5-5V299.13Z"
                              style="fill:#d5db42"/>
                        <path id="Path_126" data-name="Path 126" d="M13.11,360H239.3l59.21-60.87H83.67Z" style="fill:#947668"/>
                        <g id="Group_42" data-name="Group 42">
                            <g style="clip-path:url(#clip-path)">
                                <g id="Group_41" data-name="Group 41">
                                    <g id="Group_40" data-name="Group 40">
                                        <rect id="Rectangle_42" data-name="Rectangle 42" x="356.8" y="50.19" width="474.05" height="237.39"
                                              style="fill:#e8e8e8"/>
                                        <rect id="Rectangle_43" data-name="Rectangle 43" x="356.8" y="55.26" width="474.05" height="10.99"
                                              style="fill:#d2d0d1"/>
                                        <rect id="Rectangle_44" data-name="Rectangle 44" x="438.54" y="85.84" width="310.57" height="189.26"
                                              style="fill:#fff;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                        <path id="Path_123" data-name="Path 123"
                                              d="M819.47,6.34H368.16a2.58,2.58,0,0,0-2.35,1.72L346.6,51.31c-.73,1.87.51,10.29,2.35,10.29H838.68c1.84,0,3.08-8.42,2.36-10.29L821.83,8.06A2.58,2.58,0,0,0,819.47,6.34Z"
                                              style="fill:#6a5647"/>
                                        <path id="Path_124" data-name="Path 124"
                                              d="M819.47,0H368.16a2.56,2.56,0,0,0-2.35,1.72L346.6,51.31A2.83,2.83,0,0,0,348,55a2.9,2.9,0,0,0,.91.23H838.68a2.81,2.81,0,0,0,2.59-3,2.65,2.65,0,0,0-.23-.91L821.83,1.72A2.57,2.57,0,0,0,819.47,0Z"
                                              style="fill:#947668"/>
                                        <path id="Path_125" data-name="Path 125" d="M863.59,319.71H321.88l33.83-32.14H829.76Z"
                                              style="fill:#bababa"/>
                                        <rect id="Rectangle_46" data-name="Rectangle 46" x="321.88" y="319.71" width="541.71" height="7.12"
                                              style="fill:#757474"/>
                                        <g id="arrow">
                                            <rect x="19.16" y="129.69" width="5.08" height="94.73" rx="2.25" style="fill:#f5ab11"/>
                                            <rect x="316.81" y="129.69" width="5.08" height="94.73" rx="2.25" style="fill:#f5ab11"/>
                                            <rect x="139.38" y="174.52" width="15.76" height="5.08" rx="2.25" style="fill:#f5ab11"/>
                                            <rect x="117.1" y="174.52" width="15.76" height="5.08" rx="2.25" style="fill:#f5ab11"/>
                                            <rect x="94.81" y="174.52" width="15.76" height="5.08" rx="2.25" style="fill:#f5ab11"/>
                                            <rect x="72.52" y="174.52" width="15.76" height="5.08" rx="2.25" style="fill:#f5ab11"/>
                                            <rect x="50.23" y="174.52" width="15.76" height="5.08" rx="2.25" style="fill:#f5ab11"/>
                                            <rect x="27.94" y="174.52" width="15.76" height="5.08" rx="2.25" style="fill:#f5ab11"/>
                                            <rect x="161.67" y="174.52" width="15.76" height="5.08" rx="2.25" style="fill:#f5ab11"/>
                                            <rect x="183.96" y="174.52" width="15.76" height="5.08" rx="2.25" style="fill:#f5ab11"/>
                                            <rect x="206.25" y="174.52" width="15.76" height="5.08" rx="2.25" style="fill:#f5ab11"/>
                                            <rect x="228.54" y="174.52" width="15.76" height="5.08" rx="2.25" style="fill:#f5ab11"/>
                                            <rect x="250.83" y="174.52" width="15.76" height="5.08" rx="2.25" style="fill:#f5ab11"/>
                                            <rect x="273.12" y="174.52" width="15.76" height="5.08" rx="2.25" style="fill:#f5ab11"/>
                                            <rect x="295.41" y="174.52" width="15.76" height="5.08" rx="2.25" style="fill:#f5ab11"/>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </article>
            </div>
            <div class="col-12 col-md-6 col-xl-6 mb-4">
                <article @click="saveData" class="option option-noaccess">
                    <i class="far fa-check-circle hidden"></i>
                    <h4>I don't have sufficient access</h4>
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         viewBox="0 0 616 360">
                        <defs>
                            <clipPath id="clip-path">
                                <path d="M0,0H616.6V354.92a5.05,5.05,0,0,1-5,5.12H5a5.05,5.05,0,0,1-5-5.12V0Z" style="fill:none"/>
                            </clipPath>
                        </defs>
                        <path id="Rectangle_8" data-name="Rectangle 8" d="M.28,299.13H616V355a5,5,0,0,1-5,5H5.28a5,5,0,0,1-5-5V299.13Z"
                              style="fill:#d5db42"/>
                        <g id="Group_45" data-name="Group 45">
                            <g style="clip-path:url(#clip-path)">
                                <g id="Group_45-2" data-name="Group 45">
                                    <polyline points="-1.39 0 616.61 0 616.61 0 616.61 346.3" style="fill:none"/>
                                    <rect id="Rectangle_42" data-name="Rectangle 42" x="356.8" y="50.19" width="474.05" height="237.39"
                                          style="fill:#e8e8e8"/>
                                    <rect id="Rectangle_43" data-name="Rectangle 43" x="356.8" y="55.26" width="474.05" height="10.99"
                                          style="fill:#d2d0d1"/>
                                    <rect id="Rectangle_44" data-name="Rectangle 44" x="438.54" y="85.84" width="310.57" height="189.26"
                                          style="fill:#fff;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                    <path id="Path_123" data-name="Path 123"
                                          d="M819.47,6.34H368.16a2.58,2.58,0,0,0-2.35,1.72L346.6,51.31c-.73,1.87.51,10.29,2.35,10.29H838.68c1.84,0,3.08-8.42,2.36-10.29L821.83,8.06A2.58,2.58,0,0,0,819.47,6.34Z"
                                          style="fill:#6a5647"/>
                                    <path id="Path_124" data-name="Path 124"
                                          d="M819.47,0H368.16a2.56,2.56,0,0,0-2.35,1.72L346.6,51.31A2.83,2.83,0,0,0,348,55a2.9,2.9,0,0,0,.91.23H838.68a2.81,2.81,0,0,0,2.59-3,2.65,2.65,0,0,0-.23-.91L821.83,1.72A2.57,2.57,0,0,0,819.47,0Z"
                                          style="fill:#947668"/>
                                    <path id="Path_125" data-name="Path 125" d="M863.59,319.71H321.88l33.83-32.14H829.76Z"
                                          style="fill:#bababa"/>
                                    <rect id="Rectangle_46" data-name="Rectangle 46" x="321.88" y="319.71" width="541.71" height="7.12"
                                          style="fill:#757474"/>
                                    <g style="clip-path:url(#clip-path)">
                                        <g id="Group_44" data-name="Group 44">
                                            <g id="Group_43" data-name="Group 43">
                                                <path id="Path_130" data-name="Path 130" d="M144.11,360H251l59.21-60.91H214.67Z"
                                                      style="fill:#947668"/>
                                                <g id="arrow">
                                                    <rect x="193.84" y="129.69" width="5.07" height="94.73" rx="2.25" style="fill:#f5ab11"/>
                                                    <rect x="318.82" y="129.69" width="5.07" height="94.73" rx="2.25" style="fill:#f5ab11"/>
                                                    <rect x="208.26" y="174.52" width="15.76" height="5.08" rx="2.25" style="fill:#f5ab11"/>
                                                    <rect x="230.55" y="174.52" width="15.76" height="5.08" rx="2.25" style="fill:#f5ab11"/>
                                                    <rect x="252.84" y="174.52" width="15.76" height="5.08" rx="2.25" style="fill:#f5ab11"/>
                                                    <rect x="275.13" y="174.52" width="15.76" height="5.08" rx="2.25" style="fill:#f5ab11"/>
                                                    <rect x="297.42" y="174.52" width="15.76" height="5.08" rx="2.25" style="fill:#f5ab11"/>
                                                </g>
                                                <path id="Path_131" data-name="Path 131" d="M74.83,17.2l88.17-4V299.13L74.83,351.3Z"
                                                      style="fill:#e8e8e8"/>
                                                <rect id="Rectangle_75" data-name="Rectangle 75" x="56.22" y="17.2" width="18.61"
                                                      height="334.1" style="fill:#757474"/>
                                                <path id="Path_132" data-name="Path 132" d="M163,13.24l-88.17,4H56.22l93.7-3.84Z"
                                                      style="fill:#bababa"/>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </article>
            </div>
        </section>
        <nav class="container sm-fixed-bottom text-center">
            <section class="footer-left col-6 col-lg-3 float-left">
                <button @click="goBack" type="button" class="btn btn-back btn-lg w-100"><i class="fa fa-angle-left"></i> Go Back</button>
            </section>
            <section class="footer-right col-6 col-lg-3 float-right">
                <button @click="next" type="button" href="#" class="btn btn-confirm btn-lg w-100 btn-disable">Confirm
                    Choices <i class="far fa-check-circle"></i></button>
            </section>
        </nav>
    </main>
</template>
<script>
export default {
    created() {
        document.querySelectorAll('nav.stages ul li').forEach((el) => {
            if(el.classList.contains('four')) {
                el.classList.add('active');
            }
            el.classList.remove('hidden');
        });
    },
    methods: {
        next: function (e) {
            if (!e.target.classList.contains('btn-disable')) {
                    document.querySelector('body').querySelector('#dashboard').click();

                document.querySelectorAll('nav.stages ul li').forEach((el) => {
                    if(el.classList.contains('four')) {
                        el.classList.remove('active');
                        el.classList.add('done');
                    }
                });
            }
        },
        goBack: function () {
            window.history.back();
        },
        saveData: function (e) {
            if (e.target.closest('article').classList.contains('option-access')) {
                sessionStorage.ProdConfiguredDeliveryClearance = 'Sufficent Access';
            }

            if (e.target.closest('article').classList.contains('option-noaccess')) {
                sessionStorage.ProdConfiguredDeliveryClearance = 'Insufficient Access';
            }

            document.querySelectorAll(".option").forEach((el) => {
                el.classList.remove('chosen');
            });
            e.target.closest('article').classList.add('chosen');
            document.querySelector('body').querySelector('.btn-confirm').classList.remove('btn-disable');
        }
    }
}
</script>
