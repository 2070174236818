<template>
    <main id="products" class="container product-wrapper">
        <div class="loading d-flex justify-content-center" style="z-index: 99999;background:#ffffffcf;position:fixed;top:0vh;left:0vw;width:100vw;height:100vh;">
            <div class="spinner-border" role="status" style="margin-top: 48vh;">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <section class="row">
            <h3 class="title col-12">Dashboard</h3>
            <p class="description col-12">Please configure all of the products listed below, and then click the <strong>Confirm
                Choices</strong> button</p>
        </section>
        <section class="choices row">
        </section>
        <section class="footer-right col-6 col-lg-3 float-right">
            <button type="button" href="#" class="btn btn-confirm btn-configurator-finish btn-lg w-100 btn-disable">
                Confirm Choices <i class="far fa-check-circle"></i></button>
        </section>
    </main>
</template>
<script>
import external_aluminium_bifold_3000_less_dashboard_product from './products/external-aluminium-bifold-3000less';
import external_aluminium_bifold_3000_plus_dashboard_product from './products/external-aluminium-bifold-3000plus';
import external_aluminium_french_3000_less_dashboard_product from './products/external-aluminium-french-3000less';
import external_aluminium_french_3000_plus_dashboard_product from './products/external-aluminium-french-3000plus';
import external_aluminium_sliding_dashboard_product from './products/external-aluminium-sliding';

import Vue from "vue";
import axios from "axios";

function persistData(token, current_product, is_final) {
    return new Promise((resolve) => {
        if (current_product !== null && current_product !== undefined && current_product !== '') {
            build_product_session_data(current_product)
                .then((product_session_data) => {
                    update_session(sessionStorage.token, 0, product_session_data).then(() => {
                        sessionStorage.clear();
                        sessionStorage.token = token;
                        resolve(product_session_data);
                    });
                });
        } else {
            resolve(false);
        }
    });
}

function build_product_session_data(current_product) {
    return new Promise(function (resolve) {
        get_session(sessionStorage.token)
            .then((session) => {
                let session_prod_data = JSON.parse(session.data);
                for (let [key, val] of Object.entries(session_prod_data)) {
                    if (val[0] === current_product) {
                        val[1].confData = {};
                        for (let [_key, _val] of Object.entries(sessionStorage)) {
                            if (_key !== "currentProduct" && _key !== "token") {
                                val[1].confData[_key] = _val;
                            }
                        }
                        val[1].confData['ProdConfigured'] = "done";
                    }
                }
                resolve(session_prod_data);
            });
    });
}

function create_session(token, data) {
    return new Promise(function (resolve, reject) {
        axios.post("/api/session/create/", {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            data: {
                token: token,
                configured: 0,
                data: JSON.stringify(data)
            }
        })
            .then((res) => {
                resolve(res.data);
            })
            .catch((e) => {
                console.log(e);
                reject("error");
            });
    });
}

function get_session(token) {
    return new Promise(function (resolve, reject) {
        axios.get("/api/session/" + token, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
                resolve(res.data);
            })
            .catch((e) => {
                console.log(e);
                reject("error");
            });
    });
}

function update_session(token, configured, data) {
    return new Promise(function (resolve, reject) {
        axios.post("/api/session/update", {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            data: {
                token: token,
                configured: configured,
                data: JSON.stringify(data)
            }
        })
            .then((res) => {
                resolve(res.status);
            })
            .catch((e) => {
                console.log(e);
                reject("error");
            });
    });
}

function product_dashboard(session) {
    return new Promise(function (resolve) {
        let product_data = JSON.parse(session.data);
        if (session.configured === 1) {
            resolve('configured');
            return;
        }
        Object.keys(product_data).forEach(function (prod) {
            let prodObj = product_data[prod]; // key
            let component;
            let dashboardProdData;

            switch (prodObj[1].type) {
                case "external-aluminium-bifold":
                    if (parseInt(prodObj[1].width) > 3000) {
                        dashboardProdData = {
                            propsData: {
                                ProdId: prodObj[1].id,
                                ProdName: prodObj[1].name,
                                ProdImg: prodObj[1].img,
                                ProdConfiguredDirection: prodObj[1].confData.ProdConfiguredDirection,
                                ProdConfiguredInwardsOrOutwards: prodObj[1].confData.ProdConfiguredInwardsOrOutwards,
                                ProdConfiguredTypeOfSill: prodObj[1].confData.ProdConfiguredTypeOfSill,
                                ProdConfigured: prodObj[1].confData.ProdConfigured
                            }
                        };
                        component = Vue.extend(external_aluminium_bifold_3000_plus_dashboard_product);
                    } else {
                        dashboardProdData = {
                            propsData: {
                                ProdId: prodObj[1].id,
                                ProdName: prodObj[1].name,
                                ProdImg: prodObj[1].img,
                                ProdConfiguredDirection: prodObj[1].confData.ProdConfiguredDirection,
                                ProdConfiguredInwardsOrOutwards: prodObj[1].confData.ProdConfiguredInwardsOrOutwards,
                                ProdConfiguredTypeOfSill: prodObj[1].confData.ProdConfiguredTypeOfSill,
                                ProdConfiguredDeliveryClearance: prodObj[1].confData.ProdConfiguredDeliveryClearance,
                                ProdConfigured: prodObj[1].confData.ProdConfigured
                            }
                        };
                        component = Vue.extend(external_aluminium_bifold_3000_less_dashboard_product);
                    }
                    break;
                case "external-aluminium-sliding":
                    dashboardProdData = {
                        propsData: {
                            ProdId: prodObj[1].id,
                            ProdName: prodObj[1].name,
                            ProdImg: prodObj[1].img,
                            ProdConfiguredDirection: prodObj[1].confData.ProdConfiguredDirection,
                            ProdConfiguredWithOrWithoutSill: prodObj[1].confData.ProdConfiguredWithOrWithoutSill,
                            ProdConfigured: prodObj[1].confData.ProdConfigured
                        }
                    };
                    component = Vue.extend(external_aluminium_sliding_dashboard_product);
                    break;
                case "external-aluminium-french":
                    if (parseInt(prodObj[1].width) > 3000) {
                        dashboardProdData = {
                            propsData: {
                                ProdId: prodObj[1].id,
                                ProdName: prodObj[1].name,
                                ProdImg: prodObj[1].img,
                                ProdConfiguredDirection: prodObj[1].confData.ProdConfiguredDirection,
                                ProdConfiguredTypeOfSill: prodObj[1].confData.ProdConfiguredTypeOfSill,
                                ProdConfiguredHandling: prodObj[1].confData.ProdConfiguredHandling,
                                ProdConfigured: prodObj[1].confData.ProdConfigured
                            }
                        };
                        component = Vue.extend(external_aluminium_french_3000_plus_dashboard_product);
                    } else {
                        dashboardProdData = {
                            propsData: {
                                ProdId: prodObj[1].id,
                                ProdName: prodObj[1].name,
                                ProdImg: prodObj[1].img,
                                ProdConfiguredDirection: prodObj[1].confData.ProdConfiguredDirection,
                                ProdConfiguredTypeOfSill: prodObj[1].confData.ProdConfiguredTypeOfSill,
                                ProdConfiguredHandling: prodObj[1].confData.ProdConfiguredHandling,
                                ProdConfiguredDeliveryClearance: prodObj[1].confData.ProdConfiguredDeliveryClearance,
                                ProdConfigured: prodObj[1].confData.ProdConfigured
                            }
                        };
                        component = Vue.extend(external_aluminium_french_3000_less_dashboard_product);
                    }
                    break;
                default:
                    break;
            }
            let DashboardComponent = new component(dashboardProdData).$mount();
            document.querySelector('.choices.row').append(DashboardComponent.$el);
        });
        resolve(session);
    });
}

function init(token) {
    return new Promise(function (resolve) {
        get_session(token)
            .then((session) => {
                if (session === 'No Session Found') {
                    init_session(token)
                        .then((products) => {
                            if(products.product_message) {
                                let status = products.product_message[1];
                                if(status === 'No order could be found with that token') {
                                    document.querySelector('body').querySelector('.loading').classList.add('hidden');
                                    alert(status);
                                    return;
                                }
                            }

                            let session = create_session(token, products);
                            resolve(session);
                        });
                } else {
                    resolve(session);
                }
            });
    });
}

function init_session(token) {
    return new Promise(function (resolve, reject) {
        fetch('https://www.aspire-doors.co.uk/wp-json/aspire-amc/v1/get_order_details', {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: 'token=' + token
        })
            .then(res => res.json())
            .then((data) => {
                let products = {};
                Object.keys(data).forEach((product) => {
                    let key = `product_${product}`;
                    products[key] = [product, data[product]];
                });
                resolve(products);
            })
            .catch((error) => {
                console.log('Request failed', error);
                reject(error);
            });
    });
}

function set_token_local_storage(token) {
    return new Promise(function (resolve) {
        if (!sessionStorage.token && token !== undefined) {
            sessionStorage.token = token;
            resolve(token);
        } else {
            resolve(sessionStorage.token);
        }
    });
}

function is_configurator_done() {
    return new Promise((resolve) => {
        document.querySelector('body').querySelectorAll('article.product').forEach((el) => {
            if (!el.classList.contains('done')) {
                resolve(false);
            }
        });
        resolve(true);
    });
}

export default {
    created() {
        let token;
        if (this.$route.query.token) {
            token = this.$route.query.token;
        } else {
            token = sessionStorage.token;
        }
        document.querySelector('body').querySelector('h1 span.product-name').innerHTML = '';
        persistData(token, sessionStorage.currentProduct, false).then((product_session_data) => {
            set_token_local_storage(token)
                .then((token) => {
                    if (token !== undefined && sessionStorage.token) {
                        init(token)
                            .then((session) => {
                                product_dashboard(session)
                                    .then((session) => {
                                        if (session === "configured") {
                                            document.querySelector('body').querySelector('.loading').classList.add('hidden');
                                            alert("This order has already been configured. For assistance, please call us on 020 3744 0704");
                                            return;
                                        }
                                        document.querySelectorAll('.aluminium-external-bifold-3000less-step-1-btn-choose').forEach(el => {
                                            el.addEventListener('click', (e) => {
                                                document.querySelector('body').querySelector('#aluminium-external-bifold-3000less-step-1').click();
                                                document.querySelector('body').querySelector('h1 span.product-name').innerHTML = JSON.parse(session.data)["product_" + el.dataset.prodid][1].name;
                                                sessionStorage.currentProduct = el.dataset.prodid;
                                            });
                                        });
                                        document.querySelectorAll('.aluminium-external-bifold-3000plus-step-1-btn-choose').forEach(el => {
                                            el.addEventListener('click', (e) => {
                                                document.querySelector('body').querySelector('#aluminium-external-bifold-3000plus-step-1').click();
                                                document.querySelector('body').querySelector('h1 span.product-name').innerHTML = JSON.parse(session.data)["product_" + el.dataset.prodid][1].name;
                                                sessionStorage.currentProduct = el.dataset.prodid;
                                            });
                                        });
                                        document.querySelectorAll('.aluminium-external-french-3000less-step-1-btn-choose').forEach(el => {
                                            el.addEventListener('click', (e) => {
                                                document.querySelector('body').querySelector('#aluminium-external-french-3000less-step-1').click();
                                                document.querySelector('body').querySelector('h1 span.product-name').innerHTML = JSON.parse(session.data)["product_" + el.dataset.prodid][1].name;
                                                sessionStorage.currentProduct = el.dataset.prodid;
                                            });
                                        });
                                        document.querySelectorAll('.aluminium-external-french-3000plus-step-1-btn-choose').forEach(el => {
                                            el.addEventListener('click', (e) => {
                                                document.querySelector('body').querySelector('#aluminium-external-french-3000plus-step-1').click();
                                                document.querySelector('body').querySelector('h1 span.product-name').innerHTML = JSON.parse(session.data)["product_" + el.dataset.prodid][1].name;
                                                sessionStorage.currentProduct = el.dataset.prodid;
                                            });
                                        });
                                        document.querySelectorAll('.aluminium-external-sliding-step-1-btn-choose').forEach(el => {
                                            el.addEventListener('click', (e) => {
                                                document.querySelector('body').querySelector('#aluminium-external-sliding-step-1').click();
                                                document.querySelector('body').querySelector('h1 span.product-name').innerHTML = JSON.parse(session.data)["product_" + el.dataset.prodid][1].name;
                                                sessionStorage.currentProduct = el.dataset.prodid;
                                            });
                                        });

                                        document.querySelector('body').querySelector('button.btn-configurator-finish').addEventListener('click', () => {
                                            sessionStorage.session = JSON.stringify(session);
                                            document.querySelector('body').querySelector('#verify').click();
                                        });

                                        is_configurator_done()
                                            .then((res) => {
                                                if (res === true) {
                                                    document.querySelector('body').querySelector('button.btn-configurator-finish').classList.remove('btn-disable');
                                                }
                                            });
                                        document.querySelector('body').querySelector('.loading').classList.add('hidden');
                                    });
                            });
                    } else {
                        document.querySelector('body').querySelector('.loading').classList.add('hidden');
                        alert('No Token Provided!');
                    }
                });
        });
        document.querySelectorAll('nav.stages ul li').forEach((el) => {
            el.classList.add('hidden');
        });
    },
    components: {
        external_aluminium_bifold_3000_less_dashboard_product,
        external_aluminium_bifold_3000_plus_dashboard_product,
        external_aluminium_french_3000_less_dashboard_product,
        external_aluminium_french_3000_plus_dashboard_product,
        external_aluminium_sliding_dashboard_product
    }
}
</script>
