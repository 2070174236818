<template>
    <main id="direction-options" class="container option-wrapper">
        <section class="row">
            <h3 class="title col-12">Opening Direction</h3>
            <p class="description col-12">If standing outside in the garden looking towards the property, did you want
                the set to open right to left or left to right?</p>
        </section>
        <section class="choices row">
            <div class="col-12 col-md-6 col-xl-6 mb-4">
                <article @click="saveData" class="option option-rtl">
                    <i class="far fa-check-circle"></i>
                    <h4>Right to Left</h4>
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 616 360">
                        <path id="Rectangle_8" data-name="Rectangle 8" d="M.28,299.13H616V355a5,5,0,0,1-5,5H5.28a5,5,0,0,1-5-5V299.13Z"
                              style="fill:#d5db42"/>
                        <g id="Group_56" data-name="Group 56">
                            <rect id="Rectangle_107" data-name="Rectangle 107" x="66.01" y="51.24" width="483.98" height="242.37"
                                  style="fill:#e8e8e8"/>
                            <rect id="Rectangle_108" data-name="Rectangle 108" x="66.01" y="56.42" width="483.98" height="11.23"
                                  style="fill:#d2d0d1"/>
                            <rect id="Rectangle_109" data-name="Rectangle 109" x="149.46" y="87.64" width="317.08" height="193.23"
                                  style="fill:#f4f4f4;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                            <g id="Group_54" data-name="Group 54">
                                <path id="Path_167" data-name="Path 167" d="M151,90.23v188H308v-188ZM299.36,270.8H159.62V97.71H299.36Z"
                                      style="fill:#5b5b5b"/>
                                <rect id="Rectangle_110" data-name="Rectangle 110" x="150.98" y="90.23" width="157.02" height="188.05"
                                      style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                <rect id="Rectangle_111" data-name="Rectangle 111" x="159.62" y="97.71" width="139.75" height="173.08"
                                      style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                <path id="Path_168" data-name="Path 168" d="M165.22,161.44v14l53.89-72.59h-10Z" style="fill:#fff"/>
                                <path id="Path_169" data-name="Path 169" d="M165.22,142.34v14l38.32-53.49h-10Z" style="fill:#fff"/>
                            </g>
                            <g id="Group_55" data-name="Group 55">
                                <path id="Path_170" data-name="Path 170" d="M266.42,90.23v188h157v-188ZM414.8,270.8H275.06V97.71H414.8Z"
                                      style="fill:#5b5b5b"/>
                                <rect id="Rectangle_112" data-name="Rectangle 112" x="266.42" y="90.23" width="157.02" height="188.05"
                                      style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                <rect id="Rectangle_113" data-name="Rectangle 113" x="275.06" y="97.71" width="139.75" height="173.08"
                                      style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                <path id="Path_171" data-name="Path 171" d="M280.66,161.44v14l53.89-72.59h-10Z" style="fill:#fff"/>
                                <path id="Path_172" data-name="Path 172" d="M280.66,142.34v14L319,102.84H309Z" style="fill:#fff"/>
                            </g>
                            <rect id="Rectangle_114" data-name="Rectangle 114" x="419.22" y="162.82" width="2.73" height="42.88" rx="1.21"
                                  style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                            <path id="arrow"
                                  d="M478.3,206.1l-20.71-20.65a1.67,1.67,0,0,1,0-2.37h0l20.71-20.65a1.66,1.66,0,0,1,2.36,0h0l2.73,2.73a1.66,1.66,0,0,1,0,2.34s0,0-.05.05l-13.55,13.07h34.72a1.68,1.68,0,0,1,1.67,1.68v3.9a1.68,1.68,0,0,1-1.67,1.68H469.82L483.37,201a1.68,1.68,0,0,1,0,2.38h0l-2.73,2.73a1.66,1.66,0,0,1-2.34.07l0,0Z"
                                  style="fill:#f4990a"/>
                            <path id="Path_174" data-name="Path 174"
                                  d="M538.38,6.48H77.62a2.62,2.62,0,0,0-2.41,1.75L55.6,52.38c-.74,1.91.52,10.51,2.4,10.51H558c1.88,0,3.14-8.6,2.4-10.51L540.79,8.23A2.62,2.62,0,0,0,538.38,6.48Z"
                                  style="fill:#6a5647"/>
                            <path id="Path_175" data-name="Path 175"
                                  d="M538.38,0H77.62a2.63,2.63,0,0,0-2.41,1.76L55.6,52.38a2.87,2.87,0,0,0,1.47,3.8,3,3,0,0,0,.93.24H558a2.89,2.89,0,0,0,2.64-3.1,3.1,3.1,0,0,0-.24-.94L540.79,1.76A2.63,2.63,0,0,0,538.38,0Z"
                                  style="fill:#947668"/>
                            <path id="Path_176" data-name="Path 176"
                                  d="M472.1,286.76H143.84a1.08,1.08,0,0,1-1.09-1.08,1.06,1.06,0,0,1,.17-.58h0a4.3,4.3,0,0,1,3.63-2h323.1a4.06,4.06,0,0,1,3.43,1.9h0a1.16,1.16,0,0,1-.36,1.6A1.23,1.23,0,0,1,472.1,286.76Z"
                                  style="fill:#464545"/>
                            <path id="Path_177" data-name="Path 177" d="M584.53,326.42H31.47L66,293.6H550Z" style="fill:#bababa"/>
                            <rect id="Rectangle_116" data-name="Rectangle 116" x="31.47" y="326.42" width="553.07" height="7.27"
                                  style="fill:#757474"/>
                        </g>
                    </svg>

                </article>
            </div>
            <div class="col-12 col-md-6 col-xl-6 mb-4">
                <article @click="saveData" class="option option-ltr">
                    <i class="far fa-check-circle hidden"></i>
                    <h4>Left to Right</h4>
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 616 360">
                        <path id="Rectangle_8" data-name="Rectangle 8" d="M.28,299.13H616V355a5,5,0,0,1-5,5H5.28a5,5,0,0,1-5-5V299.13Z"
                              style="fill:#d5db42"/>
                        <g id="Group_56" data-name="Group 56">
                            <rect id="Rectangle_107" data-name="Rectangle 107" x="66.01" y="51.24" width="483.98" height="242.37"
                                  style="fill:#e8e8e8"/>
                            <rect id="Rectangle_108" data-name="Rectangle 108" x="66.01" y="56.42" width="483.98" height="11.23"
                                  style="fill:#d2d0d1"/>
                            <rect id="Rectangle_109" data-name="Rectangle 109" x="149.46" y="87.64" width="317.08" height="193.23"
                                  style="fill:#f4f4f4;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                            <g id="Group_55" data-name="Group 55">
                                <path id="Path_170" data-name="Path 170" d="M307.6,90.23v188h157v-188ZM456,270.8H316.24V97.71H456Z"
                                      style="fill:#5b5b5b"/>
                                <rect id="Rectangle_112" data-name="Rectangle 112" x="307.6" y="90.23" width="157.02" height="188.05"
                                      style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                <rect id="Rectangle_113" data-name="Rectangle 113" x="316.24" y="97.71" width="139.75" height="173.08"
                                      style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                <path id="Path_171" data-name="Path 171" d="M321.84,161.44v14l53.89-72.59h-10Z" style="fill:#fff"/>
                                <path id="Path_172" data-name="Path 172" d="M321.84,142.34v14l38.32-53.49h-10Z" style="fill:#fff"/>
                            </g>
                            <g id="Group_54" data-name="Group 54">
                                <path id="Path_167" data-name="Path 167" d="M191.76,90.23v188h157v-188ZM340.14,270.8H200.4V97.71H340.14Z"
                                      style="fill:#5b5b5b"/>
                                <rect id="Rectangle_110" data-name="Rectangle 110" x="191.76" y="90.23" width="157.02" height="188.05"
                                      style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                <rect id="Rectangle_111" data-name="Rectangle 111" x="200.4" y="97.71" width="139.75" height="173.08"
                                      style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                <path id="Path_168" data-name="Path 168" d="M206,161.44v14l53.89-72.59h-10Z" style="fill:#fff"/>
                                <path id="Path_169" data-name="Path 169" d="M206,142.34v14l38.32-53.49h-10Z" style="fill:#fff"/>
                            </g>
                            <rect id="Rectangle_114" data-name="Rectangle 114" x="195.19" y="162.82" width="2.73" height="42.88" rx="1.21"
                                  style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                            <path id="Path_174" data-name="Path 174"
                                  d="M538.38,6.48H77.62a2.62,2.62,0,0,0-2.41,1.75L55.6,52.38c-.74,1.91.52,10.51,2.4,10.51H558c1.88,0,3.14-8.6,2.4-10.51L540.79,8.23A2.62,2.62,0,0,0,538.38,6.48Z"
                                  style="fill:#6a5647"/>
                            <path id="Path_175" data-name="Path 175"
                                  d="M538.38,0H77.62a2.63,2.63,0,0,0-2.41,1.76L55.6,52.38a2.87,2.87,0,0,0,1.47,3.8,3,3,0,0,0,.93.24H558a2.89,2.89,0,0,0,2.64-3.1,3.1,3.1,0,0,0-.24-.94L540.79,1.76A2.63,2.63,0,0,0,538.38,0Z"
                                  style="fill:#947668"/>
                            <path id="Path_176" data-name="Path 176"
                                  d="M472.1,286.76H143.84a1.08,1.08,0,0,1-1.09-1.08,1.06,1.06,0,0,1,.17-.58h0a4.3,4.3,0,0,1,3.63-2h323.1a4.06,4.06,0,0,1,3.43,1.9h0a1.16,1.16,0,0,1-.36,1.6A1.23,1.23,0,0,1,472.1,286.76Z"
                                  style="fill:#464545"/>
                            <path id="Path_177" data-name="Path 177" d="M584.53,326.42H31.47L66,293.6H550Z" style="fill:#bababa"/>
                            <rect id="Rectangle_116" data-name="Rectangle 116" x="31.47" y="326.42" width="553.07" height="7.27"
                                  style="fill:#757474"/>
                            <path id="arrow"
                                  d="M137.7,206.1l0,0a1.66,1.66,0,0,1-2.34-.07l-2.73-2.73h0a1.68,1.68,0,0,1,0-2.38l13.55-13.07H111.46a1.68,1.68,0,0,1-1.67-1.68v-3.9a1.68,1.68,0,0,1,1.67-1.68h34.72l-13.55-13.07s0,0,0-.05a1.66,1.66,0,0,1,0-2.34l2.73-2.73h0a1.66,1.66,0,0,1,2.36,0l20.71,20.65h0a1.67,1.67,0,0,1,0,2.37L137.7,206.1Z"
                                  style="fill:#f4990a"/>
                        </g>
                    </svg>
                </article>
            </div>
        </section>
        <nav class="container sm-fixed-bottom text-center">
            <section class="footer-left col-6 col-lg-3 float-left">
                <button @click="goBack" type="button" href="#" class="btn btn-back btn-lg w-100"><i
                    class="fa fa-angle-left"></i> Go Back
                </button>
            </section>
            <section class="footer-right col-6 col-lg-3 float-right">
                <button @click="next" type="button" href="#" class="btn btn-next btn-lg w-100 btn-disable">Next Step <i
                    class="fa fa-angle-right"></i></button>
            </section>
        </nav>
    </main>
</template>
<script>

export default {
    created() {
        document.querySelectorAll('nav.stages ul li').forEach((el) => {
            if(el.classList.contains('one')) {
                el.classList.add('active');
                el.classList.remove('hidden');
            }
            if(el.classList.contains('two')) {
                el.classList.remove('hidden');
            }

        });
    },
    methods: {
        next: function (e) {
            if (!e.target.classList.contains('btn-disable')) {
                document.querySelector('body').querySelector('#aluminium-external-sliding-step-2').click();
                document.querySelectorAll('nav.stages ul li').forEach((el) => {
                    if(el.classList.contains('one')) {
                        el.classList.remove('active');
                        el.classList.add('done');
                    }
                });
            }
        },
        goBack: function () {
            for(let [key, val] of Object.entries(sessionStorage)){
                if(key !== 'token'){
                    sessionStorage.removeItem(key);
                }
            }
            window.history.back();
        },
        saveData: function (e) {
            if (e.target.closest('article').classList.contains('option-rtl')) {
                sessionStorage.ProdConfiguredDirection = 'Right to Left';
            }

            if (e.target.closest('article').classList.contains('option-ltr')) {
                sessionStorage.ProdConfiguredDirection = 'Left to Right';
            }

            document.querySelectorAll(".option").forEach((el) => {
                el.classList.remove('chosen');
            });
            e.target.closest('article').classList.add('chosen');
            document.querySelector('body').querySelector('.btn-next').classList.remove('btn-disable');
        }
    }
}
</script>
