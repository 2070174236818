import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios';

// Common views
import App from './components/App.vue';
import dashboard from './components/dashboard.vue';
import thankyou from './components/thankyou.vue';
import verify from './components/verify.vue';
import FourOhFour from './components/FourOhFour.vue';

// External Aluminium Bifolds <3000mm
import external_aluminium_bifold_3000_less_step_1 from './components/external-aluminium-bifold/3000less/step-1.vue';
import external_aluminium_bifold_3000_less_step_2_rtl from './components/external-aluminium-bifold/3000less/step-2-rtl.vue';
import external_aluminium_bifold_3000_less_step_2_ltr from './components/external-aluminium-bifold/3000less/step-2-ltr.vue';
import external_aluminium_bifold_3000_less_step_3 from './components/external-aluminium-bifold/3000less/step-3.vue';
import external_aluminium_bifold_3000_less_step_4 from './components/external-aluminium-bifold/3000less/step-4.vue';

// External Aluminium Bifolds >3000mm
import external_aluminium_bifold_3000_plus_step_1 from './components/external-aluminium-bifold/3000plus/step-1.vue';
import external_aluminium_bifold_3000_plus_step_2_rtl from './components/external-aluminium-bifold/3000plus/step-2-rtl.vue';
import external_aluminium_bifold_3000_plus_step_2_ltr from './components/external-aluminium-bifold/3000plus/step-2-ltr.vue';
import external_aluminium_bifold_3000_plus_step_3 from './components/external-aluminium-bifold/3000plus/step-3.vue';

// External Aluminium Sliding
import external_aluminium_sliding_step_1 from './components/external-aluminium-sliding/step-1.vue';
import external_aluminium_sliding_step_2 from './components/external-aluminium-sliding/step-2.vue';

// External Aluminium French <3000mm
import external_aluminium_french_3000_less_step_1 from './components/external-aluminium-french/3000less/step-1.vue';
import external_aluminium_french_3000_less_step_2 from './components/external-aluminium-french/3000less/step-2.vue';
import external_aluminium_french_3000_less_step_3_inwards from './components/external-aluminium-french/3000less/step-3-inwards.vue';
import external_aluminium_french_3000_less_step_3_outwards from './components/external-aluminium-french/3000less/step-3-outwards.vue';
import external_aluminium_french_3000_less_step_4 from './components/external-aluminium-french/3000less/step-4.vue';

// External Aluminium French >3000mm
import external_aluminium_french_3000_plus_step_1 from './components/external-aluminium-french/3000plus/step-1.vue';
import external_aluminium_french_3000_plus_step_2 from './components/external-aluminium-french/3000plus/step-2.vue';
import external_aluminium_french_3000_plus_step_3_inwards from './components/external-aluminium-french/3000plus/step-3-inwards.vue';
import external_aluminium_french_3000_plus_step_3_outwards from './components/external-aluminium-french/3000plus/step-3-outwards.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: dashboard
    }, {
        path: '/verify',
        name: 'verify',
        component: verify
    }, {
        path: '/thankyou',
        name: 'thankyou',
        component: thankyou
    }, {
        path: '/aluminium-external-bifold-3000less/step-1',
        name: 'aluminium-external-bifold-3000less-step-1',
        component: external_aluminium_bifold_3000_less_step_1
    }, {
        path: '/aluminium-external-bifold-3000less/step-2/rtl',
        name: 'aluminium-external-bifold-3000less-step-2-rtl',
        component: external_aluminium_bifold_3000_less_step_2_rtl
    }, {
        path: '/aluminium-external-bifold-3000less/step-2/ltr',
        name: 'aluminium-external-bifold-3000less-step-2-ltr',
        component: external_aluminium_bifold_3000_less_step_2_ltr
    }, {
        path: '/aluminium-external-bifold-3000less/step-3',
        name: 'aluminium-external-bifold-3000less-step-3',
        component: external_aluminium_bifold_3000_less_step_3
    }, {
        path: '/aluminium-external-bifold-3000less/step-4',
        name: 'aluminium-external-bifold-3000less-step-4',
        component: external_aluminium_bifold_3000_less_step_4
    }, {
        path: '/aluminium-external-bifold-3000plus/step-1',
        name: 'aluminium-external-bifold-3000plus-step-1',
        component: external_aluminium_bifold_3000_plus_step_1
    }, {
        path: '/aluminium-external-bifold-3000plus/step-2/rtl',
        name: 'aluminium-external-bifold-3000plus-step-2-rtl',
        component: external_aluminium_bifold_3000_plus_step_2_rtl
    }, {
        path: '/aluminium-external-bifold-3000plus/step-2/ltr',
        name: 'aluminium-external-bifold-3000plus-step-2-ltr',
        component: external_aluminium_bifold_3000_plus_step_2_ltr
    }, {
        path: '/aluminium-external-bifold-3000plus/step-3',
        name: 'aluminium-external-bifold-3000plus-step-3',
        component: external_aluminium_bifold_3000_plus_step_3
    }, {
        path: '/aluminium-external-sliding/step-1',
        name: 'aluminium-external-sliding-step-1',
        component: external_aluminium_sliding_step_1
    }, {
        path: '/aluminium-external-sliding/step-2',
        name: 'aluminium-external-sliding-step-2',
        component: external_aluminium_sliding_step_2
    }, {
        path: '/aluminium-external-french-3000less/step-1',
        name: 'aluminium-external-french-3000less-step-1',
        component: external_aluminium_french_3000_less_step_1
    }, {
        path: '/aluminium-external-french-3000less/step-2',
        name: 'aluminium-external-french-3000less-step-2',
        component: external_aluminium_french_3000_less_step_2
    }, {
        path: '/aluminium-external-french-3000less/step-3/inwards',
        name: 'aluminium-external-french-3000less-step-3-inwards',
        component: external_aluminium_french_3000_less_step_3_inwards
    }, {
        path: '/aluminium-external-french-3000less/step-3/outwards',
        name: 'aluminium-external-french-3000less-step-3-outwards',
        component: external_aluminium_french_3000_less_step_3_outwards
    }, {
        path: '/aluminium-external-french-3000less/step-4',
        name: 'aluminium-external-french-3000less-step-4',
        component: external_aluminium_french_3000_less_step_4
    }, {
        path: '/aluminium-external-french-3000plus/step-1',
        name: 'aluminium-external-french-3000plus-step-1',
        component: external_aluminium_french_3000_plus_step_1
    }, {
        path: '/aluminium-external-french-3000plus/step-2',
        name: 'aluminium-external-french-3000plus-step-2',
        component: external_aluminium_french_3000_plus_step_2
    }, {
        path: '/aluminium-external-french-3000plus/step-3/inwards',
        name: 'aluminium-external-french-3000plus-step-3-inwards',
        component: external_aluminium_french_3000_plus_step_3_inwards
    }, {
        path: '/aluminium-external-french-3000plus/step-3/outwards',
        name: 'aluminium-external-french-3000plus-step-3-outwards',
        component: external_aluminium_french_3000_plus_step_3_outwards
    }, {
        path: '/404',
        name: '404',
        component: FourOhFour
    }, {
        path: '*',
        redirect: '/404'
    },
];

const router = new VueRouter({
    routes,
    mode: 'history'
});

const app = new Vue({
    el: '#app',
    router: router,
    components: {
        App
    },
    created() {
        window.onbeforeunload = () => {
            for(let [key, val] of Object.entries(sessionStorage)){
                if(key !== 'token'){
                    sessionStorage.removeItem(key);
                }
            }
        }
    }
});
