<template>
    <main id="direction-options" class="container option-wrapper">
        <section class="row">
            <h3 class="title col-12">Opening Direction</h3>
            <p  class="description col-12">Do you want the doors to open outwards into the garden or inwards into the house?</p>
        </section>
        <section class="choices row">
            <div class="col-12 col-md-6 col-xl-6 mb-4">
                <article @click="saveData" class="option option-in">
                    <i class="far fa-check-circle"></i>
                    <h4>Inwards</h4>
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         viewBox="0 0 616 360">
                        <defs>
                            <clipPath id="clip-path">
                                <rect x="159.25" y="88.23" width="296.62" height="195.43" style="fill:none"/>
                            </clipPath>
                        </defs>
                        <path id="Rectangle_8" data-name="Rectangle 8" d="M.28,299.13H616V355a5,5,0,0,1-5,5H5.28a5,5,0,0,1-5-5V299.13Z"
                              style="fill:#d5db42"/>
                        <g id="Illustration">
                            <rect id="Rectangle_17" data-name="Rectangle 17" x="74.92" y="51.82" width="465.35" height="245.13"
                                  style="fill:#e8e8e8"/>
                            <rect id="Rectangle_18" data-name="Rectangle 18" x="74.92" y="57.06" width="465.35" height="11.35"
                                  style="fill:#d2d0d1"/>
                            <path id="Path_45" data-name="Path 45"
                                  d="M529.6,6.55H85.6a2.54,2.54,0,0,0-2.32,1.77L64.38,53c-.71,1.93.51,10.63,2.32,10.63H548.5c1.81,0,3-8.7,2.32-10.63L531.92,8.32A2.53,2.53,0,0,0,529.6,6.55Z"
                                  style="fill:#6a5647"/>
                            <path id="Path_46" data-name="Path 46"
                                  d="M529.6,0H85.6a2.55,2.55,0,0,0-2.32,1.78L64.38,53c-.71,1.94.51,4.08,2.32,4.08H548.5c1.81,0,3-2.15,2.32-4.08L531.91,1.78A2.54,2.54,0,0,0,529.6,0Z"
                                  style="fill:#947668"/>
                            <path id="Path_47" data-name="Path 47"
                                  d="M461.58,287.91h-308a1.1,1.1,0,0,1-1-1.17,1,1,0,0,1,.17-.51h0a3.94,3.94,0,0,1,3.4-2H459.28a3.72,3.72,0,0,1,3.22,1.92h0a1.17,1.17,0,0,1-.38,1.61A1.05,1.05,0,0,1,461.58,287.91Z"
                                  style="fill:#464545"/>
                            <g id="Group_24" data-name="Group 24">
                                <g style="clip-path:url(#clip-path)">
                                    <g id="Group_23" data-name="Group 23">
                                        <rect id="Rectangle_19" data-name="Rectangle 19" x="159.25" y="88.23" width="296.62" height="195.43"
                                              style="fill:#fff;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                        <g id="Group_22" data-name="Group 22">
                                            <g id="Group_20" data-name="Group 20">
                                                <g id="Group_17" data-name="Group 17">
                                                    <path id="Path_48" data-name="Path 48"
                                                          d="M160.67,92v190.2l81.9-16.62a2.06,2.06,0,0,0,1.45-1.88V76.16a.83.83,0,0,0-.71-.93.8.8,0,0,0-.31,0Zm75.27,167.36-67.19,13.63V97.88l67.19-13.63Z"
                                                          style="fill:#5b5b5b"/>
                                                    <path id="Path_49" data-name="Path 49"
                                                          d="M242.58,265.53,161.69,282a.83.83,0,0,1-1-.6.81.81,0,0,1,0-.31V93.54a2,2,0,0,1,1.45-1.88L243,75.25a.82.82,0,0,1,1,.6.81.81,0,0,1,0,.31V263.65A2,2,0,0,1,242.58,265.53Z"
                                                          style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                                    <path id="Path_50" data-name="Path 50"
                                                          d="M235.94,259.31l-67.19,13.63V97.88l67.19-13.63Z"
                                                          style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                                    <path id="Path_51" data-name="Path 51" d="M174,161.27v14.15L224.4,91.78,215,93.68Z"
                                                          style="fill:#fff"/>
                                                    <path id="Path_52" data-name="Path 52" d="M174,142V156.1l35.85-61.37-9.38,1.9Z"
                                                          style="fill:#fff"/>
                                                </g>
                                                <g id="Group_18" data-name="Group 18">
                                                    <path id="Path_53" data-name="Path 53"
                                                          d="M246,76.16V263.65a2,2,0,0,0,1.45,1.88l80.88,16.41a.81.81,0,0,0,1-.6.65.65,0,0,0,0-.31V93.54a2,2,0,0,0-1.44-1.88L247,75.25a.83.83,0,0,0-1,.6A.81.81,0,0,0,246,76.16Zm75.27,196.78-67.19-13.63V84.25l67.19,13.63Z"
                                                          style="fill:#5b5b5b"/>
                                                    <path id="Path_54" data-name="Path 54"
                                                          d="M328.35,282l-80.89-16.42a2,2,0,0,1-1.45-1.88V76.16a.83.83,0,0,1,.71-.93.8.8,0,0,1,.31,0l80.89,16.41a2,2,0,0,1,1.44,1.88V281a.83.83,0,0,1-.7.94A1.08,1.08,0,0,1,328.35,282Z"
                                                          style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                                    <path id="Path_55" data-name="Path 55"
                                                          d="M321.28,272.94l-67.19-13.63V84.25l67.19,13.63Z"
                                                          style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                                    <path id="Path_56" data-name="Path 56" d="M259.33,149.77v14.15l50.41-63.19-9.37-1.9Z"
                                                          style="fill:#fff"/>
                                                    <path id="Path_57" data-name="Path 57" d="M259.33,130.45V144.6l35.85-46.83-9.38-1.9Z"
                                                          style="fill:#fff"/>
                                                </g>
                                                <g id="Group_19" data-name="Group 19">
                                                    <path id="Path_58" data-name="Path 58"
                                                          d="M331.35,93.54V281a.83.83,0,0,0,.71.93.81.81,0,0,0,.31,0l82.33-16.71V75L332.8,91.66A2.06,2.06,0,0,0,331.35,93.54Zm75.27,165.77-67.19,13.63V97.88l67.19-13.63Z"
                                                          style="fill:#5b5b5b"/>
                                                    <path id="Path_59" data-name="Path 59"
                                                          d="M413.26,265.54,332.37,282a.82.82,0,0,1-1-.6.81.81,0,0,1,0-.31V93.54a2,2,0,0,1,1.44-1.88l80.9-16.41a.83.83,0,0,1,1,.6.81.81,0,0,1,0,.31V263.65A2,2,0,0,1,413.26,265.54Z"
                                                          style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                                    <path id="Path_60" data-name="Path 60"
                                                          d="M406.62,259.31l-67.19,13.63V97.88l67.19-13.63Z"
                                                          style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                                    <path id="Path_61" data-name="Path 61" d="M344.67,161.27v14.15l50.42-83.64-9.38,1.9Z"
                                                          style="fill:#fff"/>
                                                    <path id="Path_62" data-name="Path 62" d="M344.67,142V156.1l35.85-61.37-9.38,1.9Z"
                                                          style="fill:#fff"/>
                                                </g>
                                            </g>
                                            <g id="Group_21" data-name="Group 21">
                                                <path id="Path_63" data-name="Path 63"
                                                      d="M410,188.68h0a1.06,1.06,0,0,1-1.26-.81,1.08,1.08,0,0,1,0-.31V176.5a1.72,1.72,0,0,1,1.28-1.64h0a1.05,1.05,0,0,1,1.25.81.81.81,0,0,1,0,.31V187A1.71,1.71,0,0,1,410,188.68Z"
                                                      style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                                                <path id="Path_64" data-name="Path 64"
                                                      d="M410.51,178.9h0a1.72,1.72,0,0,1-1.28,1.64l-4.47.91a1.06,1.06,0,0,1-1.26-.82,1,1,0,0,1,0-.3h0a1.72,1.72,0,0,1,1.28-1.65l4.47-.9a1.06,1.06,0,0,1,1.28,1.12Z"
                                                      style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <rect id="Rectangle_21" data-name="Rectangle 21" x="159.33" y="87.23" width="296.54" height="195.43"
                                  style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                            <path id="arrow"
                                  d="M476.23,181.58l20.89-20.94a1.7,1.7,0,0,1,2.4,0h0l20.89,20.94a1.7,1.7,0,0,1,0,2.4l-2.77,2.77a1.67,1.67,0,0,1-2.37,0l0-.05L502,173v35.11a1.7,1.7,0,0,1-1.69,1.7h-3.95a1.7,1.7,0,0,1-1.69-1.7V173l-13.23,13.7a1.69,1.69,0,0,1-2.4,0h0L476.26,184a1.66,1.66,0,0,1-.06-2.36l0,0Z"
                                  style="fill:#f4990a"/>
                            <path id="Path_66" data-name="Path 66" d="M575.61,327.47H40.39l33.42-33.19H542.19Z" style="fill:#bababa"/>
                            <rect id="Rectangle_23" data-name="Rectangle 23" x="40.39" y="327.47" width="535.22" height="7.35"
                                  style="fill:#757474"/>
                        </g>
                    </svg>
                </article>
            </div>
            <div class="col-12 col-md-6 col-xl-6 mb-4">
                <article @click="saveData" class="option option-out">
                    <i class="far fa-check-circle hidden"></i>
                    <h4>Outwards</h4>
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 616 360">
                        <path id="Rectangle_8" data-name="Rectangle 8" d="M.14,299.13H615.86V355a5,5,0,0,1-5,5H5.14a5,5,0,0,1-5-5V299.13Z"
                              style="fill:#d5db42"/>
                        <g id="Illustration">
                            <rect id="Rectangle_5" data-name="Rectangle 5" x="72.02" y="51.35" width="471.16" height="242.88"
                                  style="fill:#e8e8e8"/>
                            <rect id="Rectangle_6" data-name="Rectangle 6" x="72.02" y="56.54" width="471.16" height="11.25"
                                  style="fill:#d2d0d1"/>
                            <rect id="Rectangle_7" data-name="Rectangle 7" x="155.65" y="87.83" width="303.9" height="193.64"
                                  style="fill:#fff;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                            <path id="arrow"
                                  d="M524.37,188l-20.69,20.75a1.67,1.67,0,0,1-2.37,0h0L480.61,188a1.67,1.67,0,0,1,0-2.37h0l2.74-2.74a1.65,1.65,0,0,1,2.34,0l.05,0,13.1,13.58V161.71a1.69,1.69,0,0,1,1.68-1.68h3.91a1.69,1.69,0,0,1,1.68,1.68V196.5l13.1-13.58a1.69,1.69,0,0,1,2.38,0h0l2.74,2.74a1.65,1.65,0,0,1,.07,2.34l0,0Z"
                                  style="fill:#f4990a"/>
                            <path id="Path_2" data-name="Path 2"
                                  d="M532.16,6.49H83A2.57,2.57,0,0,0,80.7,8.25L61.58,52.49C60.86,54.41,62.1,63,63.93,63H551.27c1.83,0,3.07-8.62,2.35-10.54L534.5,8.25A2.57,2.57,0,0,0,532.16,6.49Z"
                                  style="fill:#6a5647"/>
                            <path id="Path_3" data-name="Path 3"
                                  d="M532.16,0H83A2.57,2.57,0,0,0,80.7,1.76L61.58,52.49c-.72,1.92.52,4,2.35,4H551.27c1.83,0,3.07-2.13,2.35-4L534.5,1.76A2.57,2.57,0,0,0,532.16,0Z"
                                  style="fill:#947668"/>
                            <path id="Path_4" data-name="Path 4"
                                  d="M465.17,286.37H150a1.08,1.08,0,0,1-1-1.13,1,1,0,0,1,.16-.54h0a4.1,4.1,0,0,1,3.49-2H462.82a3.86,3.86,0,0,1,3.3,1.9h0a1.17,1.17,0,0,1-.95,1.78Z"
                                  style="fill:#464545"/>
                            <path id="Path_5" data-name="Path 5" d="M578.19,327.1H37.81l33.75-32.88H544.44Z" style="fill:#bababa"/>
                            <g id="Group_9" data-name="Group 9">
                                <g id="Group_7" data-name="Group 7">
                                    <g id="Group_4" data-name="Group 4">
                                        <path id="Path_6" data-name="Path 6"
                                              d="M158.07,90V278.48L245.81,294a1.33,1.33,0,0,0,1.53-1.07,2,2,0,0,0,0-.23V106.88a1.32,1.32,0,0,0-1.09-1.3ZM238.7,285.2l-72-12.7V99.05l72,12.69Z"
                                              style="fill:#5b5b5b"/>
                                        <path id="Path_7" data-name="Path 7"
                                              d="M245.81,294l-86.65-15.28a1.32,1.32,0,0,1-1.09-1.3V91.6a1.32,1.32,0,0,1,1.32-1.32l.23,0,86.65,15.28a1.32,1.32,0,0,1,1.09,1.3V292.65A1.33,1.33,0,0,1,246,294Z"
                                              style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                        <path id="Path_8" data-name="Path 8" d="M238.7,285.19l-72-12.69V99.05l72,12.69Z"
                                              style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                        <path id="Path_9" data-name="Path 9" d="M172.34,163.9v14l54-63.22-10-1.77Z" style="fill:#fff"/>
                                        <path id="Path_10" data-name="Path 10" d="M172.34,144.76v14L210.74,112l-10.05-1.77Z"
                                              style="fill:#fff"/>
                                    </g>
                                    <g id="Group_5" data-name="Group 5">
                                        <path id="Path_11" data-name="Path 11"
                                              d="M249.49,106.88V292.65a1.32,1.32,0,0,0,1.32,1.32l.23,0,86.65-15.28a1.3,1.3,0,0,0,1.08-1.3V91.6a1.31,1.31,0,0,0-1.31-1.32l-.23,0-86.65,15.28A1.32,1.32,0,0,0,249.49,106.88ZM330.12,272.5l-72,12.7V111.75l72-12.7Z"
                                              style="fill:#5b5b5b"/>
                                        <path id="Path_12" data-name="Path 12"
                                              d="M337.69,278.67,251,294a1.33,1.33,0,0,1-1.53-1.07.93.93,0,0,1,0-.23V106.88a1.32,1.32,0,0,1,1.09-1.3L337.23,90.3a1.31,1.31,0,0,1,1.52,1.07.9.9,0,0,1,0,.23V277.37A1.31,1.31,0,0,1,337.69,278.67Z"
                                              style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                        <path id="Path_13" data-name="Path 13" d="M330.12,272.5l-72,12.69V111.74l72-12.69Z"
                                              style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                        <path id="Path_14" data-name="Path 14" d="M263.75,174.62v14l54-82.26-10,1.77Z" style="fill:#fff"/>
                                        <path id="Path_15" data-name="Path 15" d="M263.75,155.48v14l38.4-60.38-10,1.77Z" style="fill:#fff"/>
                                    </g>
                                    <g id="Group_6" data-name="Group 6">
                                        <path id="Path_16" data-name="Path 16"
                                              d="M340.91,91.6V277.37a1.32,1.32,0,0,0,1.09,1.3l88.2,15.55V105.77L342.46,90.3a1.32,1.32,0,0,0-1.53,1.07A1.79,1.79,0,0,0,340.91,91.6Zm80.63,193.6-72-12.69V99.05l72,12.7Z"
                                              style="fill:#5b5b5b"/>
                                        <path id="Path_17" data-name="Path 17"
                                              d="M428.65,294,342,278.67a1.32,1.32,0,0,1-1.09-1.3V91.6a1.32,1.32,0,0,1,1.32-1.32l.23,0,86.65,15.28a1.3,1.3,0,0,1,1.08,1.3V292.65a1.32,1.32,0,0,1-1.31,1.32Z"
                                              style="fill:none;stroke:#4d4c4c;stroke-miterlimit:10;stroke-width:4px"/>
                                        <path id="Path_18" data-name="Path 18" d="M421.54,285.19l-72-12.69V99.05l72,12.69Z"
                                              style="fill:#d8e9f9;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:2px;opacity:0.85"/>
                                        <path id="Path_19" data-name="Path 19" d="M355.17,163.9v14l54-63.22-10-1.77Z" style="fill:#fff"/>
                                        <path id="Path_20" data-name="Path 20" d="M355.17,144.76v14L393.57,112l-10-1.77Z"
                                              style="fill:#fff"/>
                                    </g>
                                </g>
                                <g id="Group_8" data-name="Group 8">
                                    <path id="Path_21" data-name="Path 21"
                                          d="M425.13,203.13h0a1.72,1.72,0,0,1-1.37-1.61v-11a1.13,1.13,0,0,1,1.09-1.16,1.33,1.33,0,0,1,.28,0h0a1.7,1.7,0,0,1,1.37,1.61V202a1.11,1.11,0,0,1-1.09,1.15A.88.88,0,0,1,425.13,203.13Z"
                                          style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                                    <path id="Path_22" data-name="Path 22"
                                          d="M418.17,192.32h0a1.12,1.12,0,0,1,1.08-1.15.93.93,0,0,1,.29,0l4.79.85a1.7,1.7,0,0,1,1.37,1.61h0a1.11,1.11,0,0,1-1.08,1.15,1,1,0,0,1-.29,0l-4.79-.85A1.72,1.72,0,0,1,418.17,192.32Z"
                                          style="fill:#f4f4f4;stroke:#a3a2a2;stroke-miterlimit:10"/>
                                </g>
                            </g>
                            <rect id="Rectangle_9" data-name="Rectangle 9" x="37.81" y="327.1" width="540.39" height="7.28"
                                  style="fill:#757474"/>
                        </g>
                    </svg>
                </article>
            </div>
        </section>
        <nav class="container sm-fixed-bottom text-center">
            <section class="footer-left col-6 col-lg-3 float-left">
                <button @click="goBack" type="button" class="btn btn-back btn-lg w-100"><i class="fa fa-angle-left"></i> Go Back</button>
            </section>
            <section class="footer-right col-6 col-lg-3 float-right">
                <button @click="next" type="button" href="#" class="btn btn-next btn-lg w-100 btn-disable">Next Step <i
                    class="fa fa-angle-right"></i></button>
            </section>
        </nav>
    </main>
</template>
<script>
export default {
    created() {
        document.querySelectorAll('nav.stages ul li').forEach((el) => {
            if(el.classList.contains('two')) {
                el.classList.add('active');
            }
        });
    },
    methods: {
        next: function (e) {
            if (!e.target.classList.contains('btn-disable')) {
                document.querySelector('body').querySelector('#aluminium-external-bifold-3000less-step-3').click();

                document.querySelectorAll('nav.stages ul li').forEach((el) => {
                    if(el.classList.contains('two')) {
                        el.classList.remove('active');
                        el.classList.add('done');
                    }
                });
            }
        },
        goBack: function () {
            window.history.back();
        },
        saveData: function (e) {
            if (e.target.closest('article').classList.contains('option-in')) {
                sessionStorage.ProdConfiguredInwardsOrOutwards = 'Inwards';
            }

            if (e.target.closest('article').classList.contains('option-out')) {
                sessionStorage.ProdConfiguredInwardsOrOutwards = 'Outwards';
            }

            document.querySelectorAll(".option").forEach((el) => {
                el.classList.remove('chosen');
            });
            e.target.closest('article').classList.add('chosen');
            document.querySelector('body').querySelector('.btn-next').classList.remove('btn-disable');
        }
    }
}
</script>
