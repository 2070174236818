<template>
    <main id="confirm-screen" class="container option-wrapper">
        <div class="loading d-flex justify-content-center hidden" style="z-index: 99999;background:#ffffffcf;position:fixed;top:0vh;left:0vw;width:100vw;height:100vh;">
            <div class="spinner-border" role="status" style="margin-top: 48vh;">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="col-12 center-this">
            <section class="row d-flex justify-content-center ">
                <h4 class="title col-11 col-lg-5">By clicking submit, you are confirming that all your choices are correct.</h4>
            </section>
            <section class="row d-flex justify-content-center confirm-buttons">
                <section class="col-5 col-lg-2">
                    <button @click="goBack" type="button" href="#" class="btn btn-back w-100 btn-lg"><i class="fa fa-angle-left"></i> Go Back</button>
                </section>
                <section class="col-5 col-lg-2">
                    <button @click="submitConfiguration" type="button" href="#" class="btn btn-confirm send-config w-100 btn-lg">Submit <i class="far fa-check-circle"></i></button>
                </section>
            </section>
            <section class="row d-flex justify-content-center">
                <p class="col-11 col-lg-5">If you are unsure about any of your choices, speak to your builder/contractor, or give us a call on:</p>
                <h5 class="col-12 text-center"><a href="tel:020 3744 0704">020 3744 0704</a></h5>
            </section>
        </div>
    </main>
</template>
<script>
    import axios from "axios";

    export default {
        created() {
            this.noSession();
        },
        methods: {
            goBack: function () {
                sessionStorage.removeItem('session');
                window.history.back();
            },
            noSession: function() {
                if(!sessionStorage.session || sessionStorage.session === '' || sessionStorage.session === undefined){
                    alert("Your session has been lost. Please return to the dashboard.");
                }
            },
            submitConfiguration: function() {
                document.querySelector('button.send-config').classList.add('btn-disable');
                document.querySelector('body').querySelector('.loading').classList.remove('hidden');
                fetch('https://www.aspire-doors.co.uk/wp-json/aspire-amc/v1/set_order_details', {
                    method: 'post',
                    headers: {
                        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                    },
                    body: 'token=' + sessionStorage.token + '&configuration=' + sessionStorage.session,
                })
                    .then((data) => {
                        axios.post("/api/session/update", {
                            headers: {
                                "Accept": "application/json",
                                "Content-Type": "application/json"
                            },
                            data: {
                                token: sessionStorage.token,
                                configured: 1,
                                data: sessionStorage.session
                            }
                        })
                            .then((res) => {
                                document.querySelector('body').querySelector('#thankyou').click();
                            })
                            .catch((e) => {
                                console.log(e);
                                reject("error");
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                        alert(error);
                    });
            }
        }
    }
</script>
